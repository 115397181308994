import React from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
import "./Scholarship.css";
class Scholarship extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Scholarships - Indian Institute of Information Technology, Kota
          </title>
          <meta
            name="description"
            content="Scholarships - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="Scholarships,Scholarships in iiit kota, Nsp, india scholarship, scholarship for backward classes iiitkota, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Scholarship for Students</b>
                  </h2>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col lg={12} xs={12} className="text-justify">
                  <h6>
                    B.Tech students can apply and avail different Central/State
                    Govt. scholarship schemes. In addition to these govt.
                    schemes, a student can also apply for different PSU (Public
                    Sector Unit) scholarships. All existing and new students of
                    IIIT Kota are advised to go through following spreadsheet.
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col lg={12} xs={12} className="mt-3 mb-5">

                  
                
                  <h6 class="mt-5">
                    <b>
                      <a
                        href="https://docs.google.com/spreadsheets/d/e/2PACX-1vRgW5TqL-hvC0gusAjb3TId9LDvygNlCRl2DRyWbcxVb_SfCaR5VqoPZBlry8DtJeq9HX5ZGyC2x8fm/pubhtml"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Click here for the detailed spreadsheet view
                      </a>
                    </b>
                    <br />
                    <br />
                    <b>Note:</b> The provided information is indicative.
                    Eligibility criteria, Value of scholarship, selection
                    criteria and other parameters shall be decided by the
                    concerned scholarship authority. It is advised to visit
                    their website for updated information.
                  </h6>
                </Col>
              </Row>

              <Row className="justify-content-center text mb-5 mt-3">
                <center>
                  <h4 className="mb-3 mt-3">
                    <b>For any query contact</b>
                  </h4>
                </center>
                <Col lg="12 mt-5">
                  <Row className="row-grid justify-content-center">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4" style={{color: "#fca841"}}>
                            <i className="fas fa-address-book" />
                          </div>

                          <h6 className="text-uppercase" style={{color: "#fca841"}}>
                            <b> Office</b>
                            <br />
                          </h6>

                          <div className="px-2 pb-2">
                            Admin Block, IIIT kota{" "}
                          </div>
                          <h6>
                            <a href="mailto:office@iiitkota.ac.in">
                              <i className="fa fa-envelope px-2" />
                              office@iiitkota.ac.in
                            </a>
                          </h6>

                          <h6>
                            <a href="tel:0141-2715071">
                              <i className="fas fa-phone-square-alt px-2" />
                              0141-2715071
                            </a>
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <a href="/faculty/608525427fb8f4001564e185">
                              <i className="fas fa-address-card" />
                            </a>
                          </div>

                          <h6 className="text-uppercase">
                            <a href="/faculty/608525427fb8f4001564e185">
                              <b>Dr. Amit Kumar</b>{" "}
                            </a>
                            <br />
                          </h6>

                          <div className="px-2 pb-2">
                            Nodel Officer, Scholarship
                          </div>
                          <h6>
                            <a href="mailto:amit@iiitkota.ac.in">
                              <i className="fa fa-envelope px-2" />
                              amit@iiitkota.ac.in
                            </a>
                          </h6>

                          
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Scholarship;
