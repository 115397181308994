/* eslint-disable */
import React, { useState, useEffect } from "react";
import axios from "axios";
import "react-popper";
import "bootstrap/dist/js/bootstrap.min.js";
import EventRow from "./EventRow";

export default function Event() {
  const [event, setEvent] = useState([]);

  const getData = async () => {
    const eventAll = await axios.get("https://admin-panel-new.onrender.com/events");
    let eventRow = [];
    let EVENT = [];
    for (let i = eventAll.data.length - 1; i >= 0; i--) {
      eventRow.push(eventAll.data[i]);

      if ((eventAll.data.length - i) % 4 == 0) {
        EVENT.push(eventRow);
        eventRow = [];
      }
    }

    if (eventRow.length != 0) EVENT.push(eventRow);
    await setEvent(EVENT);
    // console.log('Event : ',EVENT);
  };

  useEffect(() => {
    (async () => {
      try {
        await getData();
      } catch (e) {
        console.log(`Error Found : ${e}`);
      }
    })();
  }, []);

  return (
    <section className="pt-7 pb-5 ">
      <div className="container col-lg-11 justify-content-center px-8 smallNews2">
        <div className="row">
          <div className="col-6">
            <h2 className="mb-3 display-3">Events</h2>
          </div>
          <div className="col-6 text-right">
            <a
              className="btn btn-default mb-3 mr-1"
              href="#nextEvent"
              role="button"
              data-slide="prev"
            >
              <i className="fa fa-arrow-left" />
            </a>
            <a
              className="btn btn-default mb-3 "
              href="#nextEvent"
              role="button"
              data-slide="next"
            >
              <i className="fa fa-arrow-right" />
            </a>
          </div>
          <div className="col-lg-12 col-md-12">
            <div id="nextEvent" className="carousel slide" data-ride="carousel">
              <div className="carousel-inner">
                {event
                  ? event.map((ele, index) => (
                      <EventRow data={ele} isActive={index ? false : true} />
                    ))
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
