import React from "react";
import "assets/css/style.css";
import { Button, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
class Coordinator extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Registrar - Indian Institute of Information Technology, Kota
          </title>
          <meta
            name="description"
            content="Coordinator - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="Coordinator iiit kota, Prof. A. K. Vyas, Coordinator mnit jaipur, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
          <meta name="author" content="Prof. Udaykumar R Yaragatti" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Registrar</b>
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col lg={4} xs={12} className="mb-5">
                  <img
                    alt="/"
                    className="director-img"
                    src={require("assets/img/people/administrating_body/Dr.-Rohit-Bhakar.webp")}
                  ></img>

                  <div className="mt-3">
                    <h6>
                      Phone : <a href="tel:+91-141-2713267">+91 141-2529078</a>
                    </h6>
                  </div>
                  <div className="mt-3">
                    <h6>
                      Registrar, IIIT Kota
                      <br />
                      2nd Floor, Prabha Bhawan, MNIT Campus, JLN Marg,
                      Jaipur-302017
                    </h6>
                  </div>
                  <div className="mt-3">
                    <Button
                      className="btn-icon btn-3 theme-color-kota-bg"
                      type="button"
                      href="mailto:registrar@iiitkota.ac.in"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fa fa-envelope" />
                      </span>
                      <span className="btn-inner--text">E-mail</span>
                    </Button>
                    {/* <Button
                      className="btn-icon btn-3 theme-color-kota-bg"
                      type="button"
                      href="https://www.linkedin.com/in/udaykumar-r-yaragatti-559a1514/"
                      target="_blank"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fab fa-linkedin" />
                      </span>
                      <span className="btn-inner--text">LinkedIn</span>
                    </Button> */}
                  </div>
                </Col>
                <Col lg={8} xs={12}>
                  <div className="">
                    <h2 className="display-3 mb-0">
                      <b>Prof. Rohit Bhakar</b>
                    </h2>
                    <h5>Registrar, IIIT Kota</h5>
                  </div>
                  {/* <div className="text-justify director-content">
                    <h5>
                      Our focus is to continuously improve our performance
                      inspiring excellence and to grow into one of the best
                      institutions for engineering education in the country.
                    </h5>
                  </div> */}
                  <hr />
                  <div className="text-justify mb-6">
                    <h5 className="mb-2">
                      <b>Research Interests</b>
                    </h5>
                    <h6 className="mb-4">
                    Power Systems Restructuring, Power Systems Economics, Network Pricing, Electricity Markets, Game Theory, Risk Management, Ancillary Services, Energy Storage, Grid Integration of Renewables, Cyber Security, Power System Planning.
                    </h6>
                    {/* <h5 className="mb-2">
                      <b>Brief Research Profile</b>
                    </h5>
                    <h6 className="mb-4">
                      Working on utilization of industrial wastes as
                      construction material. Different types of industrial slag
                      have been tried in concrete and asphalt mixes.
                      Recommendations for optimum quantity were given in
                      different types of mixes.
                    </h6> */}
                    <h5 className="mb-2">
                      <b>Administrative Responsibilities</b>
                    </h5>
                    <ul>
                      <li>
                        <h6>
                        Co-cordinator in Indian Institute of Information Technology Kota from - 23-01-2023 to Till Date
                        </h6>{" "}
                      </li>
                      <li>
                        <h6>
                        Coordinator, Institute Purchase Committee in MNIT Jaipur from - 17-07-2022 to Till Date
                        </h6>
                      </li>
                      <li>
                        <h6>
                        Coordinator TEQIP in MNIT Jaipur from - 05-06-2016 to Till Date
                        </h6>
                      </li>
                      <li>
                        <h6>
                        Head, Centre for Energy and Environment in Malaviya National Institute of Technology Jaipur from - 05-06-2018 to 28-01-2020
                        </h6>
                      </li>
                      <li>
                        <h6>
                        Dy. Incharge in CSAB Counselling MNIT Reporting Center from - 01-05-2017 to 31-10-2023
                        </h6>
                      </li>
                      <li>
                        <h6>
                        Associate Dean, Student Welfare in MNIT Jaipur from - 01-07-2013 to 21-12-2013
                        </h6>
                      </li>
                      <li>
                        <h6>
                        Co-cordinator, Industry Institute Interaction in MNIT Jaipur from - 01-07-2012 to 30-06-2013
                        </h6>
                      </li>
                      <li>
                        <h6>
                        Co-convener, Logistics, Golden Jubilee Celebrations in MNIT Jaipur from - 01-07-2011 to 30-06-2013
                        </h6>
                      </li>
                    </ul>

                    <h5 className="mb-2">
                      <b>Journal Publication Details</b>
                    </h5>
                    <ul>
                      <li>
                        <h6>
                        Priyanka Kushwaha, Vivek Prakash, Sumanth Yamujala, Rohit Bhakar, "Fast frequency response constrained electric vehicle scheduling for low inertia power systems" , Journal of Energy Storage (Elsevier) Volume :62 / 106944 / 2023 
                          2021
                        </h6>{" "}
                      </li>
                      <li>
                        <h6>
                        Barala, Chandra Prakash, Parul Mathuria, and Rohit Bhakar, "Distribution locational marginal price based hierarchical scheduling framework for grid flexibility from virtual energy storage systems" , Electric Power Systems Research (Elsevier) Volume :214 / / 2023
                        </h6>
                      </li>
                      <li>
                        <h6>
                        A Jain, S Yamujala, A Gaur, P Das, R Bhakar, J Mathur, "Power sector decarbonization planning considering renewable resource variability and system operational constraints" , Applied Energy (Elsevier) Volume :331 / / 2023
                        </h6>
                      </li>
                      <li>
                        <h6>
                        Navneet Sharma, Rohit Bhakar, Prerna Jain, "Optimal reconciliation of hierarchical wind power forecasts of correlated wind farms" , Sustainable Energy, Grids and Networks (Elsevier) Volume :35 / 101091 / 2023 
                        </h6>
                      </li>
                      <li>
                        <h6>
                        Priyanka Kushwaha, Vivek Prakash, Rohit Bhakar, "A novel framework to assess synthetic inertia & primary frequency response support from energy storage systems" , Sustainable Energy, Grids and Networks (Elsevier) Volume :34 / 101052 / 2023 
                        </h6>{" "}
                      </li>
                      <li>
                        <h6>
                        Archita Vijayvargia, Kailash Chand Sharma, Rohit Bhakar, "VARMA model parameterization using MLLE approach for intraday wind power forecasting application" , International Journal of Numerical Modelling: Electronic Networks, Devices and Fields (Wiley Publications) Volume :1 / 3119 / 2023
                        </h6>{" "}
                      </li>
                      <li>
                        <h6>
                        Sandeep Chawda, Parul Mathuria, Rohit Bhakar, "Load Serving Entity�s Profit Maximization Framework for Correlated Demand and Pool Price Uncertainties" , Technology and Economics of Smart Grids and Sustainable Energy (Springer Nature Singapore) Volume :8 / 2 / 2023 
                        </h6>{" "}
                      </li>
                      <li>
                        <h6>
                          W. Huang, C. Gao, R. Li, R. Bhakar, N. Tai and M. Yu, "A Model Predictive Control-Based Voltage Optimization Method for Highway Transportation Power Supply Networks with Soft Open Points" , IEEE Transactions on Industry Applications (IEEE) Volume :99 / 1-10 / 2023 
                        </h6>{" "}
                      </li>
                      <li>
                        <h6>
                        Kailash Chand Sharma, Archee Gupta, Rohit Bhakar, "Wind Power Scenario Generation Considering Spatiotemporal Correlations: A Distribution Free Hybrid VARMA-Copula Approach" , Smart Grids and Sustainable Energy (Springer ) Volume :8 / / 2023 
                        </h6>{" "}
                      </li>
                    </ul>
                  </div>
                  <hr />
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Coordinator;
