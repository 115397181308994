import React from "react";
import { Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import "views/IndexSections/Sections.css";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
class Error404 extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            404 Not Found - Indian Institute of Information Technology ,Kota
          </title>
          <meta
            name="description"
            content="404 Not Found - Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="404, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-3">
                <Col lg="8">
                  <h2>
                    <b>404 Not Found</b>
                  </h2>
                </Col>
              </Row>

              <Row className="text-justify">
                <Col lg={12} className="mt-3 text-center">
                  <h3 className="text-justify text-center">
                    "Sorry, we can't find the page you were looking for"
                    <br />
                    <br />
                    Let's head towards the{" "}
                    <b>
                      <Link to="/">Homepage</Link>
                    </b>
                  </h3>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Error404;
