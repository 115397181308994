/* eslint-disable */
import React from "react";
import ImageViewer from "views/HeaderSection/Life/ImageViewer";
import { useState } from "react";
import "./events.css";
import { HashLink as Link } from "react-router-hash-link";
import EventRowItem from "./EventRowItem";
export default function EventRow(props) {
  // console.log(props.data);
  const [isOpen, setIsOpen] = useState(false);

  const handleImageClick = (event) => {
    setIsOpen(true);
  };

  const handleImageClose = (event) => {
    setIsOpen(false);
  };

  return (
    <div className={props.isActive ? "carousel-item active" : "carousel-item"}>
      <div className="row">
        {props.data
          ? props.data.map((ele, index) => (
              <EventRowItem ele={ele} key={index} />
            ))
          : null}
      </div>
    </div>
  );
}
