import React from "react";
import { Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
import Table from "reactstrap/lib/Table";
class UndergraduateProgram extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Undergraduate Program - Indian Institute of Information Technology
            Kota
          </title>
          <meta
            name="description"
            content="Undergraduate Program Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Undergraduate Program, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Deparment in IIIT Kota, CSE Deparment,ECE Department , Electronics and Communication Engineering Department,Computer Science and Engineering Department,IIIT Kota Department,CSE Programme Advisors,ECE Programme Advisors"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg ">
            <Container> 
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Undergraduate Program</b>
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col lg={12} xs={12}>
                  <h6 className="pb-3">
                    <b>
                      The institute offers B.Tech. programme for Undergraduate
                      (UG) in following streams:
                    </b>
                    <h6>
                      <ul>
                        <li>Computer Science and Engineering (CSE)</li>
                        <li>Electronics and Communication Engineering (ECE)</li>
                      </ul>
                    </h6>
                  </h6>
                </Col>
                <Col lg={12} xs={12}>
                  <h6>
                    The institute offers Bachelor of Technology (B.Tech.)
                    program for Undergraduate (UG) in two streams – Computer
                    Science and Engineering (CSE), Electronics and Communication
                    Engineering (ECE).
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col lg={12} xs={12} className="mt-2 mb-5">
                  <Table bordered>
                    <h6 className="only-padding">
                      <thead>
                        <tr>
                          <th style={{ width: "202px" }}>Academic Session</th>
                          <th style={{ width: "505px" }}>Branch</th>
                          <th style={{ width: "303px" }}>Intake</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            rowSpan="2"
                            className="text-center  "
                            style={{ verticalAlign: "middle" }}
                          >
                            2019-2020
                          </td>
                          <td>Computer Science and Engineering (CSE)</td>
                          <td>143</td>
                        </tr>
                        <tr>
                          <td>
                            Electronics and Communication Engineering (ECE)
                          </td>

                          <td>71</td>
                        </tr>
                      </tbody>
                    </h6>
                  </Table>
                </Col>
                <Col className="text-justify">
                  <h6>
                    In general, the duration of B. Tech. program is 8-semesters
                    spanning over 4-years. CSE and ECE students are respectively
                    exposed to 71 and 77 well-planned theory and laboratories
                    courses as well as dissertation to gain in-depth knowledge
                    and prepare for most of the opportunities in these streams.
                  </h6>
                  <h6>
                    Currently, IIIT Kota does not allow change of stream
                    internally once student is admitted. However, a student may
                    get allocated a different stream by JoSAA only in subsequent
                    rounds during centralized admission process.
                  </h6>
                  <h6>
                    The institute will soon offer M.S./M. Tech. and Ph. D.
                    programs for Postgraduate (PG) stream after due-diligence
                    and approval from Board of Governors and the Senate
                  </h6>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default UndergraduateProgram;
