import React from "react";
import "assets/css/style.css";
import { Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
class Partnership extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Partnership - Indian Institute of Information Technology, Kota
          </title>
          <meta
            name="description"
            content="Partnership - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="Partnership, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota, PPP mode, PPP act, nbc, cairn, energy for india, vakrangee, genpact"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Our Industry Partners</b>
                  </h2>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={4} className="mt-5">
                  <a
                    href="https://www.nbcbearings.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="/"
                      className="partners"
                      src={require("assets/img/partners/nbc.jpg")}
                    />
                  </a>
                </Col>
                <Col lg={4} className="mt-5">
                  <a
                    href="https://www.cairnindia.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="/"
                      className="partners"
                      src={require("assets/img/partners/cairn_india.jpg")}
                    />
                  </a>
                </Col>
              </Row>
              <Row className="justify-content-center ">
                <Col lg={4} className="mt-5">
                  <a
                    href="https://www.vakrangee.in/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="vakrangee"
                      className="partners2"
                      src={require("assets/img/partners/vakrangee.jpg")}
                    />
                  </a>
                </Col>
                <Col lg={4} className="mt-5">
                  <a
                    href="https://www.genpact.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="/"
                      className="partners2"
                      src={require("assets/img/partners/genpact.png")}
                    />
                  </a>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Partnership;
