import React from "react";
import Headroom from "react-headroom";

import home_icon from "../assets/img/brand/home_icon.svg"

function TopBar() {
  return (
    <>
      <Headroom>
        <div className="container-fluid topbar text-light pl-5 pr-5">
          <div className="row ">
            <div className="col-lg-6 col-md-6 icons">
              <a href="/">
                <img src={home_icon} alt="..." style={{height:"20px", width:"20px", marginBottom: "2px"}}></img>
              </a>
              <a href="https://www.facebook.com/IIITKotaOffice/" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-facebook" />
              </a>
              <a href="https://twitter.com/IIITKotaOffice" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-twitter" />
              </a>
              <a href="https://www.linkedin.com/school/iiitkotaoffice" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-linkedin" />
              </a>
              <a href="https://www.instagram.com/iiitkotaoffice/" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram" />
              </a>
              <a href="https://www.youtube.com/channel/UCMvxTDPPnyuEPSGgjOv6HBQ" target="_blank" rel="noopener noreferrer">
                <i class="fab fa-youtube"></i>
              </a>
            </div>
            <div className="col-lg-6 col-md-6 text-right icons">
              <a href="https://goo.gl/maps/KfBmHcSVKmzt3hvj7">
                <i class="fa fa-map-marker" aria-hidden="true"></i> &nbsp;
                Location
              </a>
            </div>
          </div>
        </div>
      </Headroom>
    </>
  );
}
export default TopBar;
