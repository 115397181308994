/* eslint-disable */
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import "../assets/css/iiitk.css";
import { HashLink as Link } from "react-router-hash-link";
import Marquee from "react-easy-marquee";
import "./News.css";
import {
  Row,
  Col,
  Container,
  CardBody,
  Card,
  // Button,
  NavItem,
  NavLink,
  Figure,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import { auto } from "@popperjs/core";

export default function News() {
  const [news, setNews] = useState([]);
  const [announcement, setAnnouncement] = useState([]);

  const design = {
    padding: "0px",
  };
  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -90;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const getData = async () => {
    const newsAll = await axios.get("https://admin-panel-new.onrender.com/newses");
    const sortedNews = newsAll.data.reverse();
    await setNews(sortedNews);

    const announceAll = await axios.get(
      "https://admin-panel-new.onrender.com/announcements"
    );
    const sortedAnnounce = announceAll.data.reverse();
    await setAnnouncement(sortedAnnounce);
    // console.log(announceAll.data);
  };

  const [iconTabs, setIconTabs] = useState(2);

  useEffect(() => {
    (async () => {
      try {
        await getData();
      } catch (e) {
        console.log(`Error Found : ${e}`);
      }
    })();
  }, []);

  return (
    <div className="d-flex flex-column flex-lg-row px-4 justify-content-center ">
      <Col lg="7" className="justify-content-center">
        <div className="nav-wrapper">
          <Nav
            className="nav-fill flex-column flex-md-row"
            id="tabs-icons-text"
            pills
            role="tablist"
          >
            <NavItem>
              <NavLink
                aria-selected={iconTabs === 2}
                style={{ cursor: "pointer" }}
                className={classnames("mb-sm-3 mb-md-0 ", {
                  active: iconTabs === 2,
                })}
                onClick={() => {
                  setIconTabs(2);
                }}
                role="tab"
              >
                Announcements
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                aria-selected={iconTabs === 1}
                style={{ cursor: "pointer" }}
                className={classnames("mb-sm-3 mb-md-0 ", {
                  active: iconTabs === 1,
                })}
                onClick={() => {
                  setIconTabs(1);
                }}
                role="tab"
              >
                Latest Updates
              </NavLink>
            </NavItem>
          </Nav>
        </div>

        <Card className="shadow col-lg-12 mb-4 ">
          <CardBody>
            <TabContent activeTab={"iconTabs" + iconTabs}>
              <TabPane tabId="iconTabs2">
                <div className="col-lg-12 mt-sm--2">
                  <div className="section-title">
                    <h2 className="display-3" style={{ marginLeft: "-40px" }}>
                      Announcements
                    </h2>
                  </div>

                  <div>
                    <Card
                      style={{
                        overflowX: "hidden",
                        border: "1px solid #0e407c",
                        borderRadius: "9px",
                        marginRight: "-40px",
                        marginLeft: "-40px",
                        marginBottom: "-10px",
                      }}
                    >
                      <div style={{ maxHeight: "320px", overflowY: "hidden" }}>
                        <Marquee
                          duration={45000}
                          // background="#00DEFB"
                          height="330px"
                          width="100%"
                          axis="Y"
                          align="center"
                          pauseOnHover={true}
                          reverse={true}
                        >
                          <ul
                            className="nav nav-pills  col-lg-12"
                            style={{ paddingRight: "0px" }}
                          >
                            {announcement
                              ? announcement?.map((ele) => (
                                <li
                                  className="newsli col-lg-12"
                                  style={design}
                                >
                                  <div className="nav-link">
                                    <div className="media switch-buttons">
                                      <div
                                        className="mr-4 ml-4 text-center ma_dat"
                                        style={{
                                          marginTop: ".5rem",
                                          color: "#0E407C",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faChevronCircleRight}
                                        />{" "}
                                        &nbsp;
                                      </div>

                                      <div className="media-body">
                                        <Link
                                          to={"/announcement#" + ele?.title}
                                          smooth
                                          title={ele?.description}
                                          scroll={(el) =>
                                            scrollWithOffset(el)
                                          }
                                        >
                                          <h6 className="mt-1">
                                            {ele?.title}
                                          </h6>
                                          <span className="tag-button"></span>
                                        </Link>

                                        {ele.date && (
                                          <Link
                                            to={"/announcement#" + ele?.title}
                                            smooth
                                            title={ele?.description}
                                            scroll={(el) =>
                                              scrollWithOffset(el)
                                            }
                                            style={{ color: "#0E407C" }}
                                          >
                                            Posted on {ele?.date}
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))
                              : null}
                          </ul>
                        </Marquee>
                      </div>
                    </Card>
                  </div>
                </div>
              </TabPane>
              <TabPane className="justify-content-center" tabId="iconTabs1">
                <div className="col-lg-12 mt-sm--2">
                  <div className="section-title">
                    <h2 className="display-3" style={{ marginLeft: "-40px" }}>
                      Latest Updates
                    </h2>
                  </div>

                  <div>
                    <Card
                      style={{
                        overflowX: "hidden",
                        border: "0.5px solid #0e407c",
                        borderRadius: "9px",
                        marginRight: "-40px",
                        marginLeft: "-40px",
                        marginBottom: "-10px",
                      }}
                    >
                      <div style={{ maxHeight: "320px", overflowY: "hidden" }}>
                        <Marquee
                          duration={45000}
                          // background="#00DEFB"
                          height="330px"
                          width="100%"
                          axis="Y"
                          align="center"
                          pauseOnHover={true}
                          reverse={true}
                        >
                          <ul
                            className="nav nav-pills col-lg-12"
                            style={{ paddingRight: "0px" }}
                          >
                            {news
                              ? news?.map((ele) => (
                                <li
                                  className="newsli col-lg-12"
                                  style={design}
                                >
                                  <div className="nav-link">
                                    <div className="media switch-buttons">
                                      <div
                                        className="mr-4 ml-4 text-center ma_dat"
                                        style={{
                                          marginTop: ".5em",
                                          color: "#0E407C",
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faChevronCircleRight}
                                        />{" "}
                                        &nbsp;
                                      </div>

                                      <div className="media-body">
                                        <Link
                                          to={"/latest-update#" + ele?.title}
                                          smooth
                                          title={ele?.description}
                                          scroll={(el) =>
                                            scrollWithOffset(el)
                                          }
                                        >
                                          <h6 className="mt-1">
                                            {ele?.title}
                                          </h6>
                                          <span className="tag-button"></span>
                                        </Link>
                                        {/* <a
                                    href="/latest-update"
                                    title={ele?.description}
                                  >
                                    
                                  </a> */}
                                        {/* <div>{ele.description}</div> */}
                                        {ele.date && (
                                          <Link
                                            to={
                                              "/latest-update#" + ele?.title
                                            }
                                            smooth
                                            title={ele?.description}
                                            scroll={(el) =>
                                              scrollWithOffset(el)
                                            }
                                            style={{ color: "#0E407C" }}
                                          >
                                            Posted on {ele?.date}
                                          </Link>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              ))
                              : null}
                          </ul>
                        </Marquee>
                      </div>
                    </Card>
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Col>
      <Col className="d-flex flex-column mx-auto" sm='12' md='12' lg='5'>
        <Row className="pl-lg-4 ml-sm-2 py-3 ">
          <h3 className="quick_access_title" style={{ borderBottom: "2px solid #1a1c4e" }}>
            Quick Access
          </h3>
        </Row>
        <br />
        <Col className="d-flex justify-content-center align-items-center align-items-lg-start pb-lg-3 flex-wrap">
          <Col className="d-flex justify-content-center" lg="4" sm="2" xs="6">
            <a href="https://tpcell.iiitkota.ac.in/" >
              <Card className="quick_access_card d-flex justify-content-center align-items-center py-3 mx-auto">
                <img
                  className="img-fluid"
                  alt="Card image"
                  src={require("../assets/img/icons/common/placements.png")}
                  style={{ width: "55%", margin: "auto" }}
                />
                <div className="card_text">Placements</div>
              </Card>
            </a>
          </Col>
          <Col className="d-flex justify-content-center" lg="4" sm="2" xs="6">
            <Link to="/faculty">
              <Card className="quick_access_card d-flex justify-content-center align-items-center py-3 mx-auto">
                <img
                  className="img-fluid"
                  alt="Card image"
                  src={require("../assets/img/icons/common/faculty.png")}
                  style={{ width: "55%", margin: "auto" }}
                />
                <div className="card_text">Faculty</div>
              </Card>
            </Link>
          </Col>
          <Col className="d-flex justify-content-center" lg="4" sm="2" xs="6">
            <Link to="/department">
              <Card className="quick_access_card d-flex justify-content-center align-items-center py-3 mx-auto">
                <img
                  className="img-fluid"
                  alt="Card image"
                  src={require("../assets/img/icons/common/courses.png")}
                  style={{ width: "55%", margin: "auto" }}
                />
                <div className="card_text">Departments</div>
              </Card>
            </Link>
          </Col>

          <Col className="d-flex justify-content-center" lg="4" sm="2" xs="6">
            <Link to="/achievements">
              <Card className="quick_access_card d-flex justify-content-center py-3 mx-auto">
                <img
                  className="img-fluid"
                  alt="Card image"
                  src={require("../assets/img/icons/common/achievements.png")}
                  style={{ width: "55%", margin: "auto" }}
                />
                <div className="card_text">Achievements</div>
              </Card>
            </Link>
          </Col>

          <Col className="d-flex justify-content-center" lg="4" sm="2" xs="6">
            <Link to="/gallery">
              <Card className="quick_access_card d-flex justify-content-center py-3 mx-auto">
                <img
                  className="img-fluid"
                  alt="Card image"
                  src={require("../assets/img/icons/common/gallery.png")}
                  style={{ width: "55%", margin: "auto" }}
                />
                <div className="card_text">Gallery</div>
              </Card>
            </Link>
          </Col>
          <Col className="d-flex justify-content-center" lg="4" sm="2" xs="6">
            <Link to="/events">
              <Card className="quick_access_card d-flex justify-content-center py-3 mx-auto">
                <img
                  className="img-fluid"
                  alt="Card image"
                  src={require("../assets/img/icons/common/events.png")}
                  style={{ width: "55%", margin: "auto" }}
                />
                <div className="card_text">Events</div>
              </Card>
            </Link>
          </Col>
        </Col>
      </Col>
    </div>
  );
}
