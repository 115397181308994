import React from "react";
import "assets/css/style.css";
import { Container, Row, Col, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";

class Fees extends React.Component {
  state = {};

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Fees - Indian Institute of Information Technology, Kota</title>
          <meta
            name="description"
            content="Fees - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="Fees of iiit kota, iiit kota fee structure, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Fees</b>
                  </h2>
                </Col>
              </Row>
              <Row className="text-center">
                <Col lg={4} xs={12} className="mb-5">
                  <UncontrolledDropdown>
                    <DropdownToggle caret>
                      B.Tech Fees
                    </DropdownToggle>
                    <DropdownMenu>
                      
                      <DropdownItem href="https://files.iiitkota.ac.in/pdf/fees/Fee_Structure_2024-25.pdf">
                        B.Tech 2024-25
                      </DropdownItem>
                      <DropdownItem href="https://files.iiitkota.ac.in/pdf/fees/Fee_Structure_2023-24.pdf">
                        B.Tech 2023-24
                      </DropdownItem>
                      <DropdownItem href="https://files.iiitkota.ac.in/pdf/Fee_Structure_2022-23_New.pdf">
                        B.Tech 2022-23
                      </DropdownItem>
                      <DropdownItem href="https://drive.google.com/file/d/1KPfnvxb64E7IIDhDdtg6udkA3GKlKmyU/view?usp=sharing">
                        B.Tech 2021-22
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Col>
                <Col lg={4} xs={12} className="mb-5">
                  <UncontrolledDropdown>
                    <DropdownToggle caret>
                      M.Tech Fees
                    </DropdownToggle>
                    <DropdownMenu>
                      
                      <DropdownItem href="https://files.iiitkota.ac.in/pdf/fees/_Fee structure MTech_2024-25-ccmt.pdf">
                        M.Tech (Full-Time) 2024-25
                      </DropdownItem>
                      <DropdownItem href="https://files.iiitkota.ac.in/pdf/fees/Fee structure MTech_2024-25 part time.pdf">
                        M.Tech (Part-Time) 2024-25
                      </DropdownItem>
                      <DropdownItem href="https://files.iiitkota.ac.in/pdf/mtechfee23-24.pdf">
                        M.Tech 2023-24
                      </DropdownItem>
                      <DropdownItem href="#">
                        M.Tech 2022-23
                      </DropdownItem>
                      <DropdownItem href="https://files.iiitkota.ac.in/pdf/fees/mtechfee21-22.pdf">
                        M.Tech 2021-22
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Col>
                <Col lg={4} xs={12} className="mb-5">
                  <UncontrolledDropdown>
                    <DropdownToggle caret>
                      Ph.D Fees
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem href="https://files.iiitkota.ac.in/pdf/fees/Fee structure PhD_2024-25.pdf">
                        Ph.D 2024-25
                      </DropdownItem>
                      <DropdownItem href="https://files.iiitkota.ac.in/pdf/phdfee23-24.pdf">
                        Ph.D 2023-24
                      </DropdownItem>
                      <DropdownItem href="https://files.iiitkota.ac.in/pdf/fees/phdfee21-22.pdf">
                        Ph.D 2021-22
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col
                  lg={12}
                  xs={12}
                  className="justify-content-center text-justify "
                >
                  <h4>
                    <b>Hostel Fees</b>
                  </h4>
                  <h6>
                    <div>
                      For detailed hostel fee structure, refer the link below:
                      <br />
                      Note: Any charges levied by banks for fee payments, will
                      be paid by student.
                      <ul className="mt-2">
                        <li>
                          <a href="https://files.iiitkota.ac.in/pdf/Hostel_Fees.pdf">
                            Hostel Fees for Students at IIIT Kota Campus
                          </a>
                        </li>
                      </ul>
                    </div>
                  </h6>
                </Col>
              </Row>
              <Row className="mt-5 ">
                <Col
                  lg={12}
                  xs={12}
                  className="justify-content-center text-justify "
                >
                  <h4>
                    <b>Procedure</b>
                  </h4>
                  <h6>
                    <div>
                      <ul>
                        <li>
                          All New/Existing B. Tech. / M.Tech. / Ph.D. students
                          should pay fees before registration date using 
                          <a href="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=646985">
                          {" "}State Bank Collect online portal.
                          </a>
                        </li>
                        <li>
                          Select Internet Banking or SBI debit card while paying
                          fee through SBI Collect for lesser charges.
                        </li>
                        <li>
                          Students are advised to retain their receipt generated
                          through State Bank Collect for proof of fee deposited.
                        </li>
                        <li>Do not use cash or direct account transfer for fee payment.</li>
                      </ul>
                    </div>
                  </h6>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col
                  lg={12}
                  xs={12}
                  className="justify-content-center text-justify mt-3"
                >
                  <h4>
                    <b>Educational Loan Scheme</b>
                  </h4>
                  <h6>
                    <div>
                      Students may visit 
                      <a href="https://www.vidyalakshmi.co.in/Students/">
                      {" "}Vidya Laxmi portal{" "}
                          </a>
                      for education loan.
                    </div>
                  </h6>
                </Col>
              </Row>
              <Row className="justify-content-center text-center mt-5 mb-5">
                <Col lg="12">
                  <h4>
                    <b>Pay Fees Online</b>
                  </h4>
                </Col>
                <Col className="mt-4" lg={4}>
                  <Button
                    className="btn-lg ml-1 theme-color-kota-bg"
                    type="button"
                    href="https://www.onlinesbi.sbi/sbicollect/icollecthome.htm?corpID=646985"
                    target="_blank"
                  >
                    Click here to Pay
                  </Button>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Fees;
