import React from "react";
import { Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
class Cse extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Department - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Department Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Department, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Deparment in IIIT Kota, CSE Deparment,ECE Department , Electronics and Communication Engineering Department,Computer Science and Engineering Department,IIIT Kota Department,CSE Programme Advisors,ECE Programme Advisors"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg ">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Departments</b>
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col lg={12} xs={12}>
                  <h4>
                    <b>Computer Science and Engineering</b>
                  </h4>
                  <h6 className="pb-3" style={{textAlign:"justify"}}>
                  The Department of Computer Science and Engineering has been functioning 
                  since July 2013. Currently, the department has a total intake of 180
                   seats in a four-year B.Tech program and 27 seats in a two-year M.Tech
                    program. Apart from the faculty members of the institute, the department 
                    benefits from the distinguished faculty of MNIT Jaipur, our mentor institute. 
                    The department is in the process of establishing state-of-the-art labs to better
                     support the UG, PG, and PhD programs.
                  </h6>
                </Col>
                <Col lg={12} xs={12}>
                  <h5>
                    <b>Offered Programs</b>
                  </h5>
                  <h6>
                    <ul>
                      <li>
                      Four-year B.Tech in Computer Science and Engineering
                      </li>
                      <li>
                      Four-year B.Tech in Artificial Intelligence and Data Engineering
                      </li>
                      <li>
                      Two-year Full-Time M.Tech in Computer Science and Engineering with
                      Specialization in (Artificial Intelligence and Data Science)
                      </li>
                      <li>
                      Three-year Part-Time M.Tech in Computer Science and Engineering with
                      Specialization in (Artificial Intelligence and Data Science)
                      </li>
                      <li>
                      Ph.D. in Computer Science and Engineering
                      </li>
                    </ul>
                  </h6>

                  <h5>
                    <b>Head of the Department (HoD)</b>
                  </h5>
                  <h6>
                    <ul>
                      <li>
                        <a href="faculty/608525427fb8f4001564e185">Dr. Amit Kumar</a>
                      </li>
                    </ul>
                  </h6>

                  <h5>
                    <b>Departmental Undergraduate Committee (DUGC) Convener</b>
                  </h5>
                  <h6>
                    <ul>
                      <li>
                        <a href="/faculty/608540887fb8f4001564e1b2">Dr. Isha Pathak Tripathi</a>
                      </li>
                    </ul>
                  </h6>

                  <h5>
                    <b>Departmental Postgraduate Committee (DPGC) Convener</b>
                  </h5>
                  <h6>
                    <ul>
                      <li>
                        <a href="/faculty/608525427fb8f4001564e185">Dr. Amit Kumar</a>
                      </li>
                    </ul>
                  </h6>
                  <h5>
                    <b>Programme Advisors (UG)</b>
                  </h5>
                  {/* <p> */}
                  <h6>
                    <ul>
                      <li>
                        <b>First Year:</b> <a href="/faculty/608525427fb8f4001564e185">Dr. Amit Kumar</a>
                      </li>
                      <li>
                        <b>Second Year:</b> <a href="/faculty/60853e867fb8f4001564e1b0">Dr. Gyan Singh Yadav</a>
                      </li>
                      <li>
                        <b>Third Year:</b> <a href="/faculty/6107a3fc6f54f50015b925b4">Dr. Ajay Nehra</a>
                      </li>
                      <li>
                        <b>Final Year:</b> <a href="/faculty/608540887fb8f4001564e1b2">Dr. Isha Pathak Tripathi</a>
                      </li>
                    </ul>
                  </h6>
                </Col>
              </Row>
              
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Cse;