import React from "react";
import "assets/css/style.css";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
class Courses extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Couses - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Courses Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Courses, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Courses in IIIT Kota,CSE in IIIT Kota,ECE in IIIT Kota, CSE,ECE,Computer Science and Engineering in IIIT Kota,Computer Science and Engineering, Electromnincs and Communication Engineering in IIIT Kota, Electromnincs and Communication Engineering,1st Year Cse,1ST YEAR ECE,2ND YEAR CSE,2ND YEAR ECE,
            3RD YEAR CSE,3RD YEAR ECE,4TH YEAR CSE,4TH YEAR ECE"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Courses</b>
                  </h2>
                </Col>
              </Row>
              <Row className="mb-5">
                <Col lg={12}>
                  <h6>
                    The list of all courses offered by IIIT-Kota is given below.
                    New courses might be added to these list as and when they
                    are approved. Click below to display cources from a specific
                    discipline.
                  </h6>
                </Col>
              </Row>
            </Container>
            <section className="section section-lg pt-lg-0 ">
              <Container>
                <Row className="justify-content-center mb-5">
                  <Col lg="12">
                    <Row className="row-grid">
                      <Col lg="3">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a href="https://files.iiitkota.ac.in/pdf/courses/1-YEAR.pdf">
                                <i className="fa fa-file" />
                              </a>
                            </div>

                            <h6 className="text-primary text-uppercase">
                              <a href="https://files.iiitkota.ac.in/pdf/courses/1-YEAR.pdf">
                                {" "}
                                <b>1st Year CSE</b>
                              </a>
                            </h6>

                            <h6>
                              Click here for the detailed Course of 1st Year
                              CSE.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="3">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a href="https://files.iiitkota.ac.in/pdf/courses/1-YEAR.pdf">
                                <i className="fa fa-file" />
                              </a>
                            </div>

                            <h6 className="text-primary text-uppercase">
                              <a href="https://files.iiitkota.ac.in/pdf/courses/1-YEAR.pdf">
                                <b>1st Year ECE</b>
                              </a>
                            </h6>

                            <h6>
                              Click here for the detailed Course of 1st Year
                              ECE.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="3">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a href="https://files.iiitkota.ac.in/pdf/courses/2-CSE.pdf">
                                <i className="fa fa-file" />
                              </a>
                            </div>

                            <h6 className="text-primary text-uppercase">
                              <a href="https://files.iiitkota.ac.in/pdf/courses/2-CSE.pdf">
                                <b> 2nd Year CSE</b>
                              </a>
                            </h6>

                            <h6>
                              Click here for the detailed Course of 2nd Year
                              CSE.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="3">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a href="https://files.iiitkota.ac.in/pdf/courses/2-ECE.pdf">
                                <i className="fa fa-file" />
                              </a>
                            </div>

                            <h6 className="text-primary text-uppercase">
                              <a href="https://files.iiitkota.ac.in/pdf/courses/2-ECE.pdf">
                                <b>2nd Year ECE</b>
                              </a>
                            </h6>

                            <h6>
                              Click here for the detailed Course of 2nd Year
                              ECE.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>{" "}
                <Row className="justify-content-center mb-5">
                  <Col lg="12">
                    <Row className="row-grid">
                      <Col lg="3">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a href="https://files.iiitkota.ac.in/pdf/courses/3-CSE.pdf">
                                <i className="fa fa-file" />
                              </a>
                            </div>

                            <h6 className="text-primary text-uppercase">
                              <a href="https://files.iiitkota.ac.in/pdf/courses/3-CSE.pdf">
                                <b>3rd Year CSE</b>
                              </a>
                            </h6>

                            <h6>
                              Click here for the detailed Course of 3rd Year
                              CSE.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="3">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a href="https://files.iiitkota.ac.in/pdf/courses/3-ECE.pdf">
                                <i className="fa fa-file" />
                              </a>
                            </div>

                            <h6 className="text-primary text-uppercase">
                              <a href="https://files.iiitkota.ac.in/pdf/courses/3-ECE.pdf">
                                <b>3rd Year ECE</b>
                              </a>
                            </h6>

                            <h6>
                              Click here for the detailed Course of 3rd Year
                              ECE.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="3">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a href="https://files.iiitkota.ac.in/pdf/courses/4-CSE.pdf">
                                <i className="fa fa-file" />
                              </a>
                            </div>

                            <h6 className="text-primary text-uppercase">
                              <a href="https://files.iiitkota.ac.in/pdf/courses/4-CSE.pdf">
                                <b>4th Year CSE</b>
                              </a>
                            </h6>

                            <h6>
                              Click here for the detailed Course of 4th Year
                              CSE.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="3">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a href="https://files.iiitkota.ac.in/pdf/courses/4-ECE.pdf">
                                <i className="fa fa-file" />
                              </a>
                            </div>

                            <h6 className="text-primary text-uppercase">
                              <a href="https://files.iiitkota.ac.in/pdf/courses/4-ECE.pdf">
                                <b>4th Year ECE</b>
                              </a>
                            </h6>

                            <h6>
                              Click here for the detailed Course of 4th Year
                              ECE.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </section>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Courses;
