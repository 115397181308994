import React from "react";
import {
  Carousel,
  onClickThumb,
  onClickItem,
  onChange,
} from "react-responsive-carousel";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import classnames from "classnames";
import {
  Container,
  Row,
  Col,
  CardBody,
  Card,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
import "assets/css/style.css";
class AboutInstitute extends React.Component {
  state = {
    iconTabs: 1,
    plainTabs: 1,
  };
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };
  // state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            About - Indian Institute of Information Technology, Kota
          </title>
          <meta
            name="description"
            content="About - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="Explore, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg text-justify">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>About IIIT Kota</b>
                  </h2>
                </Col>
              </Row>
              <section style={{ marginBottom: "100px" }} className="mt-3">
                <Container>
                  <Row className="justify-content-center">
                    <Col lg="12">
                      <div className="nav-wrapper mr-lg--6 ml-lg--6">
                        <Nav
                          className="nav-fill flex-column flex-md-row"
                          id="tabs-icons-text"
                          pills
                          role="tablist"
                        >
                          <NavItem>
                            <NavLink
                              aria-selected={this.state.iconTabs === 1}
                              className={classnames("mb-sm-3 mb-md-0", {
                                active: this.state.iconTabs === 1,
                              })}
                              onClick={(e) => this.toggleNavs(e, "iconTabs", 1)}
                              href="!#"
                              role="tab"
                            >
                              <i className="ni ni-chart-bar-32 mr-2" />
                              Vision
                            </NavLink>
                          </NavItem>
                          <NavItem>
                            <NavLink
                              aria-selected={this.state.iconTabs === 2}
                              className={classnames("mb-sm-3 mb-md-0", {
                                active: this.state.iconTabs === 2,
                              })}
                              onClick={(e) => this.toggleNavs(e, "iconTabs", 2)}
                              href="!#"
                              role="tab"
                            >
                              <i className="ni ni-spaceship mr-2" />
                              Mission
                            </NavLink>
                          </NavItem>
                          {/* <NavItem>
                        <NavLink
                          aria-selected={this.state.iconTabs === 3}
                          className={classnames("mb-sm-3 mb-md-0", {
                            active: this.state.iconTabs === 3,
                          })}
                          onClick={(e) => this.toggleNavs(e, "iconTabs", 3)}
                          href="!#"
                          role="tab"
                        >
                          <i className="ni ni-archive-2 mr-2" /> Values
                        </NavLink>
                      </NavItem> */}
                        </Nav>
                      </div>
                      <Card className="shadow ml-lg--6 mr-lg--6">
                        <CardBody>
                          <TabContent
                            activeTab={"iconTabs" + this.state.iconTabs}
                          >
                            <TabPane tabId="iconTabs1">
                              <Row class="justify-content-center">
                                <Col xs={12} lg={3}>
                                  <img
                                    alt="/"
                                    className="sectionimg"
                                    src={require("assets/img/theme/Vission.gif")}
                                    style={{
                                      height: "230px",
                                      width: "220px",
                                    }}
                                  />
                                </Col>
                                <Col lg={9} xs={12}>
                                  <h5
                                    className="text-justify tabscontent"
                                    style={{
                                      marginTop: "51px",
                                    }}
                                  >
                                    {/* Our Vision can be briefly explained by a Quote
                                of John Dewey: “If we teach today as we taught
                                yesterday, we rob our children of tomorrow.” */}
                                    <br />
                                    To be a globally competitive institute by
                                    imparting research and industry-oriented
                                    education.
                                  </h5>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="iconTabs2">
                              <Row>
                                <Col lg={3}>
                                  <img
                                    alt="/"
                                    className="sectionimg"
                                    src={require("assets/img/theme/Mission.gif")}
                                    style={{
                                      height: "230px",
                                      width: "220px",
                                    }}
                                  />
                                </Col>
                                <Col lg={9}>
                                  <h5
                                    className="text-justify tabscontent"
                                    style={{
                                      marginTop: "50px",
                                    }}
                                  >
                                    To create technical professionals to achieve
                                    the present and future requirements of
                                    engineering and allied disciplines, IIIT
                                    Kota imparts the educational system that
                                    transforms students into human power
                                    embedded with creativity, innovation and
                                    technology for the betterment of society.
                                  </h5>
                                </Col>
                              </Row>
                            </TabPane>
                            {/* <TabPane tabId="iconTabs3">
                          <Row>
                            <Col lg={3}>
                              <img
                                alt="/"
                                className="sectionimg"
                                src={require("assets/img/theme/Values.gif")}
                                style={{ height: "230px", width: "220px" }}
                              />
                            </Col>
                            <Col lg={9}>
                              <h5
                                className="text-justify tabscontent"
                                style={{
                                  marginTop: "56px",
                                }}
                              >
                                IIIT Kota has been a values-driven Institute.
                                These values continue to direct the growth and
                                Education of students studying here. <br />
                                We educate with the core values of Integrity,
                                Responsibility, Leadership, Excellence and
                                Innovation.
                              </h5>
                            </Col>
                          </Row>
                        </TabPane>
                      */}
                          </TabContent>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </section>
              <Row>
                <Col lg={12} xs={12}>
                  <h5>
                    <b>
                      IIIT Kota has been challenging and developing great minds
                      for many years.
                    </b>
                  </h5>
                </Col>
                <Col lg={12} className="mt-3" xs={12}>
                  <h6>
                    Characterised by a tradition of creating a center for
                    imparting technical education of international standards, we
                    are committed to excellence in education, to innovative
                    practice and to the concept of lifelong learning.The Indian
                    Institute of Information Technology Kota, deemed to be a
                    Institute of National Importance, was established in 2013
                    with a vision to contribute the IT world through education
                    and research, entrepreneurship and innovation. Currently the institute is mentored by Malaviya National
                    Institute of Technology Jaipur (MNIT Jaipur).
                  </h6>
                </Col>
                <Col lg={12} className="mt-3" xs={12}>
                  <h6>
                    <b>
                      IIIT Kota is a joint venture of the Ministry of Education
                      (MoE) Govt. of India and Govt. of Rajasthan with Industry
                      Partners in Public-Private-Partnership (PPP) mode.
                    </b>
                  </h6>
                </Col>
                
                <Col lg={12} className="mt-3" xs={12}>
                  <h6>
                    <b>
                      {" "}
                      Currently we offer B.Tech., M.Tech., & Ph.D. in the discipline of Computer Science & Engineering, Electronics & Communication Engineering and Artificial Intelligence and Data Engineering.
                    </b>{" "}
                    The institute attracts students from all over India and has
                    the unique distinction of selecting 100% students on merit
                    from across the country and abroad, pushing up the level of
                    discourse and peer group at the institute.
                  </h6>
                </Col>
                <Col lg={12} className="mt-3" xs={12}>
                  <h6>
                    It has a unique positioning as a leading technology
                    institute with its interdisciplinary courses which
                    crisscross information technology and social sciences being
                    pursued by students from diverse academic backgrounds.
                    Intersecting between technology and society, IIITK has grown
                    in stature to become an institution of considerable repute
                    with its unique model of education, research, and industry
                    interaction. It works in partnership with the corporate
                    sector, while retaining the freedom of an academic
                    institution. Inspired by other renowned institutions, it
                    strives to emulate an academic culture that is on par with
                    the best international institutions.
                  </h6>
                </Col>
                <Col lg={12} xs={12} className="mt-5">
                  <h4>
                    <b> IIIT Kota's Permanent Campus</b>
                  </h4>
                </Col>
                <Col lg={12} className="mt-3" xs={12}>
                  <h6>
                    IIIT Kota's permanent campus is situated in Ranpur, near
                    Kota. The campus boasts an administrative and academic
                    block, which includes the Director's Office, Senate Hall,
                    various other offices, and a sufficient number of
                    air-conditioned lecture theaters, tutorial rooms,
                    laboratories, and a 200-seat Auditorium. Additionally,
                    there's a 100-seat, WiFi-enabled computer center. The entire
                    perimeter of the campus is enveloped by lush trees.
                    Furthermore, there are accommodations for the director and
                    staff. In the upcoming phase, there are plans to construct a
                    Library building, a Health and Wellness Center, a
                    multipurpose sports facility, additional hostels, and
                    residences for faculty and staff.
                  </h6>
                </Col>
              </Row>
              <Row className="mt-3 text-justify">
                <Col lg={12} xs={12} className="mt-3">
                  <h4>
                    <b>Library</b>
                  </h4>
                  <h6>
                    The Institute has a spacious and well equipped library which
                    is being run according to an open access system. It has rich
                    collection of about 1,33,600 volumes of books, periodicals,
                    reports and reference material. In addition to the books
                    from main library, some more books are issued to students
                    from book bank also. There is a video viewing facility.
                    Large number of books, periodicals, video cassettes and
                    CD-ROMs are available. The library is being fully
                    computerized.
                  </h6>
                </Col>
              </Row>
              <Row className="justify-content-center ">
                <Col lg={12} xs={12} className="mt-5">
                  <center>
                    <h4>
                      <b>Campus at Ranpur, Kota </b>
                    </h4>
                  </center>
                </Col>
                <Col lg={9} xs={12} className="mt-5">
                  <center>
                    <Carousel
                      showArrows={true}
                      onChange={onChange}
                      onClickItem={onClickItem}
                      onClickThumb={onClickThumb}
                    >
                      <div>
                        <img
                          alt="/"
                          className="campus_plan_img"
                          src={require("assets/img/campus_plan/admin_block_1.png")}
                        />
                        {/* <h6 className="legend">Admin and Academic Block</h6> */}
                      </div>
                      <div>
                        <img
                          alt="/"
                          className="campus_plan_img"
                          src={require("assets/img/campus_plan/admin_block_2.png")}
                        />
                        {/* <h6 className="legend">Admin and Academic Block</h6> */}
                      </div>
                      <div>
                        <img
                          alt="/"
                          className="campus_plan_img"
                          src={require("assets/img/campus_plan/admin_block_3.png")}
                        />
                        {/* <h6 className="legend">Admin and Academic Block</h6> */}
                      </div>
                      {/* <div>
                        <img
                          alt="/"
                          className="campus_plan_img"
                          src={require("assets/img/campus_plan/admin_block_4.jpg")}
                        /> */}
                      {/* <h6 className="legend">Admin and Academic Block</h6> */}
                      {/* </div> */}
                      <div>
                        <img
                          alt="/"
                          className="campus_plan_img"
                          src={require("assets/img/campus_plan/Boys_hostel_1.png")}
                        />
                        {/* <h6 className="legend">Admin and Academic Block</h6> */}
                      </div>
                      <div>
                        <img
                          alt="/"
                          className="campus_plan_img"
                          src={require("assets/img/campus_plan/Boys_hostel_2.jpg")}
                        />
                        {/* <h6 className="legend">Admin and Academic Block</h6> */}
                      </div>
                      <div>
                        <img
                          alt="/"
                          className="campus_plan_img"
                          src={require("assets/img/campus_plan/Girls_hostel_1.png")}
                        />
                        {/* <h6 className="legend">Admin and Academic Block</h6> */}
                      </div>
                      <div>
                        <img
                          alt="/"
                          className="campus_plan_img"
                          src={require("assets/img/campus_plan/Dining_Area.jpg")}
                        />
                        {/* <h6 className="legend">Legend 3</h6> */}
                      </div>
                      <div>
                        <img
                          alt="/"
                          className="campus_plan_img"
                          src={require("assets/img/campus_plan/Directors_Residence.jpg")}
                        />
                        {/* <h6 className="legend">Legend 4</h6> */}
                      </div>
                      <div>
                        <img
                          alt="/"
                          className="campus_plan_img"
                          src={require("assets/img/campus_plan/Google_earth_photo.png")}
                        />
                        {/* <h6 className="legend">Legend 5</h6> */}
                      </div>
                      <div>
                        <img
                          alt="/"
                          className="campus_plan_img"
                          src={require("assets/img/campus_plan/housing2&3.jpg")}
                        />
                        {/* <h6 className="legend">Legend 6</h6> */}
                      </div>
                      <div>
                        <img
                          alt="/"
                          className="campus_plan_img"
                          src={require("assets/img/campus_plan/Housing4&5.jpg")}
                        />
                        {/* <h6 className="legend">Legend 6</h6> */}
                      </div>
                      <div>
                        <img
                          alt="/"
                          className="campus_plan_img"
                          src={require("assets/img/campus_plan/proposed_master_plan.jpg")}
                        />
                        {/* <h6 className="legend">Legend 6</h6> */}
                      </div>
                    </Carousel>
                  </center>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default AboutInstitute;
