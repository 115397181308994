import React from "react";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import "assets/css/style.css";
import Events from "views/EventSection/Events";
import Helmet from "react-helmet";
import Marquee from "react-fast-marquee";

import {
  
  Row,
  Col,
  // Container,
  // CardBody,
  UncontrolledCarousel,
  // Card,
  // Button,
  // NavItem,
  // NavLink,
  // Nav,
  // TabContent,
  // TabPane,

} from "reactstrap";

// import Slide from "react-reveal/Slide";
import News from "components/News";
import Strip from "./Strip/Strip";
import { Link } from "react-router-dom";

const items = [
  {
    src: require("assets/img/carousel/iit-hackathon.webp"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/carousel/alumnus-vibhor.webp"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/carousel/orientation-24.webp"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/carousel/15aug-24.webp"),
    altText: "",
    caption: "",
    header: "",
  },
  /*
  {
    src: require("assets/img/carousel/iiitk-admission-24.webp"),
    altText: "",
    caption: "",
    header: "",
  },
  */
  {
    src: require("assets/img/carousel/iiit-kota-3-rd-convocation-1.webp"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/carousel/iiit-kota-3-rd-convocation-2.webp"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/carousel/YuvaSangam.webp"),
    altText: "",
    caption: "",
    header: "",
  },
  // {
  //   src: require("assets/img/carousel/inaug1.webp"),
  //   altText: "",
  //   caption: "",
  //   header: "",
  // },
  // {
  //   src: require("assets/img/carousel/inaug.webp"),
  //   altText: "",
  //   caption: "",
  //   header: "",
  // },
  // {
  //   src: require("assets/img/carousel/independenceDay.webp"),
  //   altText: "",
  //   caption: "",
  //   header: "",
  // },
  {
    src: require("assets/img/carousel/chairmanVisit.webp"),
    altText: "",
    caption: "",
    header: "",
  },
  // {
  //   src: require("assets/img/carousel/inaug.webp"),
  //   altText: "",
  //   caption: "",
  //   header: "",
  // },
  // 
  {
    src: require("assets/img/carousel/frontjul27.jpeg"),
    altText: "",
    caption: "",
    header: "",
  },
  // {
  //   src: require("assets/img/carousel/SecondFrame.webp"),
  //   altText: "",
  //   caption: "",
  //   header: "",
  // },
  // {
  //   src: require("assets/img/carousel/ThirdFrame.webp"),
  //   altText: "",
  //   caption: "",
  //   header: "",
  // },
  // {
  //   src: require("assets/img/carousel/FourthFrame.webp"),
  //   altText: "",
  //   caption: "",
  //   header: "",
  // },
  // {
  //   src: require("assets/img/carousel/FifthFrame.webp"),
  //   altText: "",
  //   caption: "",
  //   header: "",
  // },

  {
    src: require("assets/img/carousel/22.webp"),
    altText: "",
    caption: "",
    header: "",
  },

  // {
  //   src: require("assets/img/carousel/19.webp"),
  //   altText: "",
  //   caption: "",
  //   header: "",
  // },



];

class Index extends React.Component {
  state = {
    iconTabs: 1,
    plainTabs: 1,
  };
  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    // document.addEventListener("contextmenu", (e) => {
    //   e.preventDefault();
    // });
  }
  
  render() {
    return (
      <>
        <Helmet>
          <title>Indian Institute of Information Technology Kota</title>
          <meta
            name="description"
            content="Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content=" IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,MNIT Jaipur"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="mt-6">
            <UncontrolledCarousel items={items} interval={6000} />
            <div className="welcome d-flex justify-content-center align-items-center">
              <Marquee
                speed={60}
                gradient={false}
                pauseOnHover={true}
                style={{ fontWeight: "900", color: "#fff" }}
              >
                {/* <a
                  href="/pg-procedure"
                  style={{ color: "#fff", margin: "0 60px" }}
                >
                  <li>Admissions in Ph.D (CSE & ECE) 2023</li>
                </a> */}

                  {/*
                <a
                  href="/ug-procedure"
                  style={{ color: "#fff", margin: "0 60px" }}
                >

                
                  <li>
                     B.Tech. Admissions (2024-25), Physical Reporting
                    will be from 10<sup>th</sup> Aug to 5<sup>th</sup> Sep,
                    2024 
                  </li>
                </a>
                    */}

                
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                <a href="/announcement" style={{ color: "#fff", margin: "0 60px" }}>
                  <li>
                  Appointment of Directors of Indian Institutes of Information Technology established in Public- Private Partnership mode (IIIT-PPP) at Nagpur, Sri City (Chittoor), Kota, Lucknow, Kottayam, Tiruchirappalli and Guwahati
                  </li>
                </a>

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                
                <a href="/tender-invitations" style={{ color: "#fff", margin: "0 60px" }}>
                  <li>
                    Tender Invitations
                  </li>
                </a>
                
                {/*
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                
                <a href="/recruitment" style={{ color: "#fff", margin: "0 60px" }}>
                  <li>
                  Applicants are invited to attend the Walk-in-Interview for the post of Medical Officer (on contract)
                  </li>
                </a>
              

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


                  <a href="/recruitment" style={{ color: "#fff", margin: "0 60px" }}>
                  <li>
                  Advertisement for the post of Training and Placement Manager (Purely on short term contract basis)
                  </li>
                </a>
                */}
              
                {/*
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            
                <a href="https://files.iiitkota.ac.in/pdf/pg/Annexure.pdf" style={{ color: "#fff", margin: "0 60px" }}>
                  <li>
                  Announcement of Result of PhD Admission 2024-25
                  </li>
                </a>
                */}

                {/*
                
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                <a href="https://files.iiitkota.ac.in/pdf/Phd/List of Documents For PG Admission (1).pdf" style={{ color: "#fff", margin: "0 60px" }}>
                  <li>
                  List of Required Documents For PhD Admission
                  </li>
                </a>

                */}

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

            

              
                <a href="https://files.iiitkota.ac.in/pdf/ragging/antiragging_committee_contact_details.pdf" style={{ color: "#fff", margin: "0 60px" }}>
                  <li>
                    Contact Details of Anti Ragging Committee
                  </li>
                </a>

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;



                {/* <a href="/announcement" style={{ color: "#fff", margin: "0 60px" }}>
                  <li>
                    Advertisement for the Post of Project Staff (JRF) (Purely on Contract) Last Date- 31st August 2024
                  </li>
                </a>

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}

                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href="" style={{ color: "#fff", margin: "0 60px" }}>
                  <li>
                    Welcome to Indian Institute Of Information Technology, Kota
                  </li>
                </a>

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                {/*

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                
                <a href="" style={{ color: "#fff", margin: "0 60px" }}>
                  <li>
                    Orientation Detatils Date:19th August, Time:10AM-12PM, Venue-Auditorium
                  </li>
                </a>

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                


                <a href="https://iiitkota.ac.in/tender-invitations" style={{ color: "#fff", margin: "0 60px" }}>
                  <li>
                    Tender Invitations
                  </li>
                </a>


                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;


                
                */}

                {/* <a href="https://iiitkota.ac.in/pg-procedure" style={{ color: "#fff", margin: "0 60px" }}>
                  <li>
                    Ph.D and M.Tech Admission 2024
                  </li>
                </a>

                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}



              </Marquee>
              {/* <Container style={{backgroundColor:"#1a1c4e", fontWeight:"900"}}>

              <div className="welcome d-flex justify-content-center align-items-center">
              <a href="/convocation" style={{ color: "#fff", margin: "0 60px" }}>
                 Live: 2nd Convocation IIIT Kota (April 12, 2023 by 2 PM onwards)
                </a>
              </div>
              </Container> */}
            </div>
          </div>
          {/* <div className="position-relative">
            <section className="section section-hero section-shaped">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span className="span-150" />
                <span className="span-50" />
                <span className="span-50" />
                <span className="span-75" />
                <span className="span-100" />
                <span className="span-75" />
                <span className="span-50" />
                <span className="span-100" />
                <span className="span-50" />
                <span className="span-100" />
              </div>
              <Container
                className="shape-container d-flex align-items-center py-lg"
                style={
                  ({ paddingTop: "50px !important" },
                  { paddingBottom: "50px !important" })
                }
              >
                <div className="col px-0">
                  <Row
                    className="align-items-center justify-content-center"
                    data-aos="fade-up"
                  >
                    <Col className="text-center" lg="8">
                      <img
                        src={require("../assets/img/brand/logo.png")}
                        alt="..."
                        className="img-fluid"
                        style={{ width: "200px", opacity: "0.9" }}
                      />

                      <p className="lead text-white" data-aos="fade-up">
                        <b>
                          <span style={{ fontSize: "25px" }}>
                            Indian Institute of Information Technology Kota
                            <br />
                            भारतीय सूचना प्रौद्योगिकी संस्थान कोटा
                          </span>{" "}
                          <br />
                          (An Institute of National Importance under an Act of
                          Parliament)
                        </b>
                      </p>
                    </Col>
                  </Row>
                </div>
              </Container>

              <div className="separator separator-bottom separator-skew zindex-100">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div> */}

          <section className="section section-shaped">
            {/* <Container className="py-md">
              <Col className="mb-12" data-aos="fade-up" data-aos-delay="100">
                <Slide>
                  <center>
                    <h2
                      data-aos="fade-up"
                      style={{
                        fontSize: "2rem !important",

                        fontWeight: "600",
                        lineHeight: "1.5",
                        marginBottom: "20px",
                      }}
                    >
                      Welcome to Indian Institute of Information Technology Kota
                    </h2>
                  </center>
                </Slide>
              </Col>
              <Row className="justify-content-center align-items-center">
                <Col className="mb-5 mb-lg-0" lg="12">
                  {" "}
                  <div
                    className="rounded shadow-lg overflow-hidden "
                    lineHeight={"200px"}
                  >
                    <UncontrolledCarousel items={items} />
                  </div>{" "}
                </Col>
                <Col className="mb-5 mb-lg-0" lg="12">
                  <h5 className="text-justify mt-4">
                    Indian Institute of Information Technology, Kota (IIIT,
                    Kota) is an institute of national importance. IIIT Kota is a
                    joint venture of the Ministry of Education (MoE) Govt. of
                    India and Govt. of Rajasthan with Industry Partners in
                    Public-Private-Partnership (PPP) model. IIIT Kota was
                    established in 2013. Currently, the institute is mentored by
                    Malaviya National Institute of Technology Jaipur (MNIT
                    Jaipur) and is situated at MNIT Jaipur. The main site of
                    IIIT, Kota shall be constructed at Ranpur near Kota on
                    100.37 acres of land made available by the Govt. of
                    Rajasthan.
                  </h5>
                </Col>
              </Row> */}
            {/* <Row className="justify-content-center align-items-center mt-2">
                <Col className="text-center">
                  <a
                    href="https://iiitkota.ac.in/postgraduate-programmes"
                    target="_blank"
                  >
                    <h5 style={{ color: "red" }}>
                      IIIT Kota is inviting applications for admission to PG
                      Programmes.
                      <br />
                      Revised Last Date for Online Application: 18th October
                      2021.
                    </h5>
                  </a>
                </Col>
              </Row> */}
            {/* </Container> */}

            <Container-fluid>
              <Row className="justify-content-center px-3 ">
                <Col
                  className="mb-5 mb-lg-6 d-none d-lg-flex flex-column px-6"
                  md="12"
                  lg="12"
                >
                  <div className="row mx-4 alignAdjust1">
                    <h3
                      style={{
                        fontSize: "1.8rem",
                        fontWeight: "600",
                        lineHeight: "1.5",
                        marginBottom: "20px",
                        borderBottom: "2px solid #1a1c4e",
                      }}
                    >
                      ABOUT IIIT KOTA
                    </h3>
                  </div>
                  <Row className="d-flex justify-content-center mt-2 mb-4 smallNews3">
                    <div class=" rounded row p-2 d-flex justify-content-around align-items-center">
                      <Col md="6" lg="5" className="about-col">
                        <h5
                          className="text-justify"
                          style={{
                            fontSize: "18px",
                            paddingTop: "16px",
                            paddingBottom: "16px",
                            paddingRight: "24px",
                            paddingLeft: "24px",
                          }}
                        >
                          Indian Institute of Information Technology, Kota
                          (IIIT, Kota) is an institute of national importance.
                          IIIT Kota is a joint venture of the Ministry of
                          Education (MoE) Govt. of India and Govt. of Rajasthan
                          with Industry Partners in Public-Private-Partnership
                          (PPP) model. IIIT Kota was established in 2013.
                          Currently, the institute is mentored by Malaviya
                          National Institute of Technology Jaipur (MNIT Jaipur)
                          and is situated at MNIT Jaipur. The permanent campus
                          of IIIT, Kota is being constructed at Ranpur near Kota
                          on 100.37 acres of land made available by the Govt. of
                          Rajasthan. The construction of Phase-1 and Phase 2 of
                          the campus is going on in full swing.
                          <Link to="/about-iiit">Read More.</Link>
                        </h5>
                      </Col>

                      <Col
                        className="embed-responsive embed-responsive-16by9 d-flex justify-content-center "
                        md="6"
                        lg="6"
                        style={{ minHeight: "100%" }}
                      >
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/xTzv_dVrNVU?si=uPE58YvXMlPoz0Xj"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                        ></iframe>
                      </Col>
                    </div>
                  </Row>
                </Col>
                <Col
                  className="mb-5 mb-lg-6 d-none pb-2 pt-4 d-lg-flex flex-column px-6 "
                  md="12"
                  lg="12"
                  style={{ backgroundColor: "#1a1c4e" }}
                >
                  <div className="row mx-4 alignAdjust2">
                    <h3
                      style={{
                        fontSize: "1.8rem",
                        fontWeight: "600",
                        lineHeight: "1.5",
                        // marginBottom: "15px",
                        // marginTop: "20px",
                        color: "white",
                        borderBottom: "2px solid #fff",
                      }}
                    >
                      DIRECTOR'S MESSAGE
                    </h3>
                  </div>
                  <Row className="d-flex justify-content-center mt-2 mb-4 smallNews3">
                    <div class=" rounded row d-flex justify-content-around align-items-center">
                      <Col
                        class="d-flex justify-content-center align-items-center "
                        md="6"
                        lg="6"
                        style={{ minHeight: "100%" }}
                      >
                        <img
                          class="img-fluid ml-3 director"
                          src={require("../assets/img/people/dir_final.jpg")}
                          alt=""
                          style={{
                            height: "50vh",
                            minHeight: "100%",
                            maxWidth: "100%",
                            margin: "20px auto",
                          }}
                        />
                      </Col>
                      <Col md="6" lg="5" className="dir-col">
                        <h5
                          className=" pl-4 py-3 text-justify dir-para"
                          style={{
                            fontSize: "18px",
                            color: "white",
                            padding: "24px",
                          }}
                        >
                          Welcome to Indian Institute of Information Technology,
                          Kota. Since its inception, in 2013, IIIT Kota has
                          accomplished many milestones and covered an
                          illustrious journey in last 8 years. As one of the
                          Premier Institutes, that is a joint venture of the
                          Ministry of Education and Government of Rajasthan with
                          4 strong Industry Partners in Public-Private
                          Partnership (PPP) mode, the institute has come a long
                          way. IIIT Kota provides a highly conducive environment
                          to its students for enhancing their technical and
                          academic skills. Being a part of this institute will
                          definitely provide lot of opportunities for grooming
                          overall personality. I welcome you all to come and
                          join this premier institute so that you can embark on
                          a journey of lifelong learning.
                          <Link to="/directors-message">Read More.</Link>
                        </h5>
                      </Col>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Container-fluid>

            <Container-fluid>
              <Row className="justify-content-center px-3">
                <Col
                  className="mb-5 mb-lg-4 d-flex d-lg-none flex-column"
                  lg="6"
                >
                  <div className="row px-4">
                    <h3
                      style={{
                        fontSize: "1.8rem",
                        fontWeight: "600",
                        lineHeight: "1.5",
                        marginBottom: "20px",
                      }}
                    >
                      ABOUT IIIT KOTA
                    </h3>
                  </div>
                  <Row className="d-flex justify-content-center flex-grow-1 mt-md-2 mt-sm-0 px-lg-3 px-md-3 px-3">
                    <div className="p-2 shadow rounded">
                      <div
                        className="embed-responsive embed-responsive-16by9 d-flex justify-content-center "
                        style={{ width: "90%", margin: "auto" }}
                      >
                        <iframe
                          width="560"
                          height="315"
                          src="https://www.youtube.com/embed/xTzv_dVrNVU?si=uPE58YvXMlPoz0Xj"
                          title="YouTube video player"
                          frameborder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                          allowfullscreen
                        ></iframe>
                      </div>
                      <h5
                        className="mt-4 p-3 text-justify"
                        style={{ fontSize: "16px" }}
                      >
                        Indian Institute of Information Technology, Kota (IIIT,
                        Kota) is an institute of national importance. IIIT Kota
                        is a joint venture of the Ministry of Education (MoE)
                        Govt. of India and Govt. of Rajasthan with Industry
                        Partners in Public-Private-Partnership (PPP) model. IIIT
                        Kota was established in 2013. Currently, the institute
                        is mentored by Malaviya National Institute of Technology
                        Jaipur (MNIT Jaipur) and is situated at MNIT Jaipur.
                        {/* The main site of IIIT, Kota shall be constructed at Ranpur
                        near Kota on 100.37 acres of land made available by the
                        Govt. of Rajasthan.  */}
                        <Link to="/about-iiit">Read More.</Link>
                      </h5>
                    </div>
                  </Row>
                </Col>
                <Col
                  className="mb-5 mb-lg-4 d-flex d-lg-none  flex-column"
                  lg="6"
                >
                  <div className="row px-lg-3 px-md-3 px-3">
                    <h3
                      style={{
                        fontSize: "1.8rem",
                        fontWeight: "600",
                        lineHeight: "1.5",
                        marginBottom: "20px",
                      }}
                    >
                      DIRECTOR'S MESSAGE
                    </h3>
                  </div>
                  <Row className="d-flex justify-content-center flex-grow-1 mt-md-2 mt-sm-0 px-lg-3 px-md-3 px-3">
                    <div class="p-2 shadow rounded ">
                      <div class="d-flex justify-content-center align-items-center">
                        <img
                          class="img-fluid"
                          src={require("../assets/img/people/director_sir.jpg")}
                          alt=""
                          style={{ maxWidth: "90%" }}
                        />
                      </div>
                      <h5
                        className="mt-4 p-3 text-justify"
                        style={{ fontSize: "16px" }}
                      >
                        Welcome to Indian Institute of Information Technology,
                        Kota. Since its inception, in 2013, IIIT Kota has
                        accomplished many milestones and covered an illustrious
                        journey in last 8 years. As one of the Premier
                        Institutes, that is a joint venture of the Ministry of
                        Education and Government of Rajasthan with 4 strong
                        Industry Partners in Public-Private Partnership (PPP)
                        mode, the institute has come a long way.
                        {/* IIIT Kota provides a highly conducive environment to its
                        students for enhancing their technical and academic
                        skills. Being a part of this institute will definitely
                        provide lot of opportunities for grooming overall
                        personality.  */}
                        <Link to="/directors-message">Read More.</Link>
                      </h5>
                    </div>
                  </Row>
                </Col>
              </Row>
            </Container-fluid>

            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon className="fill-white" points="0 0 0 0 0" />
              </svg>
            </div>
          </section>

          <News />

          {/* <EventCarousel /> */}
          <Strip />
          <Events />

          {/*Vision Mission conatiner moved to About Intitue */}
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Index;
