import React from "react";
import { Row, Col, Card, CardBody } from "reactstrap";
import ImageViewer from "./ImageViewer";
import { useState } from "react";

const EventItem = ({ title, description, imageurl }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleImageClick = (event) => {
    setIsOpen(true);
  };

  const handleImageClose = (event) => {
    setIsOpen(false);
  };

  return (
    <Row className="justify-content-center text mb-5">
      <Col lg="12" id={title}>
        <Row className="row-grid justify-content-center">
          <Col lg="12">
            <Card className="card-lift--hover shadow border-0">
              <CardBody 
                style={{ 
                  height: "22rem",
                  overflowY: "auto", 
                }}
              >
                <Row>
                  <Col lg={12} xs={12}>
                    <h4>
                      <b>{title}</b>
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4} xs={12}>
                    <img
                      alt="/"
                      src={imageurl}
                      width="100%"
                      style={{ margin: "auto", cursor: "pointer" }}
                      onClick={() => handleImageClick()}
                    />
                    <ImageViewer
                      imgs={imageurl}
                      isOpen={isOpen}
                      onClose={() => handleImageClose()}
                    />
                  </Col>
                  <Col
                    lg={8}
                    xs={12}
                    className="text-justify mt-2"
                    style={{ whiteSpace: "pre-wrap" }}
                  >
                    <h6>{description}</h6>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default EventItem;
