import React from "react";
import { Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
import {
  Carousel,
  onClickThumb,
  onClickItem,
  onChange,
} from "react-responsive-carousel";
class Fests extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Fests - Indian Institute of Information Technology Kota</title>
          <meta
            name="description"
            content="Fests Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Fests, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Fests in IIIT Kota,IIIT Kota Fests,Flair Festa,Cultural Fest IIIT Kota,Technical Fest IIIT Kota,MNIT Fest,MNIT Jaipur Fest,Sphinx,blitzschlag,Sport event iiit kota,matlab workshop iiit kota"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Fests</b>
                  </h2>
                </Col>
              </Row>
              <Row className="justify-content-center ">
                <Col lg={12} xs={12}>
                  <center>
                    <h4>
                      <b>Flair Festa Images 2019</b>
                    </h4>
                  </center>
                </Col>
                <Col lg={9} xs={12} className="mt-5">
                  <center>
                    <Carousel
                      showArrows={true}
                      onChange={onChange}
                      onClickItem={onClickItem}
                      onClickThumb={onClickThumb}
                    >
                      <div>
                        <img
                          alt="/"
                          src="https://files.iiitkota.ac.in/images/flair%20festa%202019/1.png"
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src="https://files.iiitkota.ac.in/images/flair%20festa%202019/2.png"
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src="https://files.iiitkota.ac.in/images/flair%20festa%202019/3.png"
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src="https://files.iiitkota.ac.in/images/flair%20festa%202019/4.png"
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src="https://files.iiitkota.ac.in/images/flair%20festa%202019/5.png"
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src="https://files.iiitkota.ac.in/images/flair%20festa%202019/6.png"
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src="https://files.iiitkota.ac.in/images/flair%20festa%202019/7.png"
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src="https://files.iiitkota.ac.in/images/flair%20festa%202019/8.png"
                        />
                      </div>
                      {/* <div>
                        <img
                          alt="/"
                          src={require("assets/img/campus_plan/Admin_and_Academic_Block.jpg")}
                        />
                      </div> */}
                    </Carousel>
                  </center>
                </Col>
              </Row>
              {/* <Row className="justify-content-center ">
                <Col lg={12} xs={12} className="mt-5">
                  <center>
                  <h4><b>Flair Festa Images 2018</b></h4>
                  </center>
                </Col>
                <Col lg={9} xs={12} className="mt-5">
                  <center>
                    <Carousel
                      showArrows={true}
                      onChange={onChange}
                      onClickItem={onClickItem}
                      onClickThumb={onClickThumb}
                    >
                      <div>
                        <img
                          alt="/"
                          src={require("assets/img/campus_plan/Admin_and_Academic_Block.jpg")}
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src={require("assets/img/campus_plan/Admin_and_Academic_Block.jpg")}
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src={require("assets/img/campus_plan/Admin_and_Academic_Block.jpg")}
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src={require("assets/img/campus_plan/Admin_and_Academic_Block.jpg")}
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src={require("assets/img/campus_plan/Admin_and_Academic_Block.jpg")}
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src={require("assets/img/campus_plan/Admin_and_Academic_Block.jpg")}
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src={require("assets/img/campus_plan/Admin_and_Academic_Block.jpg")}
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src={require("assets/img/campus_plan/Admin_and_Academic_Block.jpg")}
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src={require("assets/img/campus_plan/Admin_and_Academic_Block.jpg")}
                        />
                      </div>
                    </Carousel>
                  </center>
                </Col>
              </Row>
              <Row className="justify-content-center "> *
                <Col lg={12} xs={12} className="mt-5">
                  <center>
                  <h4><b>Flair Festa Images 2017</b></h4>
                  </center>
                </Col>
                <Col lg={9} xs={12} className="mt-5">
                  <center>
                    <Carousel
                      showArrows={true}
                      onChange={onChange}
                      onClickItem={onClickItem}
                      onClickThumb={onClickThumb}
                    >
                      <div>
                        <img
                          alt="/"
                          src={require("assets/img/campus_plan/Admin_and_Academic_Block.jpg")}
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src={require("assets/img/campus_plan/Admin_and_Academic_Block.jpg")}
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src={require("assets/img/campus_plan/Admin_and_Academic_Block.jpg")}
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src={require("assets/img/campus_plan/Admin_and_Academic_Block.jpg")}
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src={require("assets/img/campus_plan/Admin_and_Academic_Block.jpg")}
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src={require("assets/img/campus_plan/Admin_and_Academic_Block.jpg")}
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src={require("assets/img/campus_plan/Admin_and_Academic_Block.jpg")}
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src={require("assets/img/campus_plan/Admin_and_Academic_Block.jpg")}
                        />
                      </div>
                      <div>
                        <img
                          alt="/"
                          src={require("assets/img/campus_plan/Admin_and_Academic_Block.jpg")}
                        />
                      </div>
                    </Carousel>
                  </center>
                </Col>
    </Row>/ */}
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Fests;
