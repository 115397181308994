import React from "react";
import { Card, CardBody, Container, Row, Table, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import "views/IndexSections/Sections.css";
import Helmet from "react-helmet";
class Medical extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Medical - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Medical Labs Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Medical, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Medical Facilities in IIIT Kota,IIIT Kota Medical Facilities,Dispensary in IIIT Kota, Physician IIIT Kota"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Medical Facilities</b>
                  </h2>
                  <h5>
                    (At MNIT Jaipur campus for 4ᵗʰ Year Students)
                  </h5>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col lg={12} xs={12}>
                  <h6>
                    A full-fledged Dispensary is available in the campus. It is
                    supervised by a residential Medical Officer and two
                    Compounders. The services of a part-time allopathic Doctor
                    and a Lady Doctor are also available to students and to the
                    family members of the staff.
                  </h6>
                </Col>
                <Col lg={12} xs={12} className="mt-5 mb-5">
                  <Table bordered>
                    <h6 className="only-padding">
                      <thead>
                        <tr>
                          <th style={{ width: "20%" }}>S.No</th>
                          <th style={{ width: "30%" }}>Doctors</th>
                          <th style={{ width: "50%" }}>Timings</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>1</th>
                          <td>Dispensary Timing</td>
                          <td>09.00 AM - 07.00 PM (Monday to Saturday) and Sunday 09:00AM - 11:00AM</td>
                        </tr>
                        <tr>
                          <th>2</th>
                          <td>Cardiologist</td>
                          <td>05.15 PM - 06.15 PM (Every Wednesday)</td>
                        </tr>
                        <tr>
                          <th>3</th>
                          <td>Allopathic Doctor </td>
                          <td>09.00 AM - 07.00 PM (Monday to Saturday) <br/>
                          01.00 PM - 03.00 PM
                          (Monday to Saturday- Medical officer not available)
                          <br/>
                          09.00 AM - 11.00 AM (Sunday)
                          <br/>
                          09.00 AM - 11.00 AM (Goverment Holiday) <br/>
                          Off on National Holidays
                          </td>
                        </tr>
                        <tr>
                          <th>4</th>
                          <td>Gynecologist </td>
                          <td>
                            04.30 PM - 06.30 PM (Monday to Friday) and 05.00 PM
                            - 06.00 PM (Saturday)
                          </td>
                        </tr>
                        <tr>
                          <th>5</th>
                          <td>Orthopedician </td>
                          <td>09.00 AM - 10.00 AM (Every Saturday)</td>
                        </tr>
                        <tr>
                          <th>6</th>
                          <td>Pediatrician </td>
                          <td>
                            09.00 AM - 1.00 PM (Monday to Friday) and 09.00 AM -
                            11.00 AM (Sunday)
                          </td>
                        </tr>
                        <tr>
                          <th>7</th>
                          <td>Ayurvedic Doctor </td>
                          <td>
                            09.00 AM - 11.00 AM (Monday to Saturday)
                          </td>
                        </tr>
                        <tr>
                          <th>8</th>
                          <td>Homoepathic Doctor </td>
                          <td>09:00 AM - 01:00 PM (Monday to Saturday)</td>
                        </tr>
                        <tr>
                          <th>9</th>
                          <td>Physiotherapy </td>
                          <td>
                            10.00 AM - 04.00 PM (Monday to Saturday) 
                          </td>
                        </tr>
                      </tbody>
                    </h6>
                  </Table>
                </Col>
              </Row>

              <Row className="justify-content-center text mb-5 ">
                <center>
                  <h4 className=" mb-5">
                    <b>Contact Information:</b>
                  </h4>
                </center>
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5 pl-4">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 text-primary">
                            <i className="fas fa-address-book" />
                          </div>

                          <h6 className="text-primary text-uppercase">
                            <b>Medical Officer</b>
                            <br />
                          </h6>

                          <div className="px-2 pb-2">
                            Dispensary
                            <br />
                            Malaviya National Institute of Technology JLN Marg,
                            Jaipur-302017
                          </div>

                          <h6>
                            <b>Phone no:</b>{" "}
                            <a href="tel:0141-2713305"> 0141-2713305</a>
                            <br /> <b>Ambulance Driver:</b>
                            <a href="tel:9549650438"> 9549650438</a>
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Medical;
