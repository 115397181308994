import React from "react";
import "assets/css/style.css";
import { Card, CardBody, Button, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
class AdministratingBody extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Administration - Indian Institute of Information Technology, Kota
          </title>
          <meta
            name="description"
            content="Director - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="Director iiit kota, Prof. A. P. S. Rathore, director mnit jaipur, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota Raj."
          />
          <meta name="author" content="Prof. A. P. S. Rathore" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Administration</b>
                  </h2>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid  justify-content-center">
                    <Col lg="10 ">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <Row>
                            <Col lg={4} xs={12}>
                              <img
                                alt="/"
                                // className="director-img"
                                src={require("assets/img/people/director.png")}
                                height="280px"
                              />
                            </Col>
                            <Col lg={8} xs={12}>
                              <div>
                                <h3>
                                  <b>	Prof. N. P. Padhy</b>
                                </h3>
                                <h5>Director, IIIT Kota</h5>
                              </div>
                              <div>
                                <h6>
                                  <i class="fas fa-phone-square-alt pr-2" />
                                  Phone : +91 141 2529087
                                </h6>
                              </div>
                              {/* <div className="mt-3">
                                <h6><i class="fas fa-fax pr-2"/>Fax : +91-141-2529029</h6>
                              </div> */}
                              <div className="mt-3">
                                <h6>
                                  <b>Director's Office : </b>
                                  <br />
                                  1st Floor, Prabha Bhawan, MNIT Campus, JLN
                                  Marg, Jaipur-302017
                                </h6>
                              </div>
                              <div className="mt-3">
                                <Button
                                  className="btn-icon btn-3 theme-color-kota-bg"
                                  type="button"
                                  href="mailto:director@mnit.ac.in"
                                >
                                  <span className="btn-inner--icon mr-1">
                                    <i className="fa fa-envelope" />
                                  </span>
                                  <span className="btn-inner--text">
                                    E-mail
                                  </span>
                                </Button>
                                <Button
                                  className="btn-icon btn-3 theme-color-kota-bg"
                                  type="button"
                                  href="http://www.mnit.ac.in/admin/director.php"
                                  target="_blank"
                                >
                                  <span className="btn-inner--icon mr-1">
                                    <i class="fas fa-address-card" />
                                  </span>
                                  <span className="btn-inner--text">
                                    Profile
                                  </span>
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="justify-content-center mt-5">
                <Col lg="12">
                  <Row className="row-grid  justify-content-center">
                    <Col lg="10 ">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <Row>
                            <Col lg={4} xs={12}>
                              <img
                                alt="/"
                                // className="director-img"
                                src={require("assets/img/people/administrating_body/coordinator.png")}
                                height="280px"
                              />
                            </Col>
                            <Col lg={8} xs={12}>
                              <div>
                                <h3>
                                  <b>Prof. K. K. Sharma</b>
                                </h3>
                                <h5>Coordinator, IIIT Kota</h5>
                              </div>
                              <div>
                                <h6>
                                  <i class="fas fa-phone-square-alt pr-2" />
                                  Phone : 01412713461
                                </h6>
                              </div>
                              {/* <div className="mt-3">
                                <h6><i class="fas fa-fax pr-2"/>Fax : +91-141-2529029</h6>
                              </div> */}
                              <div className="mt-3">
                                <h6>
                                  <b>Coordinator's Office : </b>
                                  <br />
                                  2nd Floor, Prabha Bhawan, MNIT Campus, JLN
                                  Marg, Jaipur-302017
                                </h6>
                              </div>
                              <div className="mt-3">
                                <Button
                                  className="btn-icon btn-3 theme-color-kota-bg"
                                  type="button"
                                  href="mailto:coordinator@iiitkota.ac.in"
                                >
                                  <span className="btn-inner--icon mr-1">
                                    <i className="fa fa-envelope" />
                                  </span>
                                  <span className="btn-inner--text">
                                    E-mail
                                  </span>
                                </Button>
                                <Button
                                  className="btn-icon btn-3 theme-color-kota-bg"
                                  type="button"
                                  href="https://mnit.ac.in/dept_ece/profile?fid=QKs="
                                  target="_blank"
                                >
                                  <span className="btn-inner--icon mr-1">
                                    <i class="fas fa-address-card" />
                                  </span>
                                  <span className="btn-inner--text">
                                    Profile
                                  </span>
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row className="justify-content-center mt-5">
                <Col lg="12">
                  <Row className="row-grid  justify-content-center">
                    <Col lg="10 ">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <Row>
                            <Col lg={4} xs={12}>
                              <img
                                alt="/"
                                // className="director-img"
                                src={require("assets/img/people/administrating_body/Dr.-Rohit-Bhakar.webp")}
                                height="280px"
                              />
                            </Col>
                            <Col lg={8} xs={12}>
                              <div>
                                <h3>
                                  <b>Prof. Rohit Bhakar</b>
                                </h3>
                                <h5>Registrar, IIIT Kota</h5>
                              </div>
                              <div>
                                <h6>
                                  <i class="fas fa-phone-square-alt pr-2" />
                                  Phone : +91 141-2529078
                                </h6>
                              </div>
                              {/* <div className="mt-3">
                                <h6>
                                  <i class="fas fa-fax pr-2" />
                                  Fax : +91-141-2529063{" "}
                                </h6> 
                              </div>*/}
                              <div className="mt-3">
                                <h6>
                                  <b>Registrar's Office : </b>
                                  <br />
                                  Prabha Bhawan, MNIT Campus, JLN Marg,
                                  Jaipur-302017
                                </h6>
                              </div>
                              <div className="mt-3">
                                <Button
                                  className="btn-icon btn-3 theme-color-kota-bg"
                                  type="button"
                                  href="mailto:registrar@iiitkota.ac.in"
                                >
                                  <span className="btn-inner--icon mr-1">
                                    <i className="fa fa-envelope" />
                                  </span>
                                  <span className="btn-inner--text">
                                    E-mail
                                  </span>
                                </Button>
                                <Button
                                  className="btn-icon btn-3 theme-color-kota-bg"
                                  type="button"
                                  href="https://mnit.ac.in/dept_ee/profile?fid=RK2L"
                                  target="_blank"
                                >
                                  <span className="btn-inner--icon mr-1">
                                    <i class="fas fa-address-card" />
                                  </span>
                                  <span className="btn-inner--text">
                                    Profile
                                  </span>
                                </Button>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default AdministratingBody;
