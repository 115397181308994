import React from "react";
import { Container, Row, Col } from "reactstrap";

import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
class RulesandDisciplines extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Rules and Discipline - Indian Institute of Information Technology
            Kota
          </title>
          <meta
            name="description"
            content="Rules and Discipline Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Rules and Discipline, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Rules and Discipline IIIT Kota,Rules and Regualtions IIIT Kota,Code of Conduct IIIT Kota,Anti Ragging Committee IIIT Kota"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Rules and Disciplines</b>
                  </h2>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="mb-5 text-justify">
                <Col lg={12} xs={12} className=" text-justify">
                  <h4>
                    <b>Student's Code of Conduct</b>
                  </h4>
                  <h6>
                    Student Code of Conduct sets out the standards of conduct
                    expected of students. It holds individuals and groups
                    responsible for consequences of their actions. The code of
                    Conduct reflects a concern for these values and tries to
                    ensure that members of IIIT Kota and the public can make use
                    of and enjoy the activities, facilities and benefits of the
                    University without undue interference from others.
                  </h6>
                </Col>
                <Col lg={12} xs={12} className="mt-3 ">
                  <h6>
                    <b>Prohibited Conduct under the Code includes:</b>
                    <ul className="mt-3">
                      <li>
                        Assaulting, harassing, intimidating, or threatening
                        another individual or group
                      </li>
                      <li>Endangering the health or safety of others</li>
                      <li>Disrupting University Activities</li>
                      <l1>
                        Stealing, misusing, destroying, defacing or damaging
                        University property or property belonging to someone
                        else
                      </l1>
                      <li>
                        Stealing, misusing, destroying, defacing or damaging
                        University property or property belonging to someone
                        else
                      </li>
                      <li>
                        Making false accusations against any member of the
                        University
                      </li>
                      <li>Using, processing or distributing illegal drugs</li>
                      <li>
                        Violating provincial liquor laws or College alcohol
                        policies
                      </li>
                      <li>Hazing</li>
                      <li>
                        Failing to comply with a disciplinary measure or
                        disciplinary measures imposed under the procedures of
                        this Code
                      </li>
                    </ul>
                  </h6>
                </Col>
              </Row>
              <Row className="mb-5 ">
                <Col lg={12} xs={12}>
                  <h4>
                    <b>Disciplinary Measures</b>
                  </h4>
                  <h6>
                    <b>
                      Disciplinary measures/actions that may be imposed under
                      the Code includes:
                    </b>
                    <ul className="mt-3">
                      <li>Written warning or reprimand</li>
                      <li>
                        Probation, during which certain conditions must be
                        fulfilled and good behaviour must be demonstrated
                      </li>
                      <li>
                        Payment of costs or compensation for any loss, damage or
                        injury caused by the conduct
                      </li>
                      <l1>
                        Issuance of an apology, made publicly or privately
                      </l1>
                      <li>Loss of certain privileges</li>
                      <li>Fines or loss of fees</li>
                      <li>Relocation or exclusion from residence</li>
                      <li>Suspension</li>
                      <li>Expulsion</li>
                    </ul>
                  </h6>
                </Col>
              </Row>
              <Row className="mb-5 text-justify">
                <Col lg={12} xs={12}>
                  <h4>
                    <b>Anti Ragging Committee</b>
                  </h4>
                  <h6>
                    Ragging is a crime which destroys the physical and emotional
                    confidence and it led to an increase in suicide
                    activities.According to the directions of the Supreme Court
                    if any instance of ragging is brought to the notice of the
                    administration or the faculty, it is legally binding on the
                    institute to report the matter to the Local Police.
                  </h6>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default RulesandDisciplines;
