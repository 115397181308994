import React from "react";
import { Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";

class ItInfrastructure extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            IT Infrastucture - Indian Institute of Information Technology Kota
          </title>
          <meta name="description" content="IT Infrastructure IIIT Kota" />
          <meta
            name="keywords"
            content="IT Infrastructure, IIIT Kota, Best IIITs, Top Campus In Jaipur, Infrastructure IT"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>IT Infrastucture</b>
                  </h2>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="mb-5 text-justify">
                <Col lg={12} xs={12}>
                  <h6>
                    MNIT data centre is housing MNIT Private Cloud using VMware
                    V Cloud Suite installed on Blade Server and core network
                    switches with 2 Gbps lease line for internet connectivity.
                    MNIT Private Cloud is an IaaS service, which allows
                    administrator to create Virtual data centres with virtual
                    servers, machines and networks, expanding or reducing as
                    their needs require, with this Private Cloud, MNIT users get
                    both computational resources, and network License resources
                    for their own exclusive use. MNIT private cloud running
                    various applications/services used by student, faculty and
                    Staff and hundreds of Virtual Machines (VMs) have been
                    allotted to various researchers and faculty members.
                  </h6>
                </Col>
                <Col lg={12} xs={12} className="mt-3">
                  <h6>
                    <b>Computing Resources of Data Centre: </b>CISCO UCS:- Two
                    UCS 5108 Chassis and 12 CISCO B200 M4 Servers with following
                    Configuration
                    <ul className="mt-3">
                      <li>2 x 18 Core Intel® Xeon® Processor (E5-2699 v3)</li>
                      <li>Intel® C610 chipset</li>
                      <li>4 x10Gb Ethernet NIC ports</li>
                      <li>2 X CISCO UCS 6248UP Fabric Interconnect</li>
                    </ul>
                    EMC2 Storages and Backup Devices:
                    <ul className="mt-3">
                      <li> ✔ VNX-5200 SAN used for MNIT Private Cloud:20 TB</li>
                      <li> ✔ VNX-5300 SAN used for MNIT Private Cloud:10 TB</li>
                      <li>
                        {" "}
                        ✔ ISILON S200 NAS for data storages by the Deptts:22 TB
                      </li>
                      <li> ✔ AVAMAR M1200 Gen4S backup Appliance:3.9 TB</li>
                    </ul>
                    HP Storages Devices:-
                    <ul>
                      <li>
                        {" "}
                        ✔ HP Storage Works X1000 G2 (6TB) used for Auto CAD
                        Licensing Sever & Auto CAD Data Storage.
                      </li>
                      <li>
                        {" "}
                        ✔ 10 TB SAN Storage:- HP MSA P2000 Modular Smart Array
                        Systems with RAID 5, and Smart Array P410i Controller.
                      </li>
                    </ul>
                  </h6>
                </Col>
                <Col lg={12} xs={12} className="mt-3">
                  <h6>
                    <b>Highlights of Data Centre: </b>
                    <ul className="mt-3">
                      <li>Centralized provisioning of IT resources </li>
                      <li>Work load elasticity</li>
                      <li>
                        Single interface for administrator for entire IT
                        infrastructure
                      </li>
                      <li>Provide Data Storage to departments</li>
                      <li>
                        Provide High End Computational Resources for Research
                        purpose
                      </li>
                      <li>
                        Various Hosting and other Services like :
                        <ul
                          className="mt-2"
                          style={{ listStyleType: "square" }}
                        >
                          <li>MNIT Website [mnit.ac.in]</li>
                          <li>IIIT KOTA Website [iiitkota.ac.in]</li>
                          <li>DNS Server for creating Sub domains </li>
                          <li>LDAP Authentication Server for 6000 users </li>
                          <li>Library Management software KOHA and RFID </li>
                          <li>
                            Prowess Server (Database of the financial
                            performance of Indian companies)
                          </li>
                          <li>
                            More than hundred Virtual Machines used for IT
                            Services and allotted to Researchers and faculty
                            members.
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </h6>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default ItInfrastructure;
