import React from "react";
import { Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
class Achievements extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Achievements - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Achievements - Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Achievements, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2 className="display-3">Achievements</h2>
                </Col>
              </Row>
              {/* <Row className="justify-content-center mb-5">
                <Col lg={12}>
                  <h2 className="display-4">GATE 2020</h2>
                </Col>

                <Col lg={6} className="mt-2">
                  <h6>
                    <ol>
                      <li>
                        Piyush Kanda Student from 2020 Batch Secured AIR-33 in
                        Gate 2020
                      </li>
                      <li>Chess (Boys Team)</li>
                    </ol>
                  </h6>
                </Col>
                <Col lg={6} className="mt-3">
                  <h5>
                    <b>
                      <i class="fas fa-medal px-2"></i>Bronze medal
                    </b>
                  </h5>
                  <h6>
                    <ol>
                      <li>Chess (Girls Team)</li>
                      <li>Chess (Boys Team)</li>
                    </ol>
                  </h6>
                </Col>
              </Row> */}
              <Row className="justify-content-center mb-5">
                <Col lg={12}>
                  {/* vswhrv */}
                  <h2 className="display-4">Research grant on SERB sponsored project on 60Ghz channel characterization.</h2>
                  <img
                    src="https://files.iiitkota.ac.in/images/achievements/Dr.%20Anand%20Agrawal%20Research%20Grant.jpg"
                    width="400px"
                    alt=""
                  />
                </Col>
              </Row>

              <Row className="justify-content-center mb-5">
                <Col lg={12}>
                  {/* vswhrv */}
                  <h2 className="display-4">Research grant on BEL-CRL sponsored project on robotics arm.</h2>
                  <img
                    src="https://files.iiitkota.ac.in/images/achievements/Research%20Grant"
                    width="400px"
                    alt=""
                  />
                </Col>
              </Row>
              
              <Row className="justify-content-center mb-5">
                <Col lg={12}>
                  {/* vswhrv */}
                  <h2 className="display-4">Quizathon 2021 Winners</h2>
                  <img
                    src="https://files.iiitkota.ac.in/images/achievements/achievement_07.png"
                    width="400px"
                    alt=""
                  />
                </Col>
              </Row>

              <Row className="justify-content-center mb-5">
                <Col lg={12}>
                  <h2 className="display-4">
                    Grabbed the offer for "SWE Internship at Google".
                  </h2>

                  <h6>
                    Prefinal year student Omkar Deshmukh (2019KUCP1009) cracked
                    the offer from Google India for the two months Software
                    Engineering Internship for Summer 2022.
                  </h6>
                </Col>
              </Row>

              <Row className="justify-content-center mb-5">
                <Col lg={12}>
                  <h2 className="display-4">Fossasia Codeheat Winner</h2>
                  <img
                    src="https://files.iiitkota.ac.in/images/achievements/codeheat.png"
                    width="400px"
                    alt="Fossasia Codeheat Winner"
                  />

                  <h6>
                    {" "}
                    <br />
                    Pavnesh Chaturvedi - Grand Prize Winner <br />
                    Context: Fossasia is the largest open-source organization in
                    Asia. Prize awarded for the most quality contributions on
                    eventyay and PSLab project.
                    <br />
                    Websites:{" "}
                    <a
                      href="https://fossasia.org"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      fossasia.org
                    </a>
                    ,{" "}
                    <a
                      href="https://codeheat.org"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      codeheat.org
                    </a>
                  </h6>
                </Col>
              </Row>
              <Row className="justify-content-center mb-5">
                <Col lg={12}>
                  <h2 className="display-4">AIR - 46 in the GATE 2021</h2>
                  <h6>
                    Yashash Jain (ECE) has achieved AIR - 46 in the GATE 2021
                  </h6>
                </Col>
              </Row>
              <Row className="justify-content-center mb-5">
                <Col lg={12}>
                  <h2 className="display-4">AIR - 33 in the GATE 2020</h2>
                  <h6>
                    Piyush Kanda (CSE) has achieved AIR - 33 in the GATE 2020
                  </h6>
                </Col>
              </Row>
              <Row className="justify-content-center mb-5">
                <Col lg={12}>
                  <h2 className="display-4">
                    IIIT Kota at MST (MNIT Sports Tournament)
                  </h2>
                </Col>

                <Col lg={6} className="mt-2">
                  <h5>
                    <b>
                      <i class="fas fa-medal px-2"></i>Silver medal
                    </b>
                  </h5>

                  <h6>
                    <ol>
                      <li>Chess (Girls Team)</li>
                      <li>Chess (Boys Team)</li>
                    </ol>
                  </h6>
                </Col>
                <Col lg={6} className="mt-3">
                  <h5>
                    <b>
                      <i class="fas fa-medal px-2"></i>Bronze medal
                    </b>
                  </h5>
                  <h6>
                    <ol>
                      <li>Chess (Girls Team)</li>
                      <li>Chess (Boys Team)</li>
                    </ol>
                  </h6>
                </Col>
              </Row>
              <Row className="justify-content-center mb-5">
                <Col lg={12}>
                  <h2 className="display-4">IIIT Kota at Google DSC</h2>
                </Col>

                <Col lg={12} className="mt-2">
                  <h6>
                    <ol>
                      <li>Sahil Ayank (DSC Lead)</li>
                      <li>Gaurav Khandelwal (DSC Member)</li>
                      <li>Vipasha Chandwani (DSC Member)</li>
                      <li>Tanuj Mehta (DSC Member)</li>
                      <li>Shashwat Singh (DSC Member)</li>
                    </ol>
                  </h6>
                </Col>
              </Row>
              <Row className="justify-content-center mb-5">
                <Col lg={12}>
                  <h2 className="display-4">
                    IIIT Kota at Gusto-2017, IIITDM Jabalpur
                  </h2>
                </Col>

                <Col lg={6} className="mt-2">
                  <h5>
                    <b>
                      <i class="fas fa-medal px-2"></i>Silver medal
                    </b>
                  </h5>

                  <h6>
                    <ol>
                      <li>100 m race (Navneet Poonia)</li>
                      <li>200 m race (Navneet Poonia)</li>
                      <li>400 m race (Rahul Tyagi)</li>
                      <li>800 m race (Mohit Choudhary)</li>
                      <li>Carrom (Anjali Garg)</li>
                    </ol>
                  </h6>
                </Col>
                <Col lg={6} className="mt-3">
                  <h5>
                    <b>
                      <i class="fas fa-medal px-2"></i>Bronze medal
                    </b>
                  </h5>
                  <h6>
                    <ol>
                      <li>100 m race (Deepak Yadav)</li>
                      <li>800 m race (Jaipal Singh)</li>
                    </ol>
                  </h6>
                </Col>
              </Row>
              <Row className="justify-content-center mb-5">
                <Col lg={12}>
                  <h2 className="display-4">
                    IIIT Kota stands 3rd overall at Inter IIIT Sports Meet –
                    2016
                  </h2>
                </Col>

                <Col lg={4} className="mt-2">
                  <h5>
                    <b>
                      <i class="fas fa-medal px-2"></i>Gold medal
                    </b>
                  </h5>
                  <h6>
                    <ol>
                      <li>Long jump (Navneet Poonia)</li>
                    </ol>
                  </h6>
                </Col>
                <Col lg={4} className="mt-2">
                  <h5>
                    <b>
                      <i class="fas fa-medal px-2"></i>Silver medal
                    </b>
                  </h5>
                  <h6>
                    <ol>
                      <li>4*400 (boys team)</li>
                      <li>Shotput (Srikant Deshmane)</li>
                      <li>Chess (Nidhi Mantri)</li>
                    </ol>
                  </h6>
                </Col>
                <Col lg={4} className="mt-2">
                  <h5>
                    <b>
                      <i class="fas fa-medal px-2"></i>Bronze medal
                    </b>
                  </h5>
                  <h6>
                    <ol>
                      <li>Cricket (boys team)</li>
                      <li>Long jump (Parth Kumar)</li>
                      <li>Badminton doubles (girls team)</li>
                    </ol>
                  </h6>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Achievements;
