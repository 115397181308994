import React from "react";
import {  Row, Col, Table } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import "assets/css/style.css";
import Helmet from "react-helmet";

class GoverningBody extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Governing Body - Indian Institute of Information Technology, Kota
          </title>
          <meta
            name="description"
            content="Governing Body - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="Governing Body, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0 0" />
                </svg>
              </div>

            </section>
          </div>
          <section className="section section-lg">
            
            <Row>
              <Col xs={2}></Col>

              <Col xs={20}>

              
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  <h2>
                    <b>Board of Governors</b>
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col lg={35} xs={35} className="mt-5 mb-5">
                  <Table bordered>
                    <h6 className="only-padding">
                      <thead>
                        <tr>
                          
                          <th style={{ width: "560px" , textAlign:"center"}}>Provisions as per Section14 (2) of the IIIT (PPP) Act, 2017</th>
                          <th style={{ width: "580px" , textAlign:"center"}}>Name</th>
                          <th style={{ width: "180px" , textAlign:"center" }}>Designation</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{textAlign:"justify"}}>
                          (a) Chairperson, an eminent technologist or industrialist or
                          <br/>
                          educationist to be nominated by the Visitor on the recommendation of
                          <br/>
                          the Central Government
                          <br/>

                          </td>
                          <td>
                            <b>Lt. Gen. AK Bhatt (retd.)</b>
                            <br />
                            Former Military Secretary, 
                            <br />
                            PVSM UYSM AVSM SM VSM (Indian Army),
                            <br />
                            Former DGMO, MS & GOC 15 Corps,
                            <br />
                            Director General, Indian Space Association.
                          </td>
                          <td className="text_center"><b>Chairperson</b></td>
                        </tr>
                        <tr>
                         
                          <td>(i) Director of the Institute ex officio</td>
                          <td>
                            <b>Prof. N. P. Padhy</b>
                            <br />
                            Mentor Director, IIIT Kota
                          </td>
                          <td className="text_center">Member</td>
                        </tr>
                                             
                        
                        <tr>
                          
                          <td rowSpan="2">(b) One nominee each of the Central Government and the concerned
                          <br/>
                          State Government</td>
                          <td>
                            <b>Central Govt. nominee-</b>Bureau Head dealing with IIITs i.e.
                            
                            <br/>
                            Additional/Joint Secretary (Technical Educational) Deptt. Of The
                            Ministry of Education
                          </td>
                          <td className="text_center">Member</td>
                        </tr>
                        <tr>
                          <td><b>To be nominated by the Government of State</b></td>
                          <td className="text_center">Member</td>
                        </tr>
                        <tr>
                          <td rowSpan="3">(c) Three eminent persons, of which at least one shall be a woman,
                            <br/>
                            appointed by the Board out of industry, research laboratories, and
                            <br/>
                            civil society, one from each category.</td>
                          <td>
                            <b>Prof. Tripta Thakur</b>
                            <br />
                            Director General, NPTI, Faridabad
                          </td>
                          <td className="text_center">Member</td>
                        </tr>
                        <tr>
                          <td>
                                <b>Dr. JBV Reddy</b>
                                <br/>
                                Scientist-F, Dept. of Science & Technology, Govt. of India
                          </td>
                          <td className="text_center">Member</td>
                        </tr>
                        <tr>
                          <td>
                                <b>Shri Achyuta Samanta</b>
                                <br/>
                                Founder KIIT Group of Institutions
                          </td>
                          <td className="text_center">Member</td>
                        </tr>


                        <tr>
                          <td rowSpan="2">

                          (d) Two eminent academicians appointed by the Board
                          </td>
                          <td>
                            <b>Prof. M. V. Kartikeyan</b>
                            <br />
                            Director, IIITDM Kancheepuram
                          </td>
                          <td className="text_center">Member</td>
                        </tr>

                        <tr>
                          <td>
                            <b>Prof. B. K. Mishra</b>
                            <br/>
                            Director, IIT Goa                            
                          </td>
                          <td className="text_center">Member</td>
                        </tr>

                      

                        <tr>
                          <td rowSpan="2">

                          (e) One nominee representing each of the industry partners,
                          <br/>
                          Provided that if there are more than two industry partners then the
                          <br/>
                          number of representatives of industry partners at any time would be
                          <br/>
                          limited to two, chosen amongst themselves by rotation every two
                          <br/>
                          years.

                          </td>
                          <td>
                            <b>Mr. Prachur Sah</b>
                            <br />
                            Dy. CEO Cairn Oil & Gas.
                          </td>
                          <td className="text_center">Member</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Mr. Rohit Saboo</b>
                            <br/>
                            President and CEO, National Engineering Industries Ltd.
                          </td>
                          <td className="text_center">Member</td>
                        </tr>

                        <tr>
                          <td>
                          (f) Director of an Indian Institute of Technology or a National Institute
                          <br/>
                          of Technology located in the region, to be nominated by the Board.
                          </td>
                          <td>
                            <b>Prof. N. P. Padhy</b>
                            <br />
                            Director, MNIT Jaipur
                          </td>
                          <td className="text_center">Member</td>
                        </tr>

                        <tr>
                          <td rowSpan="2">

                          (g) Two members from the faculty of the institute preferably one
                          <br/>
                          Professor and one Associate/Assistant Professor to be nominated by
                          <br/>
                          the senate.
                          </td>

                          <td>

                            <b>Dr. Vinita Tiwari</b>
                            <br/>
                            Associate Dean (Academics), IIIT Kota
                          </td>
                          <td className="text_center">Member</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Dr. Amit Kumar</b>
                            <br/>
                            Head Computer Science and Engineering, IIIT Kota
                          </td>
                          <td className="text_center">Member</td>
                        </tr>

                        <tr>
                          <td>
                          (h) One person from the Scheduled Castes and Scheduled Tribes
                          <br/>
                          distinguished in the field of education or industry or social service or
                          <br/>
                          public service, to be nominated by the Board, in case anyone of the
                          <br/>
                          above do not belong to the Scheduled Castes or Scheduled Tribes.
                          </td>

                          <td>
                            <b>Prof. Binod Kumar Kanaujia</b>
                            <br/>
                            Director, NIT Jalandhar
                          </td>

                          <td className="text_center">Member</td>
                        </tr>


                        <tr>
                          <td>
                          Registrar of the Institute ex officio (Provisions as per Section 14(3) of
                          <br/>
                          the IIIT(PPP) Act, 2017)
                          </td>

                          <td>
                            <b>Prof. Rohit Bhakar</b>
                            <br/>
                            Registrar(I/c), IIIT Kota
                          </td>

                          <td className="text_center">Non-Member
                            <br/>
                          Secretary</td>
                        </tr>


                      </tbody>
                    </h6>
                  </Table>
                </Col>
              </Row>

              <br/>

              

              <Row>
                <Col lg={12} xs={12} className="mt-5 mb-5">
                  <Table borderless>
                    <h6>
                      <thead>
                        <tr className="mb-5">
                          <th></th>
                          <th style={{ width: "808px" }}>
                            Office Order
                          </th>
                          <th style={{ width: "202px" }}>Download</th>

                        </tr>
                      </thead>
                      <tbody>
                              
                      <tr className="body_tr">
                          <td><ul><li></li></ul></td>
                          <td>
                            
                          BoG Constitution IIIT Kota
                          </td>  
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/BOG/office_orders/Office Order_BoG Constitution IIIT Kota.pdf"
                              target="_blank" rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>

                     
                      </tbody>
                    </h6>
                  </Table>
                </Col>
              </Row>

              <br/>
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  <h4>
                    <b>Minutes of Meeting (MoM) of Board of Governors</b>
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col lg={12} xs={12} className="mt-5 mb-5">
                  <Table borderless>
                    <h6>
                      <thead>
                        <tr className="mb-5">
                          <th></th>
                          <th style={{ width: "808px" }}>
                            Minutes of Meeting (MoM)
                          </th>
                          <th style={{ width: "202px" }}>Download</th>

                        </tr>
                      </thead>
                      <tbody>
                              
                      <tr className="body_tr">
                          <td><ul><li></li></ul></td>
                          <td>
                            
                            To download the MoM of 2st Board of Governors Meeting
                          </td>  
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/BOG/MoM of 2nd BoG IIIT Kota.pdf"
                              target="_blank" rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>

                      <tr className="body_tr">
                          <td><ul><li></li></ul></td>
                          <td>
                            
                            To download the MoM of 1st Board of Governors Meeting
                          </td>  
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/BOG/MoM%20of%201st%20BoG.pdf"
                              target="_blank" rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                      
                     
                      <tr className="body_tr">
                          <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 11th Governing Body Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/MoM%20of%2011th%20GB.pdf"
                              target="_blank" rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>

                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 10th Governing Body Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/MoM%20of%2010th%20GB.pdf"
                              target="_blank" rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>

                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 9th Governing Body Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/MoM%209GB.pdf"
                              target="_blank" rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>


                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 8th Governing Body Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/8GB_MoM.pdf"
                              target="_blank" rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>

                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 7th Governing Body Meeting
                          </td>
                          <td>
                            <a
                              href="https://drive.google.com/file/d/1Gie0Oaxjmq-dpfe6mYHjVUeuDqSEsOU5/view?usp=sharing"
                              target="_blank" rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>

                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 6th Governing Body Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/governingbody/MoM%20of%206th%20GB.pdf"
                              target="_blank" rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>

                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 5th Governing Body Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/governingbody/MoM%20of%205th%20GB.pdf"
                              target="_blank" rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>

                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 4th Governing Body Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/governingbody/MoM%20of%204th%20GB.pdf"
                              target="_blank" rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>

                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 3rd Governing Body Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/governingbody/MoM%20of%203rd%20GB.pdf"
                              target="_blank" rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>

                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 2nd Governing Body Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/governingbody/MoM%20of%202nd%20GB.pdf"
                              target="_blank" rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>

                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>
                            To download the MoM of 1st Governing Body Meeting
                          </td>
                          <td>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/governingbody/MoM%20of%201st%20GB.pdf"
                              target="_blank" rel="noopener noreferrer"
                            >
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </h6>
                  </Table>
                </Col>
              </Row>
              </Col>
              <Col xs={2}></Col>
            </Row>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default GoverningBody;
