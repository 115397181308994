import React from "react";
import { Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import EventItem from "./EventItem";
import Helmet from "react-helmet";
import axios from "axios";

class Events extends React.Component {
  state = {
    event: [],
  };
  async componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;

    const eventAll = await axios.get("https://admin-panel-new.onrender.com/events");
    const sortedEvents = eventAll.data.reverse();
    this.setState({ event: sortedEvents });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>
            Events - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Events Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Events, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Fests in IIIT Kota,IIIT Kota Fests,Flair Festa,Cultural Fest IIIT Kota,Technical Fest IIIT Kota,MNIT Fest,MNIT Jaipur Fest,Sphinx,blitzschlag,Sport event iiit kota,matlab workshop iiit kota"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Events</b>
                  </h2>
                </Col>
              </Row>
              {this.state.event
                ? this.state.event.map((ele) => (
                    <EventItem
                      title={ele.title}
                      description={ele.description}
                      imageurl={ele.imageurl}
                    />
                  ))
                : null}
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Events;
