import React from "react";
import { Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
// import Table from 'react-bootstrap/Table';

// import AideNews from "views/HeaderSection/Academics/Departments/departments_news/Aide_news";
//import Ece_faculties from "views/HeaderSection/Academics/Departments/departments_faculties/Ece_faculties";
//import Ece_journals from "views/HeaderSection/Academics/Departments/departments_publications/Ece_journals";
//import Ece_conferences from "views/HeaderSection/Academics/Departments/departments_publications/Ece_conferences";
//import Ece_book_chapters from "views/HeaderSection/Academics/Departments/departments_publications/Ece_book_chapters";
//import Ece_patents from "views/HeaderSection/Academics/Departments/departments_publications/Ece_patents";
//import Ece_projects from "views/HeaderSection/Academics/Departments/departments_projects/Ece_projects";

class Aide extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Department - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Department Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Department, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Deparment in IIIT Kota, CSE Deparment,ECE Department , Electronics and Communication Engineering Department,Computer Science and Engineering Department,IIIT Kota Department,CSE Programme Advisors,ECE Programme Advisors"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg ">

           
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Department of Artificial Intelligence and Data Engineering</b>
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col lg={1} xs={1}></Col>
              <Col lg={10} xs={10}>
              <Tabs
                defaultActiveKey="home"
                id="fill-tab-example"
                className="mb-3"
                fill
              >
                <Tab eventKey="home" title="Home">
                  
                  <Row>
                    <Col className="mt-5">
                    <h5><b>About the Deparment</b></h5>
                    <h6 className="pb-3" style={{textAlign:"justify"}}>
                  The department of Artificial Intelligence and Data Engineering started
                   functioning in 2024-25. Currently, the department has a total intake 
                   of 60 seats and offers a four-year B.Tech programme. The Artificial 
                   Intelligence and Data Engineering department is poised to revolutionize
                    education by offering courses that delve into cutting-edge technologies,
                     equipping students with the skills necessary to thrive in the industry.
                      These courses are meticulously designed to provide hands-on experience 
                      and theoretical knowledge, preparing students to tackle real-world challenges
                       with confidence. By bridging the gap between academia and industry, the department
                        ensures that graduates emerge not only well-versed in the latest advancements
                         but also fully prepared to make meaningful contributions from day one of their
                          careers. The department is in the process of getting state-of-the-art labs 
                          to support the undergraduate program better.
                  </h6>
                    </Col>
                  </Row>

                  {/*<Row>*/}
                  {/*<Col className="mt-5">*/}
                  {/*  /!*<AideNews/>*!/*/}
                  {/*</Col>*/}
                  {/*</Row>*/}

                  <Row>
                  <Col className="mt-5">
                   
                  <h5>
                    <b>Offered Programs</b>
                  </h5>
                  <h6>
                    <ul>
                      <li>
                      Four-year B.Tech in Artificial Intelligence and Data Engineering (AI&DE)
                      </li>
                    </ul>
                  </h6>

                  <h5>
                    <b>Head of the Department (HoD)</b>
                  </h5>
                  <h6>
                    <ul>
                      <li>
                        <a href="/faculty/608540887fb8f4001564e1b2">Dr. Isha Pathak Tripathi</a>
                      </li>
                    </ul>
                  </h6>
                  
                  </Col>
                  </Row>

                </Tab>
                {/*<Tab eventKey="faculty" title="Faculty">*/}
                {/* /!*<Ece_faculties/>*!/*/}
                {/*</Tab>*/}
                {/*<Tab eventKey="time_table" title="Time-Table">*/}
                  
                {/*<Tabs*/}
                {/*  defaultActiveKey="1_sem"*/}
                {/*  id="fill-tab-example"*/}
                {/*  className="mb-3 mt-5"*/}
                {/*  fill*/}
                {/*>*/}
                {/*  <Tab eventKey="1_sem" title="1-Sem">*/}
                {/*    */}
                {/*  <Table responsive className="mt-5">*/}
                {/*    <thead>*/}
                {/*      <tr>*/}
                {/*        <th>DAY</th>*/}
                {/*        */}
                {/*        <th>8:00 - 9:00 A.M.</th>*/}
                {/*        <th>9:00 - 10:00 A.M.</th>*/}
                {/*        <th>10:00 - 11:00 A.M.</th>*/}
                {/*        <th>11:00 - 12:00 A.M.</th>*/}
                {/*        <th>12:00 - 1:00 P.M.</th>*/}
                {/*        <th>1:00 - 2:00 P.M.</th>*/}
                {/*        <th>2:00 - 3:00 P.M.</th>*/}
                {/*        <th>3:00 - 4:00 P.M.</th>*/}
                {/*        <th>4:00 - 5:00 P.M.</th>*/}
                {/*        <th>5:00 - 6:00 P.M.</th>*/}
                {/*      </tr>*/}
                {/*    </thead>*/}
                {/*    <tbody>*/}
                {/*      <tr>*/}
                {/*        <td>Monday</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        <td>CST(F5),401</td>*/}
                {/*        <td>CST(F2),401</td>*/}
                {/*        <td>CST(F3),401</td>*/}
                {/*        <td>CST(F11),401</td>*/}
                {/*        <td>Lunch</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        */}
                {/*       */}
                {/*      </tr>*/}

                {/*      <tr>*/}
                {/*        <td>Tuesday</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        <td>CST(F5),401</td>*/}
                {/*        <td>CST(F2),401</td>*/}
                {/*        <td>CST(F3),401</td>*/}
                {/*        <td>CST(F11),401</td>*/}
                {/*        <td>Lunch</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        */}
                {/*       */}
                {/*      </tr>*/}

                {/*      <tr>*/}
                {/*        <td>Wednesday</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        <td>CST(F5),401</td>*/}
                {/*        <td>CST(F2),401</td>*/}
                {/*        <td>CST(F3),401</td>*/}
                {/*        <td>CST(F11),401</td>*/}
                {/*        <td>Lunch</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        */}
                {/*       */}
                {/*      </tr>*/}

                {/*      <tr>*/}
                {/*        <td>Thursday</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        <td>CST(F5),401</td>*/}
                {/*        <td>CST(F2),401</td>*/}
                {/*        <td>CST(F3),401</td>*/}
                {/*        <td>CST(F11),401</td>*/}
                {/*        <td>Lunch</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        */}
                {/*       */}
                {/*      </tr>*/}
                {/*      */}
                {/*      <tr>*/}
                {/*        <td>Friday</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        <td>CST(F5),401</td>*/}
                {/*        <td>CST(F2),401</td>*/}
                {/*        <td>CST(F3),401</td>*/}
                {/*        <td>CST(F11),401</td>*/}
                {/*        <td>Lunch</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        <td>CST(F1),401</td>*/}
                {/*        */}
                {/*       */}
                {/*      </tr>*/}

                {/*    </tbody>*/}
                {/*  </Table>*/}
                {/*  <Row>*/}
                {/*    */}
                {/*    <Col xs={4}>*/}
                {/*    */}
                {/*    <Table responsive className="mt-5">*/}
                {/*    <thead>*/}
                {/*      <tr>*/}
                {/*        <th>Faculty Code</th>*/}
                {/*       <th>Faculty Name</th>*/}
                {/*      </tr>*/}
                {/*    </thead>*/}
                {/*    <tbody>*/}
                {/*      <tr>*/}
                {/*        <td>F1</td>*/}
                {/*        <td>Faculty One</td>*/}
                {/*      </tr>*/}
                {/*      <tr>*/}
                {/*      <td>F2</td>*/}
                {/*      <td>Faculty Two</td>*/}
                {/*        */}
                {/*      </tr>*/}
                {/*      <tr>*/}
                {/*      <td>F3</td>*/}
                {/*      <td>Faculty Three</td>*/}
                {/*       */}
                {/*      </tr>*/}
                {/*      <tr>*/}
                {/*      <td>F4</td>*/}
                {/*      <td>Faculty Four</td>*/}
                {/*       */}
                {/*      </tr>*/}
                {/*    </tbody>*/}
                {/*  </Table>*/}
                {/*  */}
                {/*    </Col>*/}
                {/*    <Col xs={7}></Col>*/}
                {/*  </Row>*/}
                {/* */}

                {/*  </Tab>*/}
                {/*  <Tab eventKey="3_sem" title="3-Sem">*/}
                {/*    Tab content for Profile*/}
                {/*  </Tab>*/}
                {/*  <Tab eventKey="5_sem" title="5-Sem">*/}
                {/*    Tab content for Loooonger Tab*/}
                {/*  </Tab>*/}
                {/*  <Tab eventKey="7_sem" title="7-Sem">*/}
                {/*    Tab content for Loooonger Tab*/}
                {/*  </Tab>*/}
                {/*</Tabs>*/}


                {/*</Tab>*/}
                  <Tab eventKey="syllabus" title="Syllabus">
                      <div className="mt-5" style={{
                          position: "relative",
                          height: "0",
                          overflow: "hidden",
                          maxWidth: "100%",
                          paddingBottom: "56.25%"
                      }}>
                          <iframe
                              title="AI&DE Syllabus"
                              src="https://docs.google.com/viewer?url=https://files.iiitkota.ac.in/pdf/curriculum/B.Tech.%20(Artificial%20Intelligence%20and%20Data%20Engineering)_1.pdf&embedded=true"
                              style={{position: "absolute", top: "0", left: "0", width: "100%", height: "100%"}}
                          ></iframe>
                      </div>


                  </Tab>
                  {/*<Tab eventKey="publications" title="Publications">*/}
                  {/*  <Row>*/}
                  {/*    <Col lg="1"></Col>*/}
                  {/*    <Col className="mt-5">*/}

                  {/*        <Tabs*/}
                  {/*        defaultActiveKey="journals"*/}
                  {/*        id="fill-tab-example"*/}
                  {/*        className="mb-3"*/}
                {/*        fill*/}
                {/*      >*/}
                {/*        <Tab eventKey="journals" title="Journals">*/}
                {/*          /!*<Ece_journals/>*!/*/}
                {/*        </Tab>*/}
                {/*        <Tab eventKey="conferences" title="Conferences">*/}
                {/*         /!*<Ece_conferences/>*!/*/}
                {/*        </Tab>*/}
                {/*        <Tab eventKey="book_chapters" title="Book/Chapters">*/}
                {/*         /!*<Ece_book_chapters/>*!/*/}
                {/*        </Tab>*/}
                {/*        <Tab eventKey="patents" title="Patents">*/}
                {/*         /!*<Ece_patents/>*!/*/}
                {/*        </Tab>*/}
                {/*       */}
                {/*      </Tabs>*/}


                {/*    </Col>*/}
                {/*    <Col lg="1"></Col>*/}
                {/*  </Row>*/}
                {/*</Tab>*/}
                {/*<Tab eventKey="projects" title="Projects">*/}
                {/*  /!*<Ece_projects/>*!/*/}
                {/*</Tab>*/}
                {/*<Tab eventKey="awards_and_honours" title="Awards & Honours">*/}
                {/*  Tab content for Contact*/}
                {/*</Tab>*/}
                <Tab eventKey="placements" title="Placements">
                  <Container className="mt-5">

                    <Card className="card-body shadow">
                    
                    <Card.Body>
                      <h6><a href="https://tpcell.iiitkota.ac.in/">Click here for Placement Statistics</a></h6>
                    </Card.Body>
                    </Card>

                  </Container>
                </Tab>
                {/*<Tab eventKey="event_organised" title="Event Organised">*/}
                {/*  Tab content for Contact*/}
                {/*</Tab>*/}
                <Tab eventKey="contact_us" title="Contact Us">
                 
                 <Row className="mt-5">
                  <Col lg={3}></Col>
                  <Col lg={6}>
                  
                  <Card className="card-body shadow">
                    
                    <Card.Body>
                     <h6><b>Dr. Isha Pathak Tripathi</b></h6>
                     <h6>Head, Department of Artificial Intelligence and Data Engineering</h6>
                      <h6>IIIT KOTA</h6>
                      <h6>IIIT Kota Permanent Campus, Kota, 325003</h6>
                      <h6>
                        <Row className="ml-1">
                          <Col xs={0.5}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z"/>
                        </svg>
                          </Col>
                          <Col xs={10} className="ml-0 mr-0" style={{textAlign:"left"}}>0141-2715071</Col>
                        </Row>
                      </h6>

                      <h6>
                        <Row className="ml-1">
                          <Col xs={0.5}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-envelope-fill" viewBox="0 0 16 16">
                          <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414zM0 4.697v7.104l5.803-3.558zM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586zm3.436-.586L16 11.801V4.697z"/>
                        </svg>
                          </Col>
                          <Col xs={10} className="ml-0 mr-0" style={{textAlign:"left"}}>isha@iiitkota.ac.in</Col>
                        </Row>
                      </h6>

                    </Card.Body>
                    </Card>


                  </Col>
                  <Col lg={2}></Col>
                 </Row>
                 
            
                  
                
                </Tab>
              </Tabs>
              </Col>
              <Col lg={1} xs={1}></Col>
              
              </Row>
              
          
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Aide;