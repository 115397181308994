import React from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import { Table } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import "views/IndexSections/Sections.css";
import Helmet from "react-helmet";
class Library extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Library - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Library Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Library, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Library of IIIT Kota, IIIT kota Library,Central Library IIIT Kota,MNIT Jaipur Library,library@iiitkota.ac.in"
          />
          <meta name="author" content="Mr. Satya Narayan Sharma" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-3">
                <Col lg="8">
                  <h2>
                    <b>Library</b>
                  </h2>
                  <br />
                  <p>
                    <a
                      href="http://library.iiitkota.ac.in"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <b>OPAC Library IIIT Kota</b>
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://files.iiitkota.ac.in/pdf/library/IIIT_Kota_Library_Rules_and_Regulations.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <b style={{color:"blue"}}>IIIT Kota Library Rules & Regulations</b>
                    </a>
                  </p>
                </Col>
              </Row>

              <Row className="text-justify">
                <Col lg={12} className="mt-3">
                  <h6 className="text-justify">
                    IIIT Kota Central Library is located at 2nd floor of the
                    Academic block. For smooth functioning of the library and
                    coordination with all faculty members, students and staff,
                    Library Committee and Library Advisory Committee for IIIT
                    Kota have been constituted. IIIT Kota Central Library
                    provides book bank facility to IIIT Kota undergraduate
                    students which was started in July 2018. Per day circulation
                    of books and number of visitors in library is around 41 and
                    113 respectively. Total numbers of books in IIIT Kota
                    library are 7125 (as on 30.6.2024). Additionally, 15 vendors
                    were empanelled in the session 2024-25 and the books were
                    purchased through Vendors in this year.
                  </h6>
                </Col>
                <Col lg={12} className="mt-5">
                  <Table bordered responsive>
                    <h6 className="only-padding">
                      <thead>
                        <tr>
                          <th style={{ width: "606px" }}>Particulars</th>
                          <th style={{ width: "404px" }}>
                            Number (as on 30.06.2024)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Students </td>
                          <td>878</td>
                        </tr>
                        <tr>
                          <td>Faculty and Staff </td>
                          <td>16</td>
                        </tr>
                        <tr>
                          <td>Library Books </td>
                          <td>1963</td>
                        </tr>
                        <tr>
                          <td>Book Bank Books </td>
                          <td>4752</td>
                        </tr>
                        <tr>
                          <td>Library Reference Books </td>
                          <td>20</td>
                        </tr>
                        <tr>
                          <td>Fiction and Non-fiction books </td>
                          <td>365</td>
                        </tr>
                        <tr>
                          <td>Donated Books</td>
                          <td>25</td>
                        </tr>
                        <tr>
                          <td>Magazines </td>
                          <td>13</td>
                        </tr>
                        <tr>
                          <td>Newspaper </td>
                          <td>3</td>
                        </tr>
                        {/* <tr>
                          <td>English Newspaper </td>
                          <td>2</td>
                        </tr>
                        <tr>
                          <td>Employment Newspaper 1 </td>
                          <td>1</td>
                        </tr> */}
                      </tbody>
                    </h6>
                  </Table>
                </Col>
                <Col lg={12} className="mt-5">
                  <h4 className=" mt-3 mb-3">
                    <b>Working Group</b>
                  </h4>
                  <Table bordered responsive className="wgroup">
                    <h6 className="only-padding">
                      <thead>
                        <tr>
                          <th style={{ width: "100px" }}>S No.</th>
                          <th style={{ width: "300px" }}>Name</th>
                          <th style={{ width: "150px" }}>Department</th>
                          <th style={{ width: "280px" }}>Email ID</th>
                          <th style={{ width: "320px" }}>Position</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Dr.Prikshit K. Singh</td>
                          <td>ECE</td>
                          <td>parikshit@iiitkota.ac.in</td>
                          <td>Convener</td>
                        </tr>
                        <tr>
                          <td>2</td>
                          <td>Dr. Isha Pathak Tripathi</td>
                          <td>CSE</td>
                          <td>isha@iiitkota.ac.in</td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <td>3</td>
                          <td>Dr. Gyan Singh Yadav</td>
                          <td>CSE</td>
                          <td>gyansingh.cse@iiitkota.ac.in</td>
                          <td>Member</td>
                        </tr>
                      </tbody>
                    </h6>
                  </Table>
                </Col>
                <Col lg={12} className="mt-5">
                  <h4 className=" mt-3 mb-3">
                    <b>Library Staff</b>
                  </h4>
                  <Table bordered responsive className="wgroup">
                    <h6 className="only-padding">
                      <thead>
                        <tr>
                          <th style={{ width: "100px" }}>S No.</th>
                          <th style={{ width: "300px" }}>Name</th>
                          <th style={{ width: "150px" }}>Department</th>
                          <th style={{ width: "280px" }}>Email ID</th>
                          <th style={{ width: "320px" }}>Position</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>Mr. Satyanarayan Sharma</td>
                          <td>Library</td>
                          <td>satyanarayan@iiitkota.ac.in</td>
                          <td>Library Information Officer</td>
                        </tr>
                      </tbody>
                    </h6>
                  </Table>
                </Col>
                <Col lg={12} className="mt-5">
                  <h4 className=" mt-3 mb-3">
                    <b>Facilities</b>
                  </h4>
                  <h6 className="text-justify">
                    The Central library has a Library Management Software - KOHA
                    (Open Source Software) customized for Institute.
                  </h6>
                </Col>
                <Col lg={12} className="mt-5">
                  <h4 className=" mt-3 mb-3">
                    <b>Services</b>
                  </h4>
                
                  <h5 className="text-justify">
                    <b>Reference Service:</b>
                  </h5>
                  <h6 className="text-justify">
                    Reference service will be provided in person, by telephone,
                    by e-mail directly from the library. It helps users to
                    fulfill their information needs. Please drop your request to
                    library@iiitkota.ac.in
                  </h6>
                  <h5 className="text-justify">
                    <b>Current Awareness Service:</b>
                  </h5>
                  <h6 className="text-justify">
                    This service provides the current information about the
                    acquisition/updates/contents of library like:<br></br>
                    1. New Subscribed Database /E Resources<br></br> 2. New
                    Arrivals of Books in library <br></br>
                    3. List of newspaper/ magazine etc.
                  </h6>
                  <h5 className="text-justify">
                    <b>Documnet Delivery Service:</b>
                  </h5>
                  <h6 className="text-justify">
                    Central Library arranges to provide e- documents like papers
                    from journals, conference proceedings, reports etc. that are
                    not available in IIIT Kota library collection. This service
                    is provided to all library users for academic and research
                    purpose only. Please drop your request to
                    library@iiitkota.ac.in
                  </h6>
                  <h5 className="text-justify">
                    <b>Library Orientation Programme:</b>
                  </h5>
                  <h6 className="text-justify">
                    Central Library organizes Library Orientation Programme for
                    the new entrants in the beginning of the academic year. The
                    aim of this programme is to make the new members more
                    familiar with the library resources, services and rules so
                    that they can avail library services up to a great extent.
                    Library also offers different training programme and
                    workshops time to time.
                  </h6>
                  <h5 className="text-justify">
                    <b>Ask a Librarian:</b>
                  </h5>
                  <h6 className="text-justify">
                    This service will be provided in person, by telephone or by
                    e-mail directly to the librarian. It helps users to fulfill
                    their information needs. Please drop your request to
                    librarian@iiitkota.ac.in
                  </h6>
                </Col>
                <Col lg={12} className="mt-5">
                  <h4 className=" mt-3 mb-3">
                    <b>General Library Rules:</b>
                  </h4>
                  <h6 className="text-justify">
                    1. All the library members will enter in the library with
                    their Identity Card.<br></br>
                    2. All library members must sign In/Out register available
                    with the attendant at the main gate of the Library.<br></br>
                    3. While entering the library, user should leave his/her
                    personal belongings such as bags, personal books etc. at the
                    property counter reserved for this purpose. Loose papers and
                    notebooks may however be taken into the library. Members
                    leaving the library should permit the library staff to
                    examine their personal belongings (if asked). The members
                    are advised not to leave any valuable/expensive items at the
                    property counter.<br></br>
                    4. The library will not be responsible for member’s personal
                    belonging/property lost in the library.<br></br>
                    5. The library members shall maintain perfect order and
                    silence in the library and should switch off their Mobiles
                    or keep them on vibration mode.<br></br>
                    6. Making noise, spiting, smoking, eating, drinking,
                    sleeping, talking loudly and to visit the library in
                    intoxicated condition is strictly prohibited in the library.
                    <br></br>
                    7. Library member will be responsible for any damage caused
                    to the library property or reading material. The member has
                    to replace the damage material/property and also has to pay
                    the penalty imposed in such cases.<br></br>
                    8. Student/members will vacate their seats ten minutes
                    before the close of library.<br></br>
                    9. The Librarian reserve the right to suspend/cancel the
                    membership of any member found misbehaving with the library
                    staff or for any other indecent behavior.<br></br>
                    10. If any library member is found indulging in book hiding,
                    stealing or mutilating, disciplinary action will be taken
                    against him/her.<br></br>
                    11. Violation of library rules or misconduct by a member may
                    invite discontinuation of the library privileges.<br></br>
                    12. Books/Documents taken out of the shelves must be left on
                    the table. Replacing the documents on shelves by members is
                    not encouraged as the documents may get misplaced.<br></br>
                    13. Library members should not mark, underline, write, or
                    tear pages or otherwise damage the library documents
                    including books/magazines/journals etc.<br></br>
                    14. Reference Books/Magazines are strictly prohibited
                    outside the library<br></br>
                    15. The Library, with the approval of the competent
                    authority reserves the right to add, delete, or modify any
                    of these rules as and when required.<br></br>
                    16. No photograph of the Library shall be taken without the
                    prior permission of the Librarian.<br></br>
                    17. Keep the Library premises tidy.<br></br>
                    18. Library Timing: 9:00 AM -- 06:00 PM (Monday to Friday).
                    <br></br>
                    19. Library will close on Gazetted Holidays declared by the
                    Institute.<br></br>
                    20. Food and beverages items are not allowed in the library.
                    <br></br>
                    21. Short dress is not allowed in the library.<br></br>
                    22. Suggestions on all aspects of library services are
                    welcome.<br></br>
                  </h6>
                </Col>
                {/* <Col lg={12}>
                  <h4 className=" mt-3 mb-3">
                    <b>Development of Library</b>
                  </h4>
                  <h6 className="text-justify">
                    <ol>
                      <li className="mb-3">
                        Library Automation The institutes library runs its
                        database on KOHA (Integrated Library Management
                        Software).{" "}
                      </li>
                      <li className="mb-3">
                        Electronic/Online Resources and Databases Through
                        RemoteX The VPS Based Online Digital Library
                        Subscription for IIIT, Kota Library is being accessed
                        through RemoteX. The students can read and access online
                        resources of MNIT through the same.Digital Library like
                        facility has been made available to the IIIT Kota users.
                        Library having LAN and Wi-fi connectivity for Internet
                        based services (which is operable on 24×7) is available
                        to the IIIT Kota users.
                      </li>
                      <li className="mb-3">
                        {" "}
                        Online Journals (INDEST-AICTE Consortium, MoE,GOI) The
                        IIIT Kota library also provides e-Resources under
                        e-Shodh Sindhu Consortium developed by MoE and supported
                        by INFLIBNET Centre, Gandhinagar, Gujarat.IIIT Kota is
                        using about 2000 Online Journals through INDEST_AICTE
                        Consortium (like ACM Digital Library, ASCE, ASME, (IEL
                        Online), IEEE and IET, Springer, Nature etc.) and about
                        1000 subscribed online Journals like Taylor & Francis,
                        Emerald, Sage etc. About 2000 e-Books (perpetual basis)
                        of various subjects published by Pearson, Sage,
                        Springer, McGraw Hill, Tata McGraw Hill CRC Press,
                        Taylor & Francis, Cenage Learning.
                      </li>
                    </ol>
                  </h6>
                </Col>
                <Col lg={12} className="mt-5">
                  <Table bordered>
                    <h6 className="only-padding">
                      <thead>
                        <tr>
                          <th style={{ width: "1008px" }}>e-Resources</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <ul>
                              <li> ACM Digital Library</li>{" "}
                              <li> ASCE e-journals </li>{" "}
                              <li> ASME e-journals </li> <li>Springer Link</li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Online Journals Subscribed
                            <ul>
                              <li> SAGE Pub. </li> <li> Taylor and Francis</li>{" "}
                              <li> Emerald</li> <li>Science Direct</li>{" "}
                              <li> IEL</li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            Bibliographic Database
                            <ul>
                              <li>Access Science Database</li>
                              <li> PROWESS</li>
                              <li> Economics Research</li>
                            </ul>
                          </td>
                        </tr>
                        <tr>
                          <td>E-Books</td>
                        </tr>
                        <tr>
                          <td>J-GATE AGGRIGATTO</td>
                        </tr>
                        <tr>
                          <td>NPTEL Resources</td>
                        </tr>
                      </tbody>
                    </h6>
                  </Table>
                </Col>
                <Col lg={12}>
                  <h6 className="text-justify">
                    IIIT Kota users can access DELNET (Database service). Using
                    DELNET, IIIIT Kota users can access various databases of
                    about 2 crores books, journals, thesis and dissertations,
                    non-print materials etc. available with 4697 Indian and
                    Foreign libraries. Also users can borrow the books from
                    other libraries under 'INTER LIBRARY LOAN' scheme. Access
                    Science-database (EST) is also available.
                  </h6>
                </Col> */}
                {/* <Col lg={12}>
                  <h4 className=" mt-3">
                    <b>NPTEL</b>
                  </h4>
                  <h6 className="text-justify">
                    You are encouraged to leverage following NPTEL resources:
                    <ul style={{ marginBottom: "0px" }}>
                      <li>
                        <a href="https://nptel.ac.in/">CSE courses at NPTEL</a>
                      </li>

                      <li>
                        <a href="https://nptel.ac.in/">ECE courses at NPTEL</a>
                      </li>
                    </ul>
                    Alternatively, you may browse{" "}
                    <a href="https://nptel.ac.in/:">NPTEL website</a>
                    <ul style={{ marginBottom: "0px" }}>
                      <li>
                        <a href="http://nptel.ac.in/course.php?disciplineId=106">
                          Computer Science and Engineering
                        </a>
                      </li>

                      <li>
                        <a href="http://nptel.ac.in/courses/117102059/">
                          Electronics and Communication Engineering
                        </a>
                      </li>
                    </ul>
                  </h6>
                </Col> */}
                {/* <Col lg={12}>
                  <h4 className=" mt-3">
                    <b>SWAYAM</b>
                  </h4>
                  <h6 className="text-justify">
                    <a href="https://swayam.gov.in/About">SWAYAM</a> is an
                    initiative by GoI to make some of the best learning
                    resources accessible to all. <br />
                    <a href="https://swayam.gov.in/Undergraduate">
                      SWAYAM portal for undergraduates
                    </a>{" "}
                    <br />
                  </h6>
                </Col>
                <Col lg={12}>
                  <h4 className=" mt-3">
                    <b>National Digital Library</b>
                  </h4>
                  <h6 className="text-justify">
                    <a href="https://ndl.iitkgp.ac.in/">
                      National Digital Library
                    </a>
                  </h6>
                </Col> */}
                <Col lg={12}>
                  <h4 className=" mt-3 mb-3">
                    <b>
                      List of empanelled vendors for the supply of Library books
                    </b>
                  </h4>
                </Col>

                <Col lg={12} className="mt-3" style={{ overflow: "auto" }}>
                  <Table bordered>
                    <h6 className="only-padding">
                      <thead>
                        <tr>
                          <th style={{ width: "101px" }}>S.No</th>
                          <th style={{ width: "303px" }}>Vendors Name</th>
                          <th style={{ width: "505px" }}>Address</th>
                          <th style={{ width: "202px" }}>E-Mail</th>
                          <th style={{ width: "303px" }}>Tel./ Mobile No.</th>
                        </tr>
                      </thead>
                      <tbody>

                        <tr>
                          <th>1</th>
                          <td>Allied Informatics</td>
                          <td>B-83, Golden Jewel Apartment, Ganesh Marg, Bapu Nagar , Jaipur</td>
                          <td>allied.info@yahoo.com</td>
                          <td>0141-2719034, 4003870</td>
                        </tr>

                        <tr>
                          <th>2</th>
                          <td>University Book House (P) LTD</td>
                          <td>79, Chaura Rasta, Jaipur -302003</td>
                          <td>uni_bookhouse@yahoo.com</td>
                          <td>0141-2311466, 2313382 , 9983075242,   9414046753</td>
                        </tr>

                        <tr>
                          <th>3</th>
                          <td>Shankar's Book Agency Pvt. Ltd.</td>
                          <td>103, Munish Plaza, 20 Ansari road, Daryaganj. Post box no. 7031, New Delhi-110002</td>
                          <td>sbad@shankarsbook.com</td>
                          <td>0141-2311466, 2313382 , 9983075242,   9414046753</td>
                        </tr>

                        <tr>
                          <th>4</th>
                          <td>Govinda Book House</td>
                          <td>59 , Gautam Apartments, Gulmoher Park Road, New Delhi-110049</td>
                          <td>govindabookhouse@gmail.com</td>
                          <td>011-26851413, 9810156183</td>
                        </tr>

                        <tr>
                          <th>5</th>
                          <td>Hi- Tech Book Services</td>
                          <td>G-5 Ganpati Bhawan, 4675/21, Ansari road, Daryaganj, New Delhi-110002</td>
                          <td>htbooks@yahoo.com</td>
                          <td>9811140844</td>
                        </tr>

                        <tr>
                          <th>6</th>
                          <td>Ahuja Book Company Pvt. Ltd. Publisher & Suppliers of medical, technical & Scientific Books</td>
                          <td>4348/4C, Ansari Road, Daryaganj, New Delhi-110002</td>
                          <td>info@ahujabooks.com</td>
                          <td>011-23276564, 23245859, 23289999</td>
                        </tr>

                        <tr>
                          <th>7</th>
                          <td>Satyam Books Pvt. Ltd.</td>
                          <td>2/13, Ansari road, Daryaganj, New Delhi-110002</td>
                          <td>satyambooks@hotmail.com, satishu1965@gamil.com</td>
                          <td>011-23242686, 23245698, 40391920, 9811132241</td>
                        </tr>

                        <tr>
                          <th>8</th>
                          <td>Metro Books</td>
                          <td>H.No. 309, Pocket-1, Phase-2, Netaji Subhash Apartment, Sec-13, Dwarka, New Delhi-110078</td>
                          <td>metrobooks2007@hotmail.com, metrobooks2007@gmail.com</td>
                          <td>9582901115, 9595522122</td>
                        </tr>

                        <tr>
                          <th>9</th>
                          <td>Metro Books PVT . LTD</td>
                          <td>20/4637, Ansari Road, Daryaganj, New Delhi-110002</td>
                          <td>metrobooks@rediffmail.com, metrobookspl@gmail.com</td>
                          <td>011-23261008, 9650391843</td>
                        </tr>

                        <tr>
                          <th>10</th>
                          <td>Allied Publishers Private Limited</td>
                          <td>D-5, SECTOR-2, NOIDA-201301</td>
                          <td>delhi.books@alliedpublishers.com</td>
                          <td>0120-4320295, 2542557</td>
                        </tr>

                        <tr>
                          <th>11</th>
                          <td>Technical bureau India Pvt. Ltd.</td>
                          <td>E/261, Shastri Nagar Delhi-110052</td>
                          <td>techbureau@gmail.com, info@technicalbureau.net</td>
                          <td>9811589130</td>
                        </tr>

                        <tr>
                          <th>12</th>
                          <td>News & Literature House</td>
                          <td>44-45, 1st Floor, Kalawati Paliwal Market, Gumanpura, kota, Raj.-324007</td>
                          <td>nlhkota@gmail.com</td>
                          <td>0744-2392079, 9829038026</td>
                        </tr>

                        <tr>
                          <th>13</th>
                          <td>Aditya Books Private Limited Distributors & Publishers</td>
                          <td>B-116, Sec-67, Noida-201301, Distt. Gautam Budh Nagar (UP)</td>
                          <td>orders@adityabooks.in</td>
                          <td>0120-2484152, 9810773221, 9810773223</td>
                        </tr>

                        <tr>
                          <th>14</th>
                          <td>INDICA Publisher & Distributors Pvt. Ltd.</td>
                          <td>7/31, Ansari road, Daryaganj, New Delhi-110002</td>
                          <td>ipdplbooks@gmail.com, ipdplbooks@yahoo.co.in, itaspldel@gmail.com,</td>
                          <td>011-23243027, 23243028, 23243006, 9810121450</td>
                        </tr>

                        <tr>
                          <th>15</th>
                          <td>Insat Books and Periodicals</td>
                          <td>4575/15, Onkar house, main Aggarwal Road, Daryaganj, New Delhi-110002</td>
                          <td>insatbp@gmail.com</td>
                          <td>011-41562759, 9910511488, 9873354414</td>
                        </tr>
                        {/*}
                        <tr>
                          <th>1</th>
                          <td>Allied Informatics</td>
                          <td>
                            B-83, Golden Jewel Apartment, Ganesh Marg, Bapu
                            Nagar, Jaipur{" "}
                          </td>
                          <td>allied.info@yahoo.com</td>
                          <td>0141-2719034, 4003870 </td>
                        </tr>
                        <tr>
                          <th>2</th>
                          <td>University Book House (P) LTD</td>
                          <td>79, Chaura Rasta, Jaipur -302003</td>
                          <td>uni_bookhouse@yahoo.com</td>
                          <td>0141-2311466, 2313382 ,9983075242</td>
                        </tr>
                        <tr>
                          <th>3</th>
                          <td> International Book Centre</td>
                          <td>
                            2/19, Ground floor, Daryaganj, New Delhi-110002
                          </td>
                          <td>ibc.delhi02@gmail.com, sales.ibcbd@gmail.com</td>
                          <td>011-23255206, 9873182405</td>
                        </tr>

                        <tr>
                          <th>4</th>
                          <td>GovindaBook House</td>
                          <td>
                            59, Gautam Apartments, Gulmohar Park Road, New
                            Delhi-110049
                          </td>
                          <td>govindabookhouse@gmail.com</td>
                          <td>9810156183</td>
                        </tr>
                        <tr>
                          <th>5</th>
                          <td>Hi-Tech Book Services</td>
                          <td>
                            G-5 Ganpati Bhawan, 4675/21, Ansari Road, Daryaganj,
                            New Delhi-110002
                          </td>
                          <td>htbooks@yahoo.com, htbsnd@gmailo.com</td>
                          <td>9811140844</td>
                        </tr>
                        <tr>
                          <th>6</th>
                          <td>Atlantic Publisher & Distributors (P) Ltd.</td>
                          <td>
                            7/22, Ansari Road, Daryaganj, New Delhi-110002
                          </td>
                          <td>
                            orders@atlanticbooks.com, sales@atlanticbooks.com
                          </td>
                          <td>011-40775252, 40775214, 23273880</td>
                        </tr>
                        <tr>
                          <th>7</th>
                          <td>Techniz Books International</td>
                          <td>
                            B-300, Okhla Indi. Area Ph-1, New Delhi-110020
                          </td>
                          <td>info@technizbooks.com</td>
                          <td>011-46852100, 9818365999</td>
                        </tr>
                        <tr>
                          <th>8</th>
                          <td>New India Book Agency</td>
                          <td>
                            Prakash Deep building, 329, 3rd floor, 22,
                            Daryaganj, New Delhi-110002
                          </td>
                          <td>nibabooks@gmail.com</td>
                          <td>011- 23268432, 9968876354</td>
                        </tr>
                        <tr>
                          <th>9</th>
                          <td>Metro Books</td>
                          <td>
                            H.No. 309, Pocket-1, Phase-2, Netaji Subhash
                            Apartment, Sec-13, Dwarka, New Delhi-110078
                          </td>
                          <td>
                            metrobooks2007@hotmail.com, metrobooks2007@gmail.com
                          </td>
                          <td>9582901115, 9595522122</td>
                        </tr>
                        <tr>
                          <th>10</th>
                          <td> Allied Publishers Private Limited</td>
                          <td>1-13/14, Asaf Ali Road, New Delhi-110002</td>
                          <td>delhi.books@alliedpublishers.com</td>
                          <td>011-23233004, 23239001, 23235967</td>
                        </tr>
                        <tr>
                          <th>11</th>
                          <td>
                            Aditya Books Private Limited Distributors &
                            Publishers
                          </td>
                          <td>
                            B-116, Sec-67, Noida-201301, Distt. Gautam Budh
                            Nagar (UP)
                          </td>
                          <td>orders@adityabooks.in</td>
                          <td>0120-2484151-52</td>
                        </tr>
                        <tr>
                          <th>12</th>
                          <td>INDICA Publisher & Distributors Pvt. Ltd.</td>
                          <td>
                            7/31, Ansari road, Daryaganj, New Delhi-110002
                          </td>
                          <td>ipdplbooks@gmail.com, itaspldel@gmail.com</td>
                          <td>011-23243027, 23243028, 23243006</td>
                        </tr>
                        <tr>
                          <th>13</th>
                          <td>Insat Books and Periodicals</td>
                          <td>
                            4575/15, Onkar house, main Aggarwal Road, Daryaganj,
                            New Delhi-110002
                          </td>
                          <td>insatbp@gmail.com</td>
                          <td>011-41562759, 9910511488</td>
                        </tr>

                        */}
                        {/* <tr>
                          <th>14</th>
                          <td>
                            Viva Books Private Limited, Publisher and
                            Distributors
                          </td>
                          <td>
                            4737/23, Ansari Road, Daryaganj, New Delhi-110002
                          </td>
                          <td>response@vivagroupindia.net</td>
                          <td>011-42242200</td>
                        </tr>
                      
                        <tr>
                          <th>15</th>
                          <td>Bombay Books importer & Wholesalers</td>
                          <td>
                            3, Humjoli, B.M. Bhargava Road, Santacruz (W),
                            Mumbai- 400054
                          </td>
                          <td>bombaybooks@yahoo.co.in </td>
                          <td>022-26603580, 9082200394</td>
                        </tr>
                        <tr>
                          <th>16</th>
                          <td> Vardhman Books</td>
                          <td>C-1/ 146, Yamuna Vihar, Delhi-53</td>
                          <td>infovardhmanbooks@gmail.com</td>
                          <td>011-43536396, 9811287652</td>
                        </tr>
                         
                        <tr>
                          <th>17</th>
                          <td>Shankar's Book Agency Pvt. Ltd.</td>
                          <td>
                            103, Munish Plaza, 20 Ansari road, Daryaganj. Post
                            box no. 7031, New Delhi-110002
                          </td>
                          <td>sbad@shankarsbook.com, sbald@gmail.com</td>
                          <td>011-23757771, 23279967, 65381560</td>
                        </tr>
                        <tr>
                          <th>18</th>
                          <td>
                            Ahuja Book Company Pvt. Ltd. Publisher & Suppliers
                            of medical, technical & Scientific Books
                          </td>
                          <td>
                            4348/4C, Ansari Road, Daryaganj, New Delhi-110002{" "}
                          </td>
                          <td>info@ahujabooks.com</td>
                          <td>011-23276564, 23245859, 23289999</td>
                        </tr>
                      
                        <tr>
                          <th>19</th>
                          <td>Technical bureau India Pvt. Ltd.</td>
                          <td>E/261, Shastri Nagar Delhi-110052</td>
                          <td>
                            techbureau@gmail.com, info@technicalbureau.net
                          </td>
                          <td>9811589130</td>
                        </tr>
                        <tr>
                          <th>20</th>
                          <td> News & Literature House</td>
                          <td>
                            44-45, 1st Floor, Kalawati Paliwal Market,
                            Gumanpura, kota, Raj.-324007
                          </td>
                          <td>nlhkota@gmail.com</td>
                          <td>0744-2392079, 9829038026</td>
                        </tr>
                        <tr>
                          <th>21</th>
                          <td>Tripathi Book Distributors</td>
                          <td>
                            N.P.A. Arcade, Shop No. 212. 2nd floor, 23 M.G.
                            Marg, Civil Lines, Allahabad
                          </td>
                          <td>tbxalld@gmail.com</td>
                          <td>9415235892</td>
                        </tr>
                        <tr>
                          <th>22</th>
                          <td> D.S. Books International</td>
                          <td>HIG-17, Kalindipuram, Allahabad -211011</td>
                          <td>dsbiindia@gmail.com, dsbooksnd@gmail.com</td>
                          <td>9415367061</td>
                        </tr>
                    
                        <tr>
                          <th>23</th>
                          <td>Consortium Books Pvt. Ltd.</td>
                          <td>
                            91A/5B, Muir Road , Near Bank of India, Ashok Nagar,
                            Prayagraj-211001
                          </td>
                          <td>consortiumbooks@gmail.com</td>
                          <td>9076651709, 7388862786</td>
                        </tr>
                        <tr>
                          <th>24</th>
                          <td>Useful Book Service</td>
                          <td>
                            C-369, Opp. Shekhar Hospital, Church Road, Indira
                            Nagar, Lucknow-226016
                          </td>
                          <td>
                            useful@airtelmail.in, usefulbookservice@gmail.com
                          </td>
                          <td> 9415017416, 8948858567</td>
                        </tr>
                     
                        <tr>
                          <th>25</th>
                          <td>Neoti Book Agency Private Limited</td>
                          <td>
                            4596/1 1st floor, 11, Daryaganj, New Delhi-110002
                          </td>
                          <td>neotibooks@gmail.com, info@neotibooks.com</td>
                          <td>011-43583117, 9873423117, 9811910303</td>
                        </tr>
                        <tr>
                          <th>26</th>
                          <td>Satyam Books Pvt. Ltd.</td>
                          <td>
                            2/13, Ansari road, Daryaganj, New Delhi-110002
                          </td>
                          <td>
                            satyambooks@hotmail.com, satyambooks@satyambooks.in
                          </td>
                          <td>011-23242686, 23245698, 40391920</td>
                        </tr> */}
                      </tbody>
                    </h6>
                  </Table>
                </Col>
                <Col lg={12}>
                  <h4 className=" mt-3">
                    <b>E-Resources</b>
                  </h4>
                  <h6 className="text-justify">
                    <a href="https://files.iiitkota.ac.in/pdf/Library_Eresources.pdf">
                      E-Resources
                    </a>
                  </h6>
                </Col>

                <Col lg={12}>
                  <h4 className=" mt-3">
                    <b>Useful Links</b>
                  </h4>
                  <h6 className="text-justify">
                    <a href="https://irins.org/">
                      Indian Research Information Network System
                    </a>
                  </h6>
                  <h6 className="text-justify">
                    <a href="https://vidwan.inflibnet.ac.in/">VIDWAN</a>
                  </h6>
                  <h6 className="text-justify">
                    <a href="https://ndl.iitkgp.ac.in/">
                      National Digital Library of India IITKGP
                    </a>
                  </h6>
                  <h6 className="text-justify">
                    <a href="https://www.nationallibrary.gov.in/">
                      National Digital Library
                    </a>
                  </h6>
                  <h6 className="text-justify">
                    <a href="https://www.inflibnet.ac.in/">Inflibnet Center</a>
                  </h6>
                  <h6 className="text-justify">
                    <a href="https://shodhchakra.inflibnet.ac.in/">
                      Shodh Chakra
                    </a>
                  </h6>
                  <h6 className="text-justify">
                    <a href="https://nptel.ac.in">NPTEL</a>
                  </h6>
                  <h6 className="text-justify">
                    <a href="https://swayam.gov.in">Swayam</a>
                  </h6>
                </Col>
                <Col lg={12}>
                  <h4 className="mt-3">
                    <b>Library Timing</b>
                  </h4>
                  09:00 AM to 06:00 PM (Monday to Friday) <br />
                  Library will close on Gazette Holidays declared by the
                  Institute.
                </Col>
              </Row>
              <br />
              {/* <Row className="text-center justify-centent-center">
                <Col lg={12}>
                  <h4 className="mt-5">
                    <b>Librarian</b>
                  </h4>
                </Col>
              </Row> */}
              <Row className="justify-content-center mt-3">
                <Col lg="12">
                  <Row className="row-grid  justify-content-center">
                    <Col lg="4">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <div className="mb-2 text-center">
                            {/* <i className="ni ni-check-bold" /> */}
                            <img
                              alt="Mr. Satya Narayan Sharma"
                              height="350px"
                              className="OfficeStaff-img"
                              src="https://files.iiitkota.ac.in/images/staff/10.webp"
                            />
                          </div>
                          <div className="text-center">
                            <h6 className="text-primary ">
                              <b>Mr. Satya Narayan Sharma</b>
                            </h6>

                            <div className="px-2">
                              Library Information Officer
                            </div>
                            <h6>
                              <a href="tel:9549651643">
                                <i className="fas fa-phone-square-alt px-2" />
                                +91-9549651643
                              </a>
                            </h6>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
                <Row className="row-grid  justify-content-center">
                  <Col lg={12} className="mt-5">
                    <h6 className="text-justify">
                      <b>
                        If you have any query, you may send an email to{" "}
                        <a href="mailto:library@iiitkota.ac.in">
                          {" "}
                          <i className="fa fa-envelope px-2" />
                          library@iiitkota.ac.in
                        </a>{" "}
                      </b>
                    </h6>
                  </Col>
                </Row>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Library;
