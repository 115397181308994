import React from "react";
import { Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
import Table from "reactstrap/lib/Table";
import "assets/css/style.css";
class Examination extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Examination and Evaluation - Indian Institute of Information
            Technology Kota
          </title>
          <meta
            name="description"
            content="Examination and Evaluation Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Examination, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Examination in IIIT Kota, Examination of IIIT Kota,IIIT Kota,Examination,Best Examination,Computer Science and Engineering
             Examination ,
             Electronics and Communication Engineering Examination"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Examination and Evaluation</b>
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <h4>
                    <b>Credit System </b>
                  </h4>
                  <h6 className="text-justify">
                    Education at the institute is organized around the credit
                    system of study. The prominent features of a credit system
                    are the process of continuous evaluation of a students’
                    performance, and a flexibility to allow a student to
                    progress at an optimum pace. Each course has a certain
                    number of credits, which describes its weightage. A
                    student's performance is measured in terms of Cumulative
                    Grade Point Average (CGPA) which is calculated in terms of
                    the number of credits that he/she has completed
                    satisfactorily. A minimum credit should be earned in order
                    to qualify for semester promotion. In order to qualify for
                    the degree, number of credits to be earned for each
                    programme is specified in ‘Ordinance for Undergraduate
                    (B,Tech.) Programmes’ of IIIT Kota, March 2019. Every course
                    is coordinated by a member of the teaching staff of the
                    department, which offers the course in a given semester.
                    This faculty member is called the Course Coordinator. He/she
                    has full responsibility for conducting the course,
                    coordinating the work of the other members of the faculty
                    involved in that course and for holding tests and
                    assignments and awarding grades. For any difficulty, a
                    student is expected to approach the Course Coordinator for
                    advice and clarification.
                  </h6>
                </Col>
                <Col lg={12} className="mt-5">
                  <h4>
                    <b>Grading System</b>
                  </h4>
                  <h6 className="text-justify">
                    The Grading system is followed as per the Table below.
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col lg={12} xs={12} className="mt-2 mb-5">
                  <Table bordered>
                    <h6 className="only-padding">
                      <thead>
                        <tr>
                          <th style={{ width: "404px" }}>
                            Academic Performance
                          </th>
                          <th style={{ width: "303px" }}>Grades</th>
                          <th style={{ width: "303px" }}>Grade Points </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td> Outstanding</td>
                          <td>AA</td>
                          <td>10</td>
                        </tr>
                        <tr>
                          <td> Excellent</td>
                          <td>AB</td>
                          <td>9</td>
                        </tr>
                        <tr>
                          <td> Very Good</td>
                          <td>BB</td>
                          <td>8</td>
                        </tr>
                        <tr>
                          <td> Good</td>
                          <td>BC</td>
                          <td>7</td>
                        </tr>
                        <tr>
                          <td> Average</td>
                          <td>CC</td>
                          <td>6</td>
                        </tr>
                        <tr>
                          <td> Below Average</td>
                          <td>CD</td>
                          <td>5</td>
                        </tr>
                        <tr>
                          <td> Marginal</td>
                          <td>DD</td>
                          <td>4</td>
                        </tr>
                        <tr>
                          <td> Failed due to Poor Performance </td>
                          <td>FP</td>
                          <td>--</td>
                        </tr>
                        <tr>
                          <td> Failed due to Attendance Shortage</td>
                          <td>FA</td>
                          <td>--</td>
                        </tr>
                        <tr>
                          <td> Incomplete</td>
                          <td>I</td>
                          <td>--</td>
                        </tr>
                        <tr>
                          <td> Withdrawal</td>
                          <td>W</td>
                          <td>--</td>
                        </tr>
                      </tbody>
                    </h6>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col>
                  <a
                    href="http://www.mnit.ac.in/academics/cgpa.php"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <a href="https://files.iiitkota.ac.in/pdf/conversion_formula/Conversion_Formula.pdf">CGPA to Percentage Formula</a>
                  </a>{" "}
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Examination;
