import React from "react";
import { Container, Row, Col, Table } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import "assets/css/style.css";
import Helmet from "react-helmet";
class Senate extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Senate - Indian Institute of Information Technology, Kota
          </title>
          <meta
            name="description"
            content="Senate - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="Senate, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  <h2>
                    <b>Senate</b>
                  </h2>
                </Col>
              </Row>

              <Row>
                <Col lg={12} xs={12} className="mt-5 mb-5">
                  <Table bordered>
                    <h6 className="only-padding">
                      <thead>
                        <tr>
                          <th style={{ width: "101px" }}>S.No</th>
                          <th style={{ width: "425px" }}>Name</th>
                          <th style={{ width: "484px" }}> Role</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>1.</th>
                          <td>
                            <b>
                              <a
                                href="/director"
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Prof. Narayana Prasad Padhy
                              </a>
                            </b>
                            <br />
                            Director
                          </td>
                          <td>Chairperson</td>
                        </tr>

                        <tr>
                          <th>2.</th>
                          <td>
                            <b>
                              <a
                                href="/coordinator"
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Prof. K. K. Sharma
                              </a>
                            </b>
                            <br />
                            Coordinator Dean (Training & Placement)
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>3.</th>
                          <td>
                            <b>
                              <a
                                href="https://mnit.ac.in/dept_ece/profile?fid=QKQ="
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Prof. D. Boolchandani
                              </a>
                            </b>
                            <br />
                            Dean Academic, MNIT
                          </td>
                          <td>Member</td>
                        </tr>
                        
                        <tr>
                          <th>4.</th>
                          <td>
                            <b>
                              <a
                                href="https://mnit.ac.in/dept_civil/profile?fid=Qq0="
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Prof. Sanjay Mathur
                              </a>
                            </b>
                            <br />
                            Dean (Planning & Development)
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>5.</th>
                          <td>
                            <b>
                              <a
                                href="https://mnit.ac.in/dept_mech/profile?fid=RKmF"
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Prof. Harlal Mali
                              </a>
                            </b>
                            <br />
                            Dean (Student Welfare)
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>6.</th>
                          <td>
                            <b>
                              <a
                                href="https://mnit.ac.in/dept_chemical/profile?fid=RK2I"
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Prof. Manish Vashishtha
                              </a>
                            </b>
                            <br />
                           Dean (Research & Consultancy)
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>7.</th>
                          <td>
                            <b>
                              <a
                                href="https://mnit.ac.in/dept_cse/profile?fid=RKSK"
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Dr. Pilli Emmanuel Shubhakar
                              </a>
                            </b>
                            <br />
                            Dean (Academic Affairs)
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>8.</th>
                          <td>
                            <b>
                              <a
                                href="faculty/608525427fb8f4001564e185"
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Dr. Amit Kumar
                              </a>
                            </b>
                            <br />
                            Head of Department, CSE
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>9.</th>
                          <td>
                            <b>
                              <a
                                href="faculty/60852fbe7fb8f4001564e198"
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Dr. Parikshit Kishor Singh
                              </a>
                            </b>
                            <br />
                            Head of Department, ECE
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>10.</th>
                          <td>
                            <b>
                              <a
                                href=""
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                To be Appointed
                              </a>
                            </b>
                            <br />
                            Head of Department, AIDE
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>11.</th>
                          <td>
                            <b>
                              <a
                                href=""
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                 To be Appointed
                              </a>
                            </b>
                            <br />
                            Head of Department, HMAS
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>12.</th>
                          <td>
                            <b>
                              <a
                                href="https://mnit.ac.in/dept_hss/profile?fid=RK+N"
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Dr. Preeti Bhatt
                              </a>
                            </b>
                            <br />
                            Head HSS, MNIT
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>13.</th>
                          <td>
                            <b>
                              <a
                                href="https://mnit.ac.in/dept_dms/profile?fid=RKWO"
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Dr. Deepak Verma
                              </a>
                            </b>
                            <br />
                            Head Management, MNIT
                          </td>
                          <td>Member</td>
                        </tr>
                        
                        <tr>
                          <th>14.</th>
                          <td>
                            <b>
                              <a
                                href="https://mnit.ac.in/dept_math/profile?fid=RK2M"
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Dr. Santosh Choudhary
                              </a>
                            </b>
                            <br />
                            Head Mathematics, MNIT
                            
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>15.</th>
                          <td>
                            <b>
                              <a
                                href="https://mnit.ac.in/dept_cse/profile?fid=Tak="
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Prof. Girdhari Singh
                              </a>
                            </b>
                            <br />
                            Professor, CSE, MNIT
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>16.</th>
                          <td>
                            <b>
                              <a
                                href="https://mnit.ac.in/dept_cse/profile?fid=TKo="
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Prof. Vijay Laxmi
                              </a>
                            </b>
                            <br />
                            Professor, CSE, MNIT
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>17.</th>
                          <td>
                            <b>
                              <a
                                href="https://mnit.ac.in/dept_cse/profile?fid=TKs="
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Dr. Namita Mittal
                              </a>
                            </b>
                            <br />
                            Head CSE, MNIT
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>18.</th>
                          <td>
                            <b>
                              <a
                                href="https://mnit.ac.in/dept_cse/profile?fid=RK6J"
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Dr. Dinesh Gopalani
                              </a>
                            </b>
                            <br />
                            Head AIDE, MNIT
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>19.</th>
                          <td>
                            <b>
                              <a
                                href="https://mnit.ac.in/dept_ece/profile?fid=RKSJ"
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Prof. R. P. Yadav
                              </a>
                            </b>
                            <br />
                            Professor, ECE, MNIT
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>20.</th>
                          <td>
                            <b>
                              <a
                                href="https://mnit.ac.in/dept_ece/profile?fid=Rqs="
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Prof. M. M. Sharma
                              </a>
                            </b>
                            <br />
                            Professor & Head, ECE, MNIT
                          </td>
                          <td>Member</td>
                        </tr>

                        <tr>
                          <th>21.</th>
                          <td>
                            <b>
                              <a
                                href="https://mnit.ac.in/dept_ece/profile?fid=TK8="
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Prof. Lava Bhargava
                              </a>
                            </b>
                            <br />
                            Professor, ECE, MNIT
                           
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>22.</th>
                          <td>
                            <b>
                              <a
                                href="https://mnit.ac.in/dept_ece/profile?fid=TK0="
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Prof. Vijay Janyani
                              </a>
                            </b>
                            <br />
                            Professor, ECE, MNIT
                            
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>23.</th>
                          <td>
                            <b>
                              <a
                                href="https://mnit.ac.in/dept_ece/profile?fid=RKyJ"
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Prof. Ghanshyam Singh
                              </a>
                            </b>
                            <br />
                            Professor, ECE, MNIT
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>24.</th>
                          <td>
                            <b>
                            <a
                                href="https://www.iiitdm.ac.in/administration/director"
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Prof. M. V. Kartikeyan
                              </a>

                            </b>
                            <br />
                            Director, IIITDM Kancheepuram
                            <br />
                            Educationists of Repute
                            
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>25.</th>
                          <td>
                            <b>
                              <a
                                href="https://www.iitr.ac.in/~CSE/Balasubramanian_R_"
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Prof. Balasubramanian Raman
                              </a>
 
                            </b>
                            <br />
                            Professor CSE, IIT Roorkee
                            <br />
                            Educationists of Repute
                          </td>
                          <td>Member</td>
                        </tr>
                        <tr>
                          <th>26.</th>
                          <td>
                            <b>
                            <a
                                href="https://www.iiit.ac.in/faculty/kishore-kothapalli/"
                                style={{ color: "#32325d" }}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Prof. Kishore Kothapalli
                              </a>
                              
                            </b>
                            <br />
                            Dean Academics, IIIT Hyderabad
                            <br />
                            Educationists of Repute
                          </td>
                          <td>Member</td>
                          
                        </tr>
                        <tr>
                          <th>27.</th>
                          <td>
                            <b>
                              <a
                              href=""
                              style={{ color: "#32325d" }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                               Dr. K. M. Suceendran
                              </a>
                            </b>
                            <br/>
                           Head Academic Alliances, Tata Consultancy Services (TCS)
                           <br />
                           Persons with Specialized Knowledge
                          </td>
                          <td>Member</td>
                        </tr>

                        <tr>
                          <th>28.</th>
                          <td>
                            <b>
                              <a
                              href=""
                              style={{ color: "#32325d" }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                               Mr. Deepak Aggarwal
                              </a>
                            </b>
                            <br/>
                           Principle Group Engineering Manager (PGEM), Microsoft India
                           <br />
                           Persons with Specialized Knowledge
                          </td>
                          <td>Member</td>
                        </tr>

                        <tr>
                          <th>28.</th>
                          <td>
                            <b>
                              <a
                              href=""
                              style={{ color: "#32325d" }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                               Mr. Mohit Chawla
                              </a>
                            </b>
                            <br/>
                           Senior Chief Engineer, Samsung R and D, India
                           <br />
                           Persons with Specialized Knowledge
                          </td>
                          <td>Member</td>
                        </tr>

                        <tr>
                          <th>28.</th>
                          <td>
                            <b>
                              <a
                              href="/Registrar"
                              style={{ color: "#32325d" }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                               Prof. Rohit Bhakar
                              </a>
                            </b>
                            <br/>
                           Registrar
                          
                          </td>
                          <td>Member Secretary</td>
                        </tr>

                      </tbody>
                    </h6>
                  </Table>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  <h4>
                    <b>Minutes of Meeting (MoM) of Senate</b>
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col lg={12} xs={12} className="mt-5 mb-5">
                  <Table borderless>
                    <h6>
                      <thead>
                        <tr>
                        <th></th>
                          <th style={{ width: "808px" }}>
                            Minutes of Meeting (MoM)
                          </th>
                          <th style={{ width: "202px" }}>Download</th>
                        </tr>
                      </thead>
                      <tbody>

                      <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>To download the MoM of 10th Senate Meeting</td>
                          <td>
                            <a href="https://files.iiitkota.ac.in/pdf/senate/10th%20Senate%20MoM.pdf">
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>

                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>To download the MoM of 9th Senate Meeting</td>
                          <td>
                            <a href="https://files.iiitkota.ac.in/pdf/senate/9th%20Senate%20MoM.pdf">
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>

                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>To download the MoM of 8th Senate Meeting</td>
                          <td>
                            <a href="https://files.iiitkota.ac.in/pdf/8th%20Senate%20MoM.pdf">
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>To download the MoM of 7th Senate Meeting</td>
                          <td>
                            <a href="https://files.iiitkota.ac.in/pdf/7Senate%20MoM.pdf">
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>To download the MoM of 6th Senate Meeting</td>
                          <td>
                            <a href="https://drive.google.com/file/d/15nCzmSr7xjIW0yghXTp2vJ5pG1_-YAVd/view?usp=sharing">
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>To download the MoM of 5th Senate Meeting</td>
                          <td>
                            <a href="https://files.iiitkota.ac.in/pdf/senate/5th%20Senate%20MoM.pdf">
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>To download the MoM of 4th Senate Meeting</td>
                          <td>
                            <a href="https://files.iiitkota.ac.in/pdf/senate/4th%20Senate%20MoM.pdf">
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>To download the MoM of 3rd Senate Meeting</td>
                          <td>
                            <a href="https://files.iiitkota.ac.in/pdf/senate/3rd%20Senate%20MoM.pdf">
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>To download the MoM of 2nd Senate Meeting</td>
                          <td>
                            <a href="https://files.iiitkota.ac.in/pdf/senate/2nd%20Senate%20MoM.pdf">
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                        <tr className="body_tr">
                        <td><ul><li></li></ul></td>
                          <td>To download the MoM of 1st Senate Meeting</td>
                          <td>
                            <a href="https://files.iiitkota.ac.in/pdf/senate/1st%20Senate%20MoM.pdf">
                              <b>Click Here</b>
                            </a>
                          </td>
                        </tr>
                      </tbody>
                    </h6>
                  </Table>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Senate;
