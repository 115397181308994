import React from "react";
import "views/IndexSections/Sections.css";
import { Button, Card, Container, Row, Col } from "reactstrap";
import Bounce from "react-reveal/Bounce";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
class Webmaster extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Webmasters - Indian Institute of Information Technology, Kota
          </title>
          <meta
            name="description"
            content="Webmasters - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="Webmasters, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota, Gaurav Parmar, thegauravparmar, Omkar Deshmukh, Parth Mangal, Mayank Goyal, Pranjal Agarwal, 2019kucp1026, 1029kucp1019, 2019kucp1009, 2018kucp1004, 2018kucp1127"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <Bounce bottom>
                    <h2>
                      <b>Convener</b>
                    </h2>
                  </Bounce>
                  {/* <p className="lead text-muted">
                    According to the National Oceanic and Atmospheric
                    Administration, Ted, Scambos, NSIDClead scentist, puts the
                    potentially record maximum.
                  </p> */}
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="mb-5 mb-lg-0 mt-5" lg="3" md="6">
                  <Bounce bottom delay={500}>
                    <div className="px-4">
                      <img
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/people/webmasters/ajay_nehra.webp")}
                        style={{ width: "200px" }}
                      />
                      <div className="pt-4 text-center">
                        <h5 className="title">
                          <span className="d-block mb-1">Ajay Nehra</span>
                          <small className="h6 text-muted">
                            Assistant Professor Department of CSE
                          </small>
                        </h5>
                        <div className="mt-3">
                          <Button
                            className="btn-icon-only rounded-circle theme-color-kota-bg"
                            href="https://www.linkedin.com/in/ajay-nehra-67152368/"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="mailto:ajay.cse@iiitkota.ac.in"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-envelope"></i>
                            {/* </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href=""
                          // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-github" /> */}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Bounce>
                </Col>
              </Row>

              <Row className="justify-content-center text-center mb-5 mt-8">
                <Col lg="8">
                  <Bounce bottom>
                    <h2>
                      <b>Web Masters</b>
                    </h2>
                  </Bounce>
                  {/* <p className="lead text-muted">
                    According to the National Oceanic and Atmospheric
                    Administration, Ted, Scambos, NSIDClead scentist, puts the
                    potentially record maximum.
                  </p> */}
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col className="mb-5 mb-lg-0 mt-5" lg="3" md="6">
                  <Bounce bottom delay={500}>
                    <div className="px-4">
                      <img
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/people/webmasters/jayesh.jpeg")}
                        style={{ width: "200px" }}
                      />
                      <div className="pt-4 text-center">
                        <h5 className="title">
                          <span className="d-block mb-1">Jayesh Wankhede</span>
                          <small className="h6 text-muted">
                            BTech CSE IIITK'25
                          </small>
                        </h5>
                        <div className="mt-3">
                          <Button
                            className="btn-icon-only rounded-circle theme-color-kota-bg"
                            href="https://www.linkedin.com/in/jayesh-wankhede-5b494a22a/"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="mailto:2021kucp1069@iiitkota.ac.in"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-envelope"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="https://github.com/jayeshw28"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-github" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Bounce>
                </Col>

                <Col className="mb-5 mb-lg-0 mt-5" lg="3" md="6">
                  <Bounce bottom delay={500}>
                    <div className="px-4">
                      <img
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/people/webmasters/aditya.jpeg")}
                        style={{ width: "200px" }}
                      />
                      <div className="pt-4 text-center">
                        <h5 className="title">
                          <span className="d-block mb-1">Aditya Nagalwade</span>
                          <small className="h6 text-muted">
                            BTech CSE IIITK'25
                          </small>
                        </h5>
                        <div className="mt-3">
                          <Button
                            className="btn-icon-only rounded-circle theme-color-kota-bg"
                            href="https://www.linkedin.com/in/adityanagalwade/"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="mailto:2021kucp1051@iiitkota.ac.in"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-envelope"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="https://github.com/Adi-2505"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-github" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Bounce>
                </Col>
                <Col className="mb-5 mb-lg-0 mt-5" lg="3" md="6">
                  <Bounce bottom delay={500}>
                    <div className="px-4">
                      <img
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/people/webmasters/kritansh.jpeg")}
                        style={{ width: "200px" }}
                      />
                      <div className="pt-4 text-center">
                        <h5 className="title">
                          <span className="d-block mb-1">kritansh Agarwal</span>
                          <small className="h6 text-muted">
                            BTech ECE IIITK'25
                          </small>
                        </h5>
                        <div className="mt-3">
                          <Button
                            className="btn-icon-only rounded-circle theme-color-kota-bg"
                            href="https://www.linkedin.com/in/kritansh-agarwal/"
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="mailto:2021kuec2007@iiitkota.ac.in"
                          >
                            <i className="fas fa-envelope"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="https://github.com/kritansh20ag"
                          >
                            <i className="fab fa-github" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Bounce>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="mb-5 mb-lg-0 mt-5" lg="3" md="6">
                  <Bounce bottom delay={500}>
                    <div className="px-4">
                      <img
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/people/webmasters/mayank2025.jpeg")}
                        style={{ width: "200px" }}
                      />
                      <div className="pt-4 text-center">
                        <h5 className="title">
                          <span className="d-block mb-1">Mayank Makkar</span>
                          <small className="h6 text-muted">
                            BTech ECE IIITK'25
                          </small>
                        </h5>
                        <div className="mt-3">
                          <Button
                            className="btn-icon-only rounded-circle theme-color-kota-bg"
                            href="https://www.linkedin.com/in/m-makkar/"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="mailto:2021kuec2046@iiitkota.ac.in"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-envelope"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="https://github.com/coder-de-coder"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-github" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Bounce>
                </Col>

                <Col className="mb-5 mb-lg-0 mt-5" lg="3" md="6">
                  <Bounce bottom delay={500}>
                    <div className="px-4">
                      <img
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/people/webmasters/pranav.jpeg")}
                        style={{ width: "200px" }}
                      />
                      <div className="pt-4 text-center">
                        <h5 className="title">
                          <span className="d-block mb-1">Pranav Malpani</span>
                          <small className="h6 text-muted">
                            BTech ECE IIITK'25
                          </small>
                        </h5>
                        <div className="mt-3">
                          <Button
                            className="btn-icon-only rounded-circle theme-color-kota-bg"
                            href="https://www.linkedin.com/in/pranav-malpani-868389239/"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="mailto:2021kuec2012@iiitkota.ac.in"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-envelope"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="https://github.com/malpani2003"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-github" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Bounce>
                </Col>
                <Col className="mb-5 mb-lg-0 mt-5" lg="3" md="6">
                  <Bounce bottom delay={500}>
                    <div className="px-4">
                      <img
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/people/webmasters/yogit.jpeg")}
                        style={{ width: "200px" }}
                      />
                      <div className="pt-4 text-center">
                        <h5 className="title">
                          <span className="d-block mb-1">Yogit Nainani</span>
                          <small className="h6 text-muted">
                            BTech CSE IIITK'25
                          </small>
                        </h5>
                        <div className="mt-3">
                          <Button
                            className="btn-icon-only rounded-circle theme-color-kota-bg"
                            href="https://www.linkedin.com/in/yogitnainani/"
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="mailto:2021kucp1004@iiitkota.ac.in"
                          >
                            <i className="fas fa-envelope"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="https://github.com/yogeyyy"
                          >
                            <i className="fab fa-github" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Bounce>
                </Col>
              </Row>
            </Container>

            <Container>
              <Row className="justify-content-center text-center mb-5 mt-8">
                <Col lg="8">
                  <Bounce bottom>
                    <h2>
                      <b>Hall of Fame</b>
                    </h2>
                  </Bounce>
                  {/* <p className="lead text-muted">
                    According to the National Oceanic and Atmospheric
                    Administration, Ted, Scambos, NSIDClead scentist, puts the
                    potentially record maximum.
                  </p> */}
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col className="mb-5 mb-lg-0 mt-5" lg="3" md="6">
                  <Bounce bottom delay={500}>
                    <div className="px-4">
                      <img
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/people/webmasters/ankur.jpeg")}
                        style={{ width: "200px" }}
                      />
                      <div className="pt-4 text-center">
                        <h5 className="title">
                          <span className="d-block mb-1">Ankur Agarwal</span>
                          <small className="h6 text-muted">
                            BTech CSE IIITK'24
                          </small>
                        </h5>
                        <div className="mt-3">
                          <Button
                            className="btn-icon-only rounded-circle theme-color-kota-bg"
                            href="https://www.linkedin.com/in/ankur-8"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="mailto:2020kucp1096@iiitkota.ac.in"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-envelope"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="https://github.com/Ankur6702"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-github" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Bounce>
                </Col>

                <Col className="mb-5 mb-lg-0 mt-5" lg="3" md="6">
                  <Bounce bottom delay={500}>
                    <div className="px-4">
                      <img
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/people/webmasters/shiv.jpeg")}
                        style={{ width: "200px" }}
                      />
                      <div className="pt-4 text-center">
                        <h5 className="title">
                          <span className="d-block mb-1">Shivanshu Panwar</span>
                          <small className="h6 text-muted">
                            BTech ECE IIITK'24
                          </small>
                        </h5>
                        <div className="mt-3">
                          <Button
                            className="btn-icon-only rounded-circle theme-color-kota-bg"
                            href="https://www.linkedin.com/in/shivanshu-panwar-16b00820b"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="mailto:2020kuec2005@iiitkota.ac.in"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-envelope"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="https://github.com/SHIVSB"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-github" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Bounce>
                </Col>
                <Col className="mb-5 mb-lg-0 mt-5" lg="3" md="6">
                  <Bounce bottom delay={500}>
                    <div className="px-4">
                      <img
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/people/webmasters/rajat.jpeg")}
                        style={{ width: "200px" }}
                      />
                      <div className="pt-4 text-center">
                        <h5 className="title">
                          <span className="d-block mb-1"> Rajat Singh</span>
                          <small className="h6 text-muted">
                            BTech CSE IIITK'24
                          </small>
                        </h5>
                        <div className="mt-3">
                          <Button
                            className="btn-icon-only rounded-circle theme-color-kota-bg"
                            href="https://www.linkedin.com/in/rajat1064"
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="mailto:2020kucp1064@iiitkota.ac.in"
                          >
                            <i className="fas fa-envelope"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="https://github.com/con-artist"
                          >
                            <i className="fab fa-github" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Bounce>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="mb-5 mb-lg-0 mt-5" lg="3" md="6">
                  <Bounce bottom delay={500}>
                    <div className="px-4">
                      <img
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/people/webmasters/gaurav.png")}
                        style={{ width: "200px" }}
                      />
                      <div className="pt-4 text-center">
                        <h5 className="title">
                          <span className="d-block mb-1">
                            <a
                              href="https://thegauravparmar.com"
                              style={{ color: "#32325d" }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Gaurav Parmar
                            </a>
                          </span>
                          <small className="h6 text-muted">
                            BTech CSE IIITK'23
                          </small>
                        </h5>
                        <div className="mt-3">
                          <Button
                            className="btn-icon-only rounded-circle theme-color-kota-bg"
                            href="https://www.linkedin.com/in/thegauravparmar/"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="mailto:2019kucp1026@iiitkota.ac.in"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-envelope"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="https://github.com/thegauravparmar"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-github" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Bounce>
                </Col>

                <Col className="mb-5 mb-lg-0 mt-5" lg="3" md="6">
                  <Bounce bottom delay={600}>
                    <div className="px-4">
                      <img
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/people/webmasters/omkar.jpg")}
                        style={{ width: "200px" }}
                      />
                      <div className="pt-4 text-center">
                        <h5 className="title">
                          <span className="d-block mb-1">Omkar Deshmukh</span>
                          <small className="h6 text-muted">
                            BTech CSE IIITK'23
                          </small>
                        </h5>
                        <div className="mt-3">
                          <Button
                            className="btn-icon-only rounded-circle theme-color-kota-bg"
                            href="https://www.linkedin.com/in/omkarvd/"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="mailto:2019kucp1009@iiitkota.ac.in"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-envelope"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="https://github.com/H4ckOm"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-github" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Bounce>
                </Col>
                <Col className="mb-5 mb-lg-0 mt-5" lg="3" md="6">
                  <Bounce bottom delay={700}>
                    <div className="px-4">
                      <img
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/people/webmasters/parth.jpg")}
                        style={{ width: "200px" }}
                      />
                      <div className="pt-4 text-center">
                        <h5 className="title">
                          <span className="d-block mb-1">Parth Mangal</span>
                          <small className="h6 text-muted">
                            BTech CSE IIITK'23
                          </small>
                        </h5>
                        <div className="mt-3">
                          <Button
                            className="btn-icon-only rounded-circle theme-color-kota-bg "
                            href="https://www.linkedin.com/in/parthmangal/"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="mailto:2019kucp1019@iiitkota.ac.in"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-envelope"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="https://github.com/parth1019"
                            // onClick={(e) => e.preventDefault()}
                          >
                            <i className="fab fa-github" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Bounce>
                </Col>
              </Row>
              <Row className="justify-content-center mt-5">
                <Col className="mb-5 mb-lg-0 " lg="3" md="6">
                  <Bounce bottom delay={300}>
                    <div className="px-4">
                      <img
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/people/webmasters/mayank.jpeg")}
                        style={{ width: "200px" }}
                      />
                      <div className="pt-4 text-center">
                        <h5 className="title">
                          <span className="d-block mb-1">
                            <a
                              href="https://www.heymayank.com/"
                              style={{ color: "#32325d" }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Mayank Goyal
                            </a>
                          </span>
                          <small className="h6 text-muted">
                            BTech CSE IIITK'22
                          </small>
                        </h5>
                        <div className="mt-3">
                          <Button
                            className="btn-icon-only rounded-circle theme-color-kota-bg"
                            href="https://www.linkedin.com/in/mayankkgoyal/"
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="mailto:2018kucp1004@iiitkota.ac.in"
                          >
                            <i className="fas fa-envelope"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="https://github.com/heromayank2"
                          >
                            <i className="fab fa-github" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Bounce>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <Bounce bottom delay={400}>
                    <div className="px-4">
                      <img
                        alt="..."
                        className="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                        src={require("assets/img/people/webmasters/pranjal.jpeg")}
                        style={{ width: "200px" }}
                      />
                      <div className="pt-4 text-center">
                        <h5 className="title">
                          <span className="d-block mb-1">
                            {" "}
                            <a
                              href="https://pranjalagrawal.me/"
                              style={{ color: "#32325d" }}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Pranjal Agrawal
                            </a>
                          </span>
                          <small className="h6 text-muted">
                            BTech CSE IIITK'22
                          </small>
                        </h5>
                        <div className="mt-3">
                          <Button
                            className="btn-icon-only rounded-circle theme-color-kota-bg"
                            href="https://www.linkedin.com/in/pranjal85/"
                          >
                            <i className="fab fa-linkedin-in"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="mailto:2018kucp1127@iiitkota.ac.in"
                          >
                            <i className="fas fa-envelope"></i>
                          </Button>
                          <Button
                            className="btn-icon-only rounded-circle ml-1 theme-color-kota-bg"
                            href="https://github.com/pranjal1127"
                          >
                            <i className="fab fa-github" />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Bounce>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-lg pt-0">
            <Container>
              <Bounce bottom delay={1000}>
                <Card className="bg-gradient-warning shadow-lg border-0">
                  <div className="p-5">
                    <Row className="align-items-center justify-content-center">
                      <Col lg="8">
                        <h3 className="text-white">You Think! We Create!!</h3>
                        <p className="lead text-white mt-3">
                          Want to connect with Us? Check Our Social Handles
                          Above
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Bounce>
            </Container>
          </section>
          {/* <Download /> */}
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Webmaster;
