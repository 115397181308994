import React from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";


class Trai extends React.Component {
    state = {};
    componentDidMount() {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.main.scrollTop = 0;
    }
    render() {
        return (
            <>
                <Helmet>
                    <title>
                        TRAI Lab - Indian Institute of Information Technology Kota
                    </title>
                    <meta
                        name="description"
                        content="TRAI Lab Indian Institute of Information Technology Kota"
                    />
                    <meta
                        name="keywords"
                        content="TRAI Lab, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota, Trusted Robotics and Artificial Intelligence Lab"
                    />
                    <meta name="author" content="Author name" />
                </Helmet>
                <DemoNavbar />
                <main ref="main">
                    <div className="position-relative">
                        {/* shape Hero */}
                        <section className="section  section-shaped pb-200">
                            <div className="shape shape-style-1 shape-default bg-gradient-default">
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                                <span />
                            </div>

                            <div className="separator separator-bottom separator-skew">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    preserveAspectRatio="none"
                                    version="1.1"
                                    viewBox="0 0 2560 100"
                                    x="0"
                                    y="0"
                                >
                                    <polygon
                                        className="fill-white"
                                        points="0 0 0 0 0"
                                    />
                                </svg>
                            </div>
                        </section>
                    </div>
                    <section className="section section-lg">
                        <Container>
                            <Row className="justify-content-center text-center mb-5">
                                <Col lg="8">
                                    <h2>
                                        <b>TRAI (Trusted Robotics and Artificial Intelligence) Lab</b>
                                    </h2>
                                </Col>
                            </Row>
                            <Row className="justify-content-center mb-5">
                                <Col lg={12}>
                                    <h6>
                                        IIIT Kota has taken a leap towards the innovation by introducing its
                                        new research lab namely TRAI (Trusted Robotics and Artificial
                                        Intelligence) Lab. The TRAI lab is setup for the advancement in AI
                                        and Robotics research domain. The team members of the lab are working
                                        in the domain of ground robotics, aerial robotics and assistive
                                        devices for disabled persons.
                                    </h6>
                                </Col>
                                <Col lg={12}>
                                    <h6>
                                        The objectives are to develop and inspire bright young minds of IIIT Kota
                                        to incorporate learning through state-of-the-art technological advancements.
                                        Interested students will be given the opportunity to explore and learn
                                        advanced robotics and AI concepts to solve real-life problems and can contribute
                                        to the ongoing research. UG, PG and PhD students and faculty members of IIIT
                                        Kota are currently working on a series of research projects whose details are provided below:
                                    </h6>
                                </Col>
                                <Col className="mt-5" lg={12}>
                                    <h4>
                                        <b>Ongoing Projects:-</b>
                                    </h4>
                                </Col>
                                <Col className="mt-2" lg={12}>
                                    <h6>
                                        <ul>
                                            <li>
                                                <b>Developing a robotic arm for hand disabled to grasp and pick the user intended object</b>
                                                <h6>
                                                    A group of people is working on the development of a robotic arm which shall help the hand disabled persons in grasping and lifting the user desired object. A 3D model has been developed and hardware prototype is also fabricated using 3D printer. The developed arm shall work on computer-vision based deep learning techniques in combination with control system to reach and grasp the object. The intension of the user shall be extracted by processing the EEG signals. This arm shall be able to mimic human capabilities with state-of-the-art functionalities.
                                                </h6>
                                                <div className="mb-2 mt-5 text-center">
                                                    <img
                                                        src="https://files.iiitkota.ac.in/images/trai%20lab/robohand1.png"
                                                        width="300px"
                                                        height={400}
                                                        alt=""
                                                    />
                                                    <img
                                                        src="https://files.iiitkota.ac.in/images/trai%20lab/robohand2.jpg"
                                                        width="250px"
                                                        height={400}
                                                        alt=""
                                                    />
                                                </div>
                                            </li>
                                            <br />
                                            <li>
                                                <b>Design and Development of Autonomous Quadcopter for Delivery Purpose </b>
                                                <h6>
                                                    We are developing a GPS-based autonomous quadcopter to deliver things in remote areas. The proposed quadcopter shall work fully autonomously. It shall compute the path for the navigation, able to avoid static as well as dynamic obstacles during flight, packet dropping at the desired place and autonomously come back to the starting location.
                                                </h6>
                                                <div className="mb-2 mt-5 text-center">
                                                    <img
                                                        src="https://files.iiitkota.ac.in/images/trai%20lab/quadcopter.png"
                                                        width="650px"
                                                        height={400}
                                                        alt=""
                                                    />
                                                </div>
                                            </li>
                                        </ul>
                                    </h6>
                                </Col>
                            </Row>
                            <Row className="justify-content-center mb-2">
                                <Col className="mt-2" lg={12}>
                                    <h4>
                                        <b>Team:-</b>
                                    </h4>
                                </Col>
                            </Row>
                            <Row className="text-center justify-centent-center">
                                <Col lg={12}>
                                    <h4 className="  ">
                                        <b>Faculty Members</b>
                                    </h4>
                                </Col>
                            </Row>
                            <br />
                            <Row className="justify-content-center mb-5">
                                <Col lg="12">
                                    <Row className="row-grid  justify-content-center">
                                        <Col lg="4">
                                            <Card className="card-lift--hover shadow border-0">
                                                <CardBody>
                                                    <div className="mb-2 text-center">
                                                        {/* <i className="ni ni-check-bold" /> */}
                                                        <img
                                                            alt="Dr. Amit Kumar"
                                                            height="300px"
                                                            className="OfficeStaff-img"
                                                            src="https://files.iiitkota.ac.in/images/faculty/amitkumar.webp"
                                                        />
                                                    </div>
                                                    <div className="text-center">
                                                        <h6 className="text-primary ">
                                                            <b>Dr. Amit Kumar</b>
                                                        </h6>

                                                        <div className="px-2">
                                                            Assistant Professor
                                                            <br />
                                                            Department of CSE, IIIT Kota
                                                        </div>
                                                        <br />
                                                        <h6 className="text-center">
                                                            <a href="mailto:amit@iiitkota.ac.in">
                                                                {" "}
                                                                <i className="fa fa-envelope px-2" />
                                                                amit@iiitkota.ac.in
                                                            </a>{" "}
                                                        </h6>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col lg="4">
                                            <Card className="card-lift--hover shadow border-0">
                                                <CardBody>
                                                    <div className="mb-2 text-center">
                                                        {/* <i className="ni ni-check-bold" /> */}
                                                        <img
                                                            alt="Dr. Anupam Kumar"
                                                            height="300px"
                                                            className="OfficeStaff-img"
                                                            src="https://files.iiitkota.ac.in/images/faculty/anupam.webp"
                                                        />
                                                    </div>
                                                    <div className="text-center">
                                                        <h6 className="text-primary ">
                                                            <b>Dr. Anupam Kumar</b>
                                                        </h6>

                                                        <div className="px-2">
                                                            Assistant Professor
                                                            <br />
                                                            Department of ECE, NIT Patna
                                                        </div>
                                                        <br />
                                                        <h6 className="text-center">
                                                            <a href="mailto:anupam.ece@iiitkota.ac.in">
                                                                {" "}
                                                                <i className="fa fa-envelope px-2" />
                                                                anupam.ec@nitp.ac.in
                                                            </a>{" "}
                                                        </h6>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className="text-center justify-centent-center">
                                <Col lg={12}>
                                    <h4 className="  ">
                                        <b>Student Members</b>
                                    </h4>
                                </Col>
                            </Row>
                            <br />
                            <Row className="text-center justify-centent-center">
                                <Col lg={12}>
                                    <h6>

                                        <h6>
                                            Monika Vyas (Ph.D student)
                                        </h6>

                                        <h6>
                                            Divyansh Singh (B.Tech CSE student)
                                        </h6>

                                        <h6>
                                            Monish ES (B.Tech CSE student)
                                        </h6>

                                        <h6>
                                            Akhil Mahajan (B.Tech CSE student)
                                        </h6>

                                    </h6>
                                </Col>
                            </Row>
                        </Container>
                    </section>
                </main>
                <NewFooter />
            </>
        );
    }
}

export default Trai;
