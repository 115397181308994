import DemoNavbar from "components/Navbars/DemoNavbar";
import React from "react";
import { Container, Row, Col, CardBody, Card } from "reactstrap";
import Helmet from "react-helmet";
import NewFooter from "components/Footers/NewFooter";
import Table from "reactstrap/lib/Table";
class Procedure extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Admission Procedure - Indian Institute of Information Technology,
            Kota
          </title>
          <meta
            name="description"
            content="Admission Procedure - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="Admission Procedure iiit kota, josaa, admissions in iiitkota, councelling, cutoff iiitkota, csab,IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Offered Programs</b>
                  </h2>
                  <br />
                </Col>
              </Row>

              {/* <h3 style={{ "fontWeight": "900", "marginBottom": "1.5rem" }}>Offered Programs</h3> */}
              {/* <h6>
              <ul>
                <li>PostGraduate programs</li>
                <li>UnderGraduate programs</li>
              </ul>
            </h6> */}

              <Row className="mt-5 mb-1">
                <Col lg={12} xs={12}>
                  <h4 style={{ fontWeight: "900", marginBottom: "1.5rem" }}>
                    Undergraduate programs:
                  </h4>
                  {/* <h6>
                    <ul>
                      <li>Computer Science and Engineering (CSE)</li>
                      <li>Electronics and Communication Engineering (ECE)</li>
                    </ul>
                  </h6> */}
                </Col>
                <Col lg={12} xs={12}>
                  <h6>
                    The institute offers Bachelor of Technology (B.Tech.)
                    program in three streams – Computer Science and Engineering
                    (CSE), Electronics and Communication Engineering (ECE), and Artificial Intelligence and Data Engineering (AI&DE).
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col lg={12} xs={12} className="mt-0">
                  <Table bordered>
                    <h6 className="only-padding">
                      <thead>
                        <tr>
                          <th style={{ width: "202px" }}>Academic Session</th>
                          <th style={{ width: "505px" }}>Branch</th>
                          <th style={{ width: "303px" }}>Intake</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                            rowSpan="3"
                            className="text-center  "
                            style={{ verticalAlign: "middle" }}
                          >
                            2024-2025
                          </td>
                          <td>Computer Science and Engineering (CSE)</td>
                          <td>180</td>
                        </tr>
                        
                        <tr>
                          <td>
                            Electronics and Communication Engineering (ECE)
                          </td>

                          <td>90</td>
                        </tr>

                        <tr>
                          <td>
                          Artificial Intelligence and Data Engineering (AI&DE)
                          </td>

                          <td>60</td>
                        </tr>
                       

                      </tbody>
                    </h6>
                  </Table>
                </Col>
              </Row>

              <Row className="mt-5">
                <Col lg={12} xs={12}>
                  <h4 style={{ fontWeight: "900", marginBottom: "1.5rem" }}>
                    Postgraduate programs:
                  </h4>
                  {/* <h6>
                    <ul>
                      <li>Computer Science and Engineering (CSE)</li>
                      <li>Electronics and Communication Engineering (ECE)</li>
                    </ul>
                  </h6> */}
                </Col>
                <Col lg={12} xs={12}>
                  <h6>
                    The institute offers Master of Technology (M.Tech.) program
                    in one stream – Computer Science and Engineering (CSE).
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col lg={12} xs={12} className="mt-2">
                  <Table bordered>
                    <h6 className="only-padding">
                      <thead>
                        <tr>
                          <th style={{ width: "202px" }}>Academic Session</th>
                          <th style={{ width: "505px" }}>Branch</th>
                          <th style={{ width: "303px" }}>Intake</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td
                          
                            className="text-center  "
                            style={{ verticalAlign: "middle" }}
                          >
                            2024-2025
                          </td>
                          <td>
                            M.Tech. in CSE with specialization in Artifical
                            Intelligence and Data Science
                          </td>
                          <td>27</td>
                        </tr>
                       
                      </tbody>
                    </h6>
                  </Table>
                </Col>
              </Row>

              <Row className="mt-5">
                <Col lg={12} xs={12}>
                  <h6>
                    {/* <b> */}
                    The Institute offers Ph.D programme in following departments
                    :{/* </b> */}
                    <h6>
                      <ul>
                        <li>
                          Ph.D. in Department of Computer Science and
                          Engineering
                        </li>
                        <li>
                          Ph.D in Department of Electronics and Communications
                          Engineering
                        </li>
                      </ul>
                    </h6>
                  </h6>
                </Col>
              </Row>

              <Row className="justify-content-center text-center mt-6 mb-5">
                <Col lg="8">
                  <h2>
                    <b>Admission Procedure</b>
                  </h2>
                  <br />
                </Col>
              </Row>
              <Row className="row-grid text-center justify-content-center mt-5 mb-5">
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className="text-center justify-content-center">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 mt-2">
                        <a
                          href="/ug-procedure"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                      <h6>Undegraduate Programmes</h6>
                      <h6>
                        {" "}
                        <a
                          href="/ug-procedure"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Procedure
                        </a>
                      </h6>
                      <br />
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0 ">
                    <CardBody className="text-center justify-content-center">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 mt-2">
                        <a
                          href="/pg-procedure"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>
                      <h6>Postgraduate Programmes</h6>
                      <h6>
                        {" "}
                        <a
                          href="/pg-procedure"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Procedure
                        </a>
                      </h6>
                      <br />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Procedure;
