import React from "react";
import { Card, CardBody, Container, Row, Col, Table } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import "bootstrap";
import "assets/css/style.css";
import Helmet from "react-helmet";
import {Link} from "react-router-dom";

class Rti extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (

      <>
        <Helmet>
          <title>RTI - Indian Institute of Information Technology Kota</title>
          <meta name="description" content="RTI Page of IIIT Kota" />
          <meta
            name="keywords"
            content="RTI, IIIT Kota RTI, RTI File of IIIT Kota, Indian Institute of Information Technology Kota RTI"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>RTI</b>
                  </h2>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row className="mb-5 ">
                <Col lg={12} xs={12}>
                  <h4>
                    <b>RTI Information</b>
                  </h4>
                </Col>
                <Col lg={12} xs={12} className="text-justify">
                  <h6>
                    Right to Information (RTI) is an Act of the Indian
                    Parliament enforced from the year 2005, to provide for a
                    practical regime for citizens to secure access to
                    information under the control of public authorities. This
                    aims to promote transparency and accountability in the
                    working of every public authority.
                  </h6>
                </Col>
              </Row>

              <Row className="mb-5 ">
                <Col lg="6">
                  <h4>
                    <b>First Appellate Authority</b>
                  </h4>
                  <Card className="card-lift--hover shadow border-0 mt-6">
                    <CardBody className="py-5">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 text-primary">
                        <i className="fas fa-address-book" />
                      </div>
                      <h6 className="text-primary text-uppercase">
                        <b>Prof. Rohit Bhakar</b>
                        <br />
                      </h6>
                      <div className="px-2 pb-2">
                        Registrar
                        <br />
                        Prabha Bhavan, MNIT Campus, J L N Marg, Jaipur
                        <br />
                        IIIT Kota
                      </div>

                      <h6>
                        <a href="mailto:jainarian@mnit.ac.in">
                          <i className="fa fa-envelope px-2" />
                          registrar@iiitkota.ac.in
                        </a>
                      </h6>

                      <h6>
                        <a href="tel:01412713204">
                          <i className="fas fa-phone-square-alt px-2" />
                          0141-2713204
                        </a>
                      </h6>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="6">
                  <h4>
                    <b>Nodal Officer cum Central Public Information Officer</b>
                  </h4>
                  <Card className="card-lift--hover shadow border-0 mt-5">
                    <CardBody className="py-5">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 text-primary">
                        <i className="fas fa-address-book" />
                      </div>
                      <h6 className="text-primary text-uppercase">
                        <b>Shri Raj Kumar Manjhiwal</b>
                        <br />
                      </h6>
                      <div className="px-2 pb-2">
                        Deputy Registrar (Establishment)
                        <br />
                        Indian Institute of Information Technology, Kota
                        <br />
                        Prabha Bhavan, MNIT
                      </div>

                      <h6>
                        <a href="mailto:office@iiitkota.ac.in">
                          <i className="fa fa-envelope px-2" />
                          office@iiitkota.ac.in
                        </a>
                      </h6>

                      <h6>
                        <a href="tel:01412715071">
                          <i className="fas fa-phone-square-alt px-2" />
                          0141-2715071
                        </a>
                      </h6>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="justify-content-center text mb-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg={12} xs={12} className="text-justify">
                      <Table borderless>
                        <tbody>
                          <tr>
                            <th>
                              <h6>
                                <b>1.</b>
                              </h6>
                            </th>
                            <td>
                              <h6>For the more information about RTI please</h6>
                            </td>
                            <td>
                              <h6>
                                {" "}
                                <a href="https://files.iiitkota.ac.in/pdf/rti/RTI Information.pdf">
                                  {" "}
                                  Click Here
                                </a>
                              </h6>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <h6>
                                <b>2.</b>
                              </h6>
                            </th>
                            <td>
                              <h6>
                                {" "}
                                For the Actuals 2016-2017, Budget estimation
                                2017-18 and 2018-19 please
                              </h6>
                            </td>
                            <td>
                              <h6>
                                {" "}
                                <a href="https://files.iiitkota.ac.in/pdf/rti/budget_2018-19.pdf">
                                  {" "}
                                  Click Here
                                </a>
                              </h6>
                            </td>
                          </tr>{" "}
                          <tr>
                            <th>
                              <h6>
                                <b>3.</b>
                              </h6>
                            </th>
                            <td>
                              <h6>
                                {" "}
                                For the Memorandum of Association (MoA) please
                              </h6>
                            </td>
                            <td>
                              <h6>
                                {" "}
                                <a href="https://files.iiitkota.ac.in/pdf/rti/MOA.pdf">
                                  {" "}
                                  Click Here
                                </a>
                              </h6>
                            </td>
                          </tr>{" "}
                          <tr>
                            <th>
                              <h6>
                                <b>4.</b>
                              </h6>
                            </th>
                            <td>
                              <h6>
                                {" "}
                                For Statement showing the year wise details of
                                fund relea sed by various partners please
                              </h6>
                            </td>
                            <td>
                              <h6>
                                {" "}
                                <a href="https://files.iiitkota.ac.in/pdf/rti/Industry-Partners.pdf">
                                  {" "}
                                  Click Here
                                </a>
                              </h6>
                            </td>
                          </tr>{" "}
                          <tr>
                            <th>
                              <h6>
                                <b>5.</b>
                              </h6>
                            </th>
                            <td>
                              <h6> For the details of Annual Report please</h6>
                            </td>
                            <td>
                              <h6>
                                {" "}
                                <Link to="/annual-reports"> Click Here</Link>
                              </h6>
                            </td>
                          </tr>
                          <tr>
                            <th>
                              <h6>
                                <b>6.</b>
                              </h6>
                            </th>
                            <td>
                              <h6>
                                {" "}
                                For the details of Minutes of the 1st meeting of
                                the Coordination Forum of IIIT(PPP) please
                              </h6>
                            </td>
                            <td>
                              <h6>
                                {" "}
                                <a href="https://files.iiitkota.ac.in/pdf/rti/Minutes of the 1st meeting of the Cordination Forum.pdf">
                                  {" "}
                                  Click Here
                                </a>
                              </h6>
                            </td>
                          </tr>

                          <tr>
                            <th>
                              <h6>
                                <b>7.</b>
                              </h6>
                            </th>
                            <td>
                              <h6>
                                {" "}
                                Suo Moto Discloures/RTI Manuals of IIIT Kota
                              </h6>
                            </td>
                            <td>
                              <h6>
                                
                                <a href="https://files.iiitkota.ac.in/pdf/rti/Suo Moto RTI Audit_IIIT_Kota 20.09.2024.pdf">
                                  {" "}
                                  English /
                                
                                </a>
                             
                             
                                <a href="https://files.iiitkota.ac.in/pdf/rti/Hindi Version _ Suo Moto RTI Audit_IIIT_Kota.pdf">
                                  {" "}
                                Hindi
                                
                                </a>
                              </h6>
                            </td>
                          </tr>

                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <Container lg={12} xs={12} className="kota-table">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Scholarship/Scheme Name </th>
                    <th scope="col">No. of benefited students </th>
                    <th scope="col">Total Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>
                      <b>1.</b>
                    </th>
                    <td>Merit cum Need based Scholarship</td>
                    <td>08</td>
                    <td>Rs. 1,20,000</td>
                  </tr>
                  <tr>
                    <th>
                      <b>2.</b>
                    </th>
                    <td>
                      <h6>Fee Waiver</h6>{" "}
                    </td>
                    <td>04</td>
                    <td>---</td>
                  </tr>
                </tbody>
              </table>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Rti;
