import React from "react";
// import WorkItem from './WorkItem';

export default function Other(props) {
  const getWorkExperience = () => {
    const { data } = props;
    const workItems = [];
    const volunteer = data.volunteer;
    volunteer &&
      volunteer.forEach((val, index) => {
        workItems.push(
          <div className="workItem">
            <h3 className="h3">
              {val.position}, <span>{val.organization}</span>
            </h3>
            <p className="workDates">
              <span className="startdate">
                {val.startDate} - {val.endDate}
              </span>
            </p>
            <p>{val.summary}</p>
            <ul className="ul">
              {val.highlights &&
                val.highlights.map(function (item, index) {
                  return (
                    <li
                      key={index}
                      style={{ listStyleType: "square", marginLeft: "2rem" }}
                    >
                      {" "}
                      {item}
                    </li>
                  );
                })}
            </ul>
          </div>
        );
      });
    return workItems;
  };

  const getInterest = () => {
    const { data } = props;
    const interests = data.interests;
    return interests
      ? interests.map((ele) => (
          <>
            <h3 className="h3">{ele.name}</h3>
            <ul className="ul skills-list">
              {ele.keywords
                ? ele.keywords.map(function (item, index) {
                    return (
                      <li key={index}>
                        <span className="label label-primary">{item}</span>
                      </li>
                    );
                  })
                : null}
            </ul>
          </>
        ))
      : [];
  };

  const getAchievement = () => {
    return (
      props.data.awards &&
      props.data.awards.map((ele) => (
        <>
          <h3 className="h3">
            {ele.title}, <span className="text-small">{ele?.awarder}</span>
          </h3>
          <p className="workDates">{ele?.date}</p>
          <p>{ele?.summary}</p>
        </>
      ))
    );
  };

  const getPublication = () => {
    return (
      props.data.publications &&
      props.data.publications.map((ele) => (
        <>
          <h3 className="h3">
            {ele.title} &nbsp;{" "}
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a className="fa fa-link" target="_blank" rel="noopener noreferrer" href={ele?.link}>
              {" "}
            </a>
          </h3>
          <p>{ele?.details}</p>
        </>
      ))
    );
  };

  return (
    <div>
      {props.data?.volunteer && (
        <section
          className="section work"
          style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}
        >
          <h2 className="text-uppercase h2">
            <i className="fa fa-lg fa-handshake-o"></i> Volunteer
          </h2>
          {getWorkExperience()}
        </section>
      )}

      {props.data?.publications ? (
        <section
          className="section work"
          style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}
        >
          <h2 className="text-uppercase h2">
            <i className="fas fa-lg fa-newspaper"></i> Projects or Publications
          </h2>
          {getPublication()}
        </section>
      ) : null}
        {((getAchievement()?.trim() || '') !== '') && (
            <section
                className="section work"
                style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}
            >
                <h2 className="text-uppercase h2">
                    <i className="fa fa-lg fa-trophy"></i> Achievements{" "}
                </h2>
                {props.data.awards ? getAchievement() : null}
            </section>
        )}
        {(getInterest()?.trim() || '') !== '' && (
            <section
                className="section work"
                style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}
            >
                <h2 className="text-uppercase h2">
                    <i className="fa fa-lg fa-heart"></i> INTERESTS
                </h2>
                {getInterest()}
            </section>
        )}
    </div>
  );
}
