import React from "react";
import { Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
class Sitemap extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Sitemap - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Sitemap - Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Sitemap, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
          <meta name="author" content="Mr. Satya Narayan Sharma" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2 className="display-3">Sitemap</h2>
                </Col>
              </Row>
            </Container>
            <Container>
              <Row>
                <Col>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/achievements">Achievements</Link>
                    </li>
                    <li>
                      <Link to="/administration">Administration</Link>
                    </li>
                    <li>
                      <Link to="/announcement">Announcement</Link>
                    </li>
                    <li>
                      <Link to="/annual-reports">Annual Reports</Link>
                    </li>
                    <li>
                      <Link to="/building-work-committee">
                        Building & Work Committee
                      </Link>
                    </li>
                    <li>
                      <Link to="/calender">Calender</Link>
                    </li>
                    <li>
                      <Link to="/campus">Campus</Link>
                    </li>
                    <li>
                      <Link to="/canteen">Canteen</Link>
                    </li>
                    <li>
                      <Link to="/clubs">Clubs</Link>
                    </li>
                    <li>
                      <Link to="/committees">Committees</Link>
                    </li>
                    <li>
                      <Link to="/computer-labs">Computer-labs</Link>
                    </li>
                    <li>
                      <Link to="/courses">Courses</Link>
                    </li>
                    <li>
                      <Link to="/curriculam">Curriculam</Link>
                    </li>
                    <li>
                      <Link to="/department">Department</Link>
                    </li>
                    <li>
                      <Link to="/director">Director</Link>
                    </li>
                    <li>
                      <Link to="/events">Events</Link>
                    </li>
                    <li>
                      <Link to="/examination-evaluation">
                        Examination Evaluation
                      </Link>
                    </li>
                    <li>
                      <Link to="/executive-committee">Executive Committee</Link>
                    </li>
                    <li>
                      <Link to="/faculty">Faculty</Link>
                    </li>
                    <li>
                      <Link to="/fees">Fees</Link>
                    </li>
                    <li>
                      <Link to="/fests">Fests</Link>
                    </li>
                    <li>
                      <Link to="/finance-committee">Finance Committee</Link>
                    </li>
                    <li>
                      <Link to="/gallery">Gallery</Link>
                    </li>
                    <li>
                      <Link to="/holidays">Holidays</Link>
                    </li>
                    <li>
                      <Link to="/hostels">Hostels</Link>
                    </li>
                    <li>
                      <Link to="/iccw">Iccw</Link>
                    </li>
                    <li>
                      <Link to="/it-infrastructure">It Infrastructure</Link>
                    </li>
                    <li>
                      <Link to="/library">Library</Link>
                    </li>
                    <li>
                      <Link to="/office-staff">Office Staff</Link>
                    </li>
                    <li>
                      <Link to="/ordinance">Ordinance</Link>
                    </li>
                    <li>
                      <Link to="/partnership">Partnership</Link>
                    </li>
                    <li>
                      <a href="https://tpcell.iiitkota.ac.in/">
                        Placement Statistics
                      </a>
                    </li>
                    <li>
                      <Link to="/procedure">Procedure</Link>
                    </li>
                    <li>
                      <Link to="/recruitment">Recruitment</Link>
                    </li>
                    <li>
                      <Link to="/rti">RTI</Link>
                    </li>
                    <li>
                      <Link to="/rules-desciplines">Rules & Desciplines</Link>
                    </li>
                    <li>
                      <Link to="/scholarship">Scholarship</Link>
                    </li>
                    <li>
                      <Link to="/senate">Senate</Link>
                    </li>
                    <li>
                      <Link to="/sports">Sports</Link>
                    </li>
                    <li>
                      <Link to="/statues">Statues</Link>
                    </li>
                    <li>
                      <Link to="/tender-invitations">Tender Invitations</Link>
                    </li>
                    <li>
                      <Link to="/time-table">Time Table</Link>
                    </li>
                    <li>
                      <Link to="/webmasters">Webmasters</Link>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Sitemap;
