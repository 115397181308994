import React, { useEffect } from "react";
import "assets/css/style.css";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
import { Container, Row, Col } from "reactstrap";

const DirectorsMessage = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <>
      <Helmet>
        <title>
          Director's Message - Indian Institute of Information Technology, Kota
        </title>
        <meta
          name="description"
          content="Director's Message - Indian Institute of Information Technology, Kota"
        />
        <meta
          name="keywords"
          content="Director's Message, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota, PPP mode, PPP act, Director, MNIT, Prof. Narayan Prasad Padhy"
        />
      </Helmet>
      <DemoNavbar />
      <div className="position-relative">
        <section className="section  section-shaped pb-200">
          <div className="shape shape-style-1 shape-default bg-gradient-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>

          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="0 0 0 0 0 0" />
            </svg>
            {/* <div className="box-head">
              <div className="message-head"></div>
            </div> */}
          </div>
        </section>
      </div>
      <section className="section section-lg">
        <Container>
          <Row className="justify-content-center text-center mb-5">
            <Col lg="8">
              <h2>
                <b>Director's Message</b>
              </h2>
            </Col>
          </Row>

          <Container className="message-body shadow rounded">
            <div id="dir-img">
              <img
                src={require("assets/img/people/dir_final.jpg")}
                alt=""
                className="directors-img"
              ></img>
            </div>
            <Col lg={12} xs={12}>
              <h5>
                <b>
                  Welcome to Indian Institute of Information Technology, Kota.
                </b>
              </h5>
            </Col>
            <Col lg={12} className="mt-3" xs={12}>
              <h6>
                Since its inception, in 2013, IIIT Kota has accomplished many
                milestones and covered an illustrious journey in last 8 years.
                As one of the Premier Institutes, that is a joint venture of the 
                <b> Ministry of Education</b> and <b>Government of Rajasthan</b> with 4 strong
                Industry Partners in Public-Private Partnership (PPP) mode, the
                institute has come a long way.<br /><br /> <b>IIIT Kota is one of the
                Institutions of National Importance.</b> IIIT Kota is mentored by
                MNIT Jaipur and is provided with all necessary infrastructural
                and academic support. The teaching is conducted in
                state-of–the-art Lecture theatres and practical exposure is
                provided to the students through highly advanced laboratories.<br /><br />
                IIIT Kota has emerged as a perfect centre for imparting
                technical education at par with international standards. Great
                impetus is laid on developing research in core areas. The
                institute offers programmes like B.Tech, M Tech and Ph.D.<br /><br />
                Focused industry interactions and invited talks have further
                accelerated dissemination of education as per the needs of
                recent trends and developments.Further, IIIT Kota is making
                significant progress in the areas of research and consultancy.<br /><br />
                The Institute has received a number of sponsored projects from
                various funding agencies like DST, DSIR, YNSPEED and consultancy
                from industries worth more than Rupees 63 lakhs.<br /><br /> Faculties of
                IIIT Kota have contributed in creation of new knowledge by
                publishing 170+ original research paper in top tier journals and
                conferences. Some of these are highly cited by other
                researchers. Few of our faculty members have been awarded 3
                patents in the past couple of years. <br /><br />IIIT Kota provides a highly conducive environment
                to its students for enhancing their technical and academic
                skills. Being a part of this institute will definitely provide
                lot of opportunities for grooming overall personality. <br /><br />I welcome
                you all to come and join this premier institute so that you can
                embark on a journey of lifelong learning.<br /><br />
                <b>Prof. Narayan Prasad Padhy</b>
                <br />
                <b>Mentor Director, IIIT Kota</b>
              </h6>
            </Col>
          </Container>
        </Container>
      </section>
      <NewFooter />
    </>
  );
};

export default DirectorsMessage;
