import React, { useEffect } from "react";
import "assets/css/style.css";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
import { Container, Row, Col } from "reactstrap";

const AboutIIIT = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>
          About IIIT Kota - Indian Institute of Information Technology, Kota
        </title>
        <meta
          name="description"
          content="About IIIT Kota - Indian Institute of Information Technology, Kota"
        />
        <meta
          name="keywords"
          content="About IIIT Kota, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota, PPP mode, PPP act, Kota"
        />
      </Helmet>
      <DemoNavbar />
      <div className="position-relative">
        <section className="section  section-shaped pb-200">
          <div className="shape shape-style-1 shape-default bg-gradient-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>

          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon className="fill-white" points="0 0 0 0 0 0" />
            </svg>
          </div>
        </section>
      </div>
      <section className="section section-lg">
        <Container>
          <Row className="justify-content-center text-center mb-5">
            <Col lg="8">
              <h2>
                <b>ABOUT IIIT KOTA</b>
              </h2>
            </Col>
          </Row>

          <Container className="message-body shadow rounded">
            <div id="dir-img">
              <img
                src={require("assets/img/campus_plan/Admin_and_Academic_Block.jpg")}
                alt=""
                className="directors-img"
              ></img>
            </div>
            <Col lg={12} xs={12}>
              <h5>
                <b>IIIT KOTA</b>
              </h5>
            </Col>
            <Col lg={12} className="mt-3" xs={12}>
              <h6>
                Indian Institute of Information Technology, Kota (IIIT, Kota) is
                an institute of national importance. IIIT Kota is a joint
                venture of the Ministry of Education (MoE) Govt. of India and
                Govt. of Rajasthan with Industry Partners in
                Public-Private-Partnership (PPP) model. IIIT Kota was
                established in 2013 and it started operating from its temporary
                campus in Prabha Bhawan of MNIT in 2013. Currently, the
                institute is mentored by Malaviya National Institute of
                Technology Jaipur (MNIT Jaipur)<br />
                <br /> The permanent campus of IIIT, Kota is being
                constructed at Ranpur near Kota on 100.37 acres of land made
                available by the Govt. of Rajasthan. The construction of Phase-1
                and Phase 2 of the campus is going on in full swing. <br />
                <br />
                The institute is totally dedicated towards imparting excellent research
                and industry –oriented education to the future technocrats of
                the country. The teaching and learning process at IIIT Kota are
                inspired by the system followed in leading national level
                institutes .The teaching pedagogy is based on real-life
                practical exposure along with strong conceptual learning.
                <br />
                <br />
              </h6>
            </Col>
            <Col lg={12} xs={12}>
              <h5>
                <b>BACKGROUND</b>
              </h5>
            </Col>
            <Col lg={12} className="mt-3" xs={12}>
              <h6>
                IIIT Kota started its journey with a sanctioned intake of 30
                students in B.Tech.(Computer Engineering) during the Academic
                Sessions 2013-14. The Electronics and Communication branch was
                started in July 2015 with a sanctioned intake of 30 students.
                The institute achieved the status of an ‘Institute of National
                Importance’ on August 9, 2017 through ‘The Indian Institute of
                Information Technology (Public- Private Partnership) Act, 2017’.
                <br />
                <br /> Since then the mission of IIIT Kota is to create
                technical professionals to achieve the present and the future
                requirements of engineering and allied disciplines, IIIT Kota
                imparts the educational system that transforms students into
                human power embedded with creativity , innovation and technology
                for the betterment of society. <br />
                <br />
              </h6>
            </Col>
          </Container>
        </Container>
      </section>
      <NewFooter />
    </>
  );
};

export default AboutIIIT;
