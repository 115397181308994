import React, { useCallback, useState } from "react";
// import CardMedia from "@material-ui/core/CardMedia";
import Gallery from "react-photo-gallery";
import { Container, Row, Col } from "reactstrap";
import Carousel, { Modal, ModalGateway } from "react-images";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
const PHOTO_SET = [
  {
    src: "https://files.iiitkota.ac.in/images/gallery/81.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/80.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/79.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/78.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/77.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/76.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/75.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/74.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/73.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/72.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/71.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/70.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/69.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/68.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/67.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/66.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/65.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/64.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/63.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/62.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/61.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/60.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/59.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/58.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/57.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/56.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/55.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/54.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/53.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/52.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/51.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/50.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/49.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/48.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/47.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/46.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/45.webp",
    width: 6,
    height: 4,
  },
 
  {
    src: "https://files.iiitkota.ac.in/images/gallery/43.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/42.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/41.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/40.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/39.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/38.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/37.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/36.webp",
    width: 6,
    height: 4,
  },
  
  {
    src: "https://files.iiitkota.ac.in/images/gallery/35.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/34.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/33.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/32.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/31.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/23.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/24.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/25.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/26.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/28.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/29.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/1.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/2.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/3.webp",
    width: 6,
    height: 4,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/4.webp",
    width: 6,
    height: 5,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/5.webp",
    width: 7,
    height: 5,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/6.webp",
    width: 6,
    height: 5,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/7.webp",
    width: 7,
    height: 5,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/8.webp",
    width: 7,
    height: 5,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/9.webp",
    width: 7,
    height: 5,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/10.webp",
    width: 7,
    height: 5,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/11.webp",
    width: 7,
    height: 5,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/12.webp",
    width: 7,
    height: 5,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/13.webp",
    width: 7,
    height: 5,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/14.webp",
    width: 7,
    height: 5,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/16.webp",
    width: 7,
    height: 5,
  },

  {
    src: "https://files.iiitkota.ac.in/images/gallery/18.webp",
    width: 7,
    height: 5,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/19.webp",
    width: 7,
    height: 5,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/20.webp",
    width: 7,
    height: 5,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/21.webp",
    width: 7,
    height: 5,
  },
  {
    src: "https://files.iiitkota.ac.in/images/gallery/22.webp",
    width: 7,
    height: 5,
  },
];

const ImageGallery = () => {

  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <>
      <Helmet>
        <title>Gallery - Indian Institute of Information Technology Kota</title>
        <meta
          name="description"
          content="Gallery Indian Institute of Information Technology Kota"
        />
        <meta
          name="keywords"
          content="Gallery, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Clubs IIIT Kota,"
        />
        <meta name="author" content="Author name" />
      </Helmet>
      <DemoNavbar />
      <main>
        <div className="position-relative">
          <section className="section  section-shaped pb-200">
            <div className="shape shape-style-1 shape-default bg-gradient-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>

            <div className="separator separator-bottom separator-skew">

              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon className="fill-white" points="0 0 0 0 0" />
              </svg>
            </div>
          </section>
        </div>
        <section className="section section-lg">
          <Container>
            <Row className="justify-content-center text-center mb-5">
              <Col lg="8">
                <h2>
                  <b>Image Gallery</b>
                </h2>
              </Col>
            </Row>
          </Container>
          <Container fluid>
            <Gallery photos={PHOTO_SET} onClick={openLightbox} />
              <ModalGateway>
                {viewerIsOpen ? (
                  <Modal onClose={closeLightbox}>
                    <Carousel
                      currentIndex={currentImage}
                      views={PHOTO_SET.map((x) => ({
                        ...x,
                        srcset: x.srcSet,
                        caption: x.title,
                      }))}
                    />
                  </Modal>
                ) : null}
            </ModalGateway>
          </Container>
        </section>
      </main>
      <NewFooter />
    </>
  );
};

export default ImageGallery;
