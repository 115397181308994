import React from "react";
import Helmet from "react-helmet";
import {
  Container,
  Row,
  Col,
  UncontrolledCarousel,
} from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";

const items = [
  {
    src: "https://files.iiitkota.ac.in/images/canteen/canteen1.webp",
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: "https://files.iiitkota.ac.in/images/canteen/canteen2.webp",
    altText: "",
    caption: "",
    header: "",
  },
  // {
  //   src: "https://files.iiitkota.ac.in/images/canteen/3.webp",
  //   altText: "",
  //   caption: "",
  //   header: "",
  // },
];

class Canteen extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Canteen - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Canteen Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Canteen, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Annapurna,Akhshaya Patra Foundation,DR. KRISHNA KUMAR,Annapurna Canteen,Annapurna MNIT,Annapurna MNIT Canteen,MNIT Canteen,IIIT Kota Canteen,Canteen IIIT Kota"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Canteen</b>
                  </h2>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg={9}>
                  <UncontrolledCarousel items={items} />
                </Col>
              </Row>

              <Row className="mt-5">
                <Col lg={12} xs={12}>
                  <h4>
                    <b>Introduction</b>
                  </h4>
                </Col>
                <Col lg={12} xs={12} className="mt-3 text-justify">
                  <h6>
                    The canteen at IIIT Kota plays a crucial role in the daily
                    life of students. Its strategic placement adjacent to the
                    mess building not only enhances convenience but also fosters
                    a warm and inclusive environment that encourages students to
                    socialize.
                    <br />
                    The canteen stands out for its unwavering dedication to
                    ensuring the availability of nourishing and hygienic food
                    options, a commitment highly prioritized by the
                    institution's governing body. A noteworthy feature of the
                    canteen is its extended work hours, operational from 10 am
                    to 2 am. This extended schedule caters to students
                    late-night study sessions and project work, ensuring that no
                    one goes hungry during these crucial periods. In essence,
                    the IIIT Kota canteen offers nourishing food, a comfortable
                    environment, and the flexibility to accommodate the diverse
                    schedules of its students.
                  </h6>
                </Col>
                {/* <Col lg={12} xs={12} className="text-justify">
                  <h6>
                    <a href="mailto:coordinatorcanteen.annapurna@gmail.com">
                      <i className="fa fa-envelope pr-2" />
                      coordinatorcanteen.annapurna@gmail.com
                    </a>
                  </h6>
                </Col>*/}
              </Row>
              {/* <Row className="justify-content-center text mb-5 mt-5">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 text-primary">
                            <i className="fas fa-address-book" />
                          </div>

                          <h6 className="text-primary text-uppercase">
                            <b>Dr. Krishna Kumar , Coordinator Canteen</b>
                            <br />
                          </h6>

                          <div className="px-2 pb-2">
                            Assistant Professor
                            <br />
                            Department of Metallurgical and Materials Engg.
                            <br />
                            Malaviya National Institute Of Technology, Jaipur
                            <br />
                            JLN Marg, Malaviya Nagar, Jaipur-17 (Rajasthan)
                          </div>

                          <h6>
                            <a href="mailto:kkumar.meta@mnit.ac.in">
                              <i className="fa fa-envelope px-2" />
                              kkumar.meta@mnit.ac.in
                            </a>
                          </h6>

                          <h6>
                            <a href="tel:9549654245">
                              <i className="fas fa-phone-square-alt px-2" />
                              +91-9549654245
                            </a>
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row> */}
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Canteen;
