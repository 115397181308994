import React from "react";
import { Container, Row, Col, Table } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import "assets/css/style.css";
import Helmet from "react-helmet";
class Committees extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Committees - Indian Institute of Information Technology, Kota
          </title>
          <meta
            name="description"
            content="Committees - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="Committees iiit kota, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  <h2>
                    <b>Committees in IIIT Kota</b>
                  </h2>
                </Col>
              </Row>

              <Container lg={12} xs={12} className="kota-table mt-5 mb-5">
                <Table bordered>
                  <h6 className="only-padding">
                    <thead>
                      <tr>
                        <th style={{ width: "202px" }}>Committee Name</th>
                        <th style={{ width: "303px" }}>Committee Members</th>
                        <th style={{ width: "404px" }}>Purpose</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">
                          Institute Purchase Committee
                        </th>
                        <td>
                          Dr. Gyan Singh Yadav, Convener
                          <br />
                          Dr. Amit Kumar, Member
                          <br />
                          Dr. Ashok Kherodia, Member
                          <br />
                          Assistant Registrar, Member
                        </td>
                        <td align="justify">
                          To facilitate and supervise general purchase in the institute. To suggest purchase procedure to be followed in the institute. To facilitate use of ERP systems in purchase of related affairs.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">
                          Sports
                        </th>
                        <td>
                          Dr. Anand Agrawal, Convener
                          <br />
                          Dr. Gyan Singh Yadav, Member
                          <br />
                          Dr. Chetna Sharma, Member
                        </td>
                        <td align="justify">
                          To frame and implement the policy and rules for sports activities. To make the sports calendar for the complete academic year. To coordinate with other technical institutions of the region. To keep all the sports facilities well maintained. To promote sports in students, staff and faculty. To organize competitions and events related to outdoor/indoor games. To plan maintenance and new facilities of outdoor/indoor games.
                        </td>
                      </tr>

                      <tr>
                        <th scope="row">Scholarships</th>
                        <td>
                          Dr. Amit Kumar, Convener
                        </td>
                        <td align="justify">
                          To devise mechanism to increase the number of scholarships from external funding agencies. To coordinate between the PG coordinator of the departments on the issue of UG/PG Scholarships. To coordinate between the State/Central Government on the issue of scholarships. To create and announce scholarships for needy students. To coordinate along with Coordinator alumni for collection of funds for scholarships. To institutionalize and maintain existing scholarships from various industries.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Training and Placement</th>
                        <td>
                          Dr. Amit Kr Garg, Faculty In-charge
                        </td>
                        <td align="justify">
                          To facilitate placement of students within and outside India. To coordinate and organize practical training of students. To take necessary measures to establish linkages with industry. To organize career counselling sessions/programs. To initiate dialogue with industry for collaborative projects/ research.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Time Table and Examination</th>
                        <td>
                          Dr. Ashok Kr Kherodia, Convener
                          <br />
                          Dr. Amit Kumar Garg, Member
                          <br />
                          Dr. Ajay Nehra, Member
                          <br />
                          Dr. Saurabh Pandey, Member
                        </td>
                        <td align="justify">
                          To prepare institute timetable and conduct examinations at all levels.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Creative Arts and Cultural Socities</th>
                        <td>
                          Dr. Vinita Tiwari, Convener
                          <br />
                          Dr. Chetna Sharma, Member
                          <br />
                          Dr. Prerna Vanjani, Member
                          <br />
                          AR (A/c), Member
                        </td>
                        <td align="justify">
                          To plan and conduct cultural related activities within
                          the framework of academic calendar. To coordinate with
                          other NITs and other technical institution for
                          participation in cultural activities. To coordinate
                          the SPICMACAY programs and other performances. To
                          encourage students participation in Creative Arts and
                          Cultural Activities.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Anti-Ragging Squad</th>
                        <td>
                          Dr. Parikshit K Singh, Convener
                          <br />
                          Dr. Amit Kumar Garg, Member
                          <br />
                          Dr. Priyanka Mishra, Member
                        </td>
                        <td align="justify">
                          To observe the students movements and any type of
                          teasing or ragging before starting of the class work,
                          during the lunch hour, after closing the class work
                          and during the night hours. If any type of ragging is
                          noticed, the Members will advise the students and will
                          bring into the notice of the Head of the Institution.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Library</th>
                        <td>
                          Dr.Prikshit K. Singh, Convener
                          <br />
                          Dr. Isha Pathak Tripathi, Member
                          <br />
                          Dr. Gyan Singh Yadav, Member
                        </td>
                        <td align="justify">
                          To plan and advise on modernization of Institute
                          library including development of E-Library/Digital
                          Library. To suggest measures to enhance the quality of
                          resources materials in the library and create a better
                          academic ambiance in the library. To discriminate
                          information on available sources and organize programs
                          on the use of education technology development,
                          educational aids and use other learning resource
                          materials. Facilitate purchase of books, journals and
                          magazines in consultation with HODs and library
                          committees.
                        </td>
                      </tr>
                      {/* <tr>
                        <th scope="row">Wardens</th>
                        <td>
                          Dr. Priyanka Harjule, Girls Hostel
                          <br />
                          Dr. Amit Kumar Garg, Boys Hostel
                        </td>
                        <td>
                          To arrange upkeep and smooth functioning of hostels.
                          To ensure maintenance of accounts and activities
                          reports. To coordinate with nodal agencies for this
                          purpose.
                        </td>
                      </tr> */}
                      <tr>
                        <th scope="row">Website</th>
                        <td>
                          Dr. Ajay Nehra, Convener
                          <br />
                          Mr. Tej Singh Jatav, Member
                        </td>
                        <td align="justify">
                          To maintain and update the all the events and news
                          related to IIIT Kota. Provide the all institute
                          related information.
                        </td>
                      </tr>
                      {/* <tr>
                        <th scope="row">Grievance</th>
                        <td>
                          Dr. Vinita Tiwari, Convener
                          <br />
                          Dr. Isha Tripathi Pathak, Member
                          <br />
                          Dr. Amit Kumar, Member
                        </td>
                        <td>
                          To meet frequently or at the instance of the convener
                          or at the request of the other members to discuss the
                          various issues received. If the members of the
                          Grievance Committee is connected with the grievance of
                          the aggrieved individual, the concerned member of the
                          Grievance Committee shall not participate in the
                          deliberations regarding that individual’s case. If the
                          aggrieved person happens to be a member of the
                          Grievance Committee, then he shall not participate in
                          the deliberations as a Member of the Committee when
                          his/her representation is being considered.
                        </td>
                      </tr> */}
                      <tr>
                        <th scope="row">Information and Annual Report</th>
                        <td>
                          Dr. Chetna Sharma, Convener <br />
                          Dr. Ashok Kr Kherodia, Member <br />
                          Dr. Prerna Vanjani, Member <br />
                        </td>
                        <td align="justify">
                          To coordinate the activities related with preparation
                          and printing of annual report and to ensure its
                          submission to MHRD well in time.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Departmental Under Graduate Committee (DUGC)</th>
                        <td>
                          Dr. Gyan Singh Yadav, Convener (CSE)
                          <br />
                          Dr. Vinita Tiwari, Convener (ECE)
                          <br />
                          Various Programme Advisors, Members
                          <br />
                          Students Nominee, Members
                        </td>
                        <td align="justify">
                          To frame and implement the policy for smooth conduction of UG programmes. Prepare academic
                          calendar for each academic session and implement
                          it. To frame rules for supplementary examinations and conduct exams.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Departmental Post Graduate Committee (DPGC)</th>
                        <td>
                          Dr. Priyanka Mishra, Convener (CSE)
                          <br />
                          Dr. Parikshit K Singh, Convener (ECE)
                          <br />
                          Various Programme Advisors, Members
                          <br />
                          Students Nominee, Members
                        </td>
                        <td align="justify">
                          To frame and implement the policy for smooth conduction of PG programmes. Prepare academic
                          calendar for each academic session and implement
                          it. To frame rules for supplementary examinations and conduct exams.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Alumni Affairs</th>
                        <td>
                          Dr. Isha Pathak Tripathi, Convener <br />
                          Dr. Ashok Kr Kherodia, Member <br />
                          AR (Academics), Member
                        </td>
                        <td align="justify">
                          To broden the width and breadth of the scope of
                          working with Alumni of the institute. To plan for
                          establishment of additional Alumni Chapter in India
                          and abroad. TO plan Alumni meets within the country
                          and abroad. To interact with Alumni for seeking,
                          suggestion and support for institutions development,
                          scholarships, finacial support in the brand building
                          exercise of the institute. To ineract and coordinate
                          activities for associate activities for associate
                          member of alumni association.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Faculty and Staff Club</th>
                        <td>
                          Dr. Gyan Singh Yadav, Convener <br />
                          Dr. Anand Agrawal, Member <br />
                          AR (A/c), Member
                        </td>
                        <td align="justify">
                          To coordinate between institute and the faculty/staff
                          club activities. To organize picnic and other cultural
                          activities of Faculty and staff club. To provide
                          feed-back from the colony residents to improve the
                          quality of facilities in the colony.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Social Media Cell</th>
                        <td>
                          Dr. Ajay Nehra, Convener
                          <br />
                          Mr. Banne Singh Bhati, Member
                        </td>
                        <td align="justify">
                          To maintain and update all the official social media
                          accounts.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Disciplinary Committee</th>
                        <td>
                          Dr. Parikshit K Singh, Convener
                          <br />
                          DPGC/DUGC Conveners, Member
                          <br />
                          Various Programme Advisors, Member
                          <br />
                          Various Wardens, Member
                        </td>
                        <td align="justify">
                          To ensure compliance of The Honour Code of IIIT Kota.
                          To decide on the matters related to the violation of
                          The Honour Code and suggest suitable action
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Student Counselling Cell</th>
                        <td>
                          Dr. Amit Kumar Garg, Convener <br />
                          Dr. Ashok Kr Kherodia, Member <br />
                          Dr. Chetna Sharma, Member
                        </td>
                        <td align="justify">
                          To appoint Faculty Mentors to the newly joined
                          students. To organize counselling sessions for
                          students in coordination with Faculty Mentors. To
                          facilitate services of Professional Counsellor on
                          recommendation of the Faculty Mentors.
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">National Academic Depository Cell</th>
                        <td>
                          Dr. Isha Pathak Tripathi, Nodal Officer <br />
                          Dr. Amit Garg, Member <br />
                          Shri Tej Singh Jatav, Member
                        </td>
                        <td align="justify">
                          To maintain and upload academic records such as
                          marksheets, degrees, etc. of students of IIIT Kota on
                          NAD portal.
                        </td>
                      </tr>
                    </tbody>
                  </h6>
                </Table>
              </Container>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Committees;
