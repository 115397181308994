import React from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import "views/IndexSections/Sections.css";
import Helmet from "react-helmet";
class Bank extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Banks - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Banks Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Banks, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Banks in IIIT Kota,IIIT Kota Banks,Banks in IIIT Kota"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Banks</b>
                  </h2>
                </Col>
              </Row>
            </Container>
            <Container>
              <div className=" d-flex justify-content-center mt-3 icicilogo-img">
                    <img className="rounded img-fluid "src={require("assets/img/facilities/icicilogo.webp")} alt="/" width={300}/>
                </div>
              <Row>
                <Col lg={12} xs={12} className="mt-3">
                  <h6>
                  Banking Facilities in MNIT is extended by ICICI Bank through its MREC Campus, Jaipur Branch that is located in the MNIT premises. ATM facility, Internet Banking, Telebanking are available here.
                  </h6>
                </Col>
                <Col lg={12} xs={12} className="mt-3 mb-1">
                      <b>Branch Details:</b>
                      <b>MNIT Campus Branch, Jaipur</b> <br/>
                      <p> <b>IFSC Code: </b> ICIC0006768</p>
                      <p> <b>MICR Code: </b> 302229031</p>
                </Col>
                <Col lg={12} xs={12} className="mt-1 mb-1">
                      <b>Timings:</b>
                      <p>9.30 am - 3.30 pm (Monday - Saturday) <br/>
                        2nd and 4th Saturday- OFF</p>
                </Col> 
                <Col lg={12} xs={12} className="mt-1 mb-3">
                      <b>ATM</b>
                      <p>24*7 hrs ATM is situated in the control room near dispensary in the campus</p>
                </Col>  
                  
              </Row>

              <Row className="justify-content-center text mb-5 ">
                <center>
                  <h4 className=" mb-5">
                    <b>Contact Information:</b>
                  </h4>
                </center>
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5 pl-4">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 text-primary">
                            <i className="fas fa-address-book" />
                          </div>

                          <h6 className="text-primary text-uppercase">
                            <b>Mr. Manoj Mantri</b>
                            <br />
                          </h6>

                          <div className="px-2 pb-2">
                            <i>Deptuy Branch Manager </i>
                            <br />
                            ICICI Bank Ltd. <br/> Prabha Bhawan, MNIT Campus <br/> JLN Marg, Malviya Nagar, Jaipur- 302017
                          </div>

                          <h6>
                            <b>Phone no:</b>{" "}
                            <a href="tel:+91-8696924225">  +91-8696924225</a>
                            <br /> <b>Email:</b>
                            <a href="mailto:chetan.bose@icicibank.com"> chetan.bose@icicibank.com</a>
                          </h6>
                          <b>Customer Care No: 1860 120 7777</b> <br/>
                          <b>Customer Care Rajasthan : 9829222292</b>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
            <Container>
            <div className=" d-flex justify-content-center mt-6">
                    <img className="rounded img-fluid sbilogo-img"src={require("assets/img/facilities/sbilogo.webp")} alt="/" width={300}/>
                </div>
              <Row>
                <Col lg={12} xs={12} className="mt-3">
                  <h6>
                  Banking Facilities in MNIT is extended by and SBI Bank through its MNIT Campus, Jaipur Branch that is located in the MNIT premises. Internet Banking,ATM, Loans are available here.
                  </h6>
                </Col>
                <Col lg={12} xs={12} className="mt-3 mb-1">
                      <b>Branch Details:</b>
                      <b>MNIT Campus Branch, Jaipur</b> <br/>
                      <p> <b>IFSC Code: </b> SBIN0015921</p>
                      <p> <b>MICR Code: </b> 302002066</p>
                </Col>
                <Col lg={12} xs={12} className="mt-1 mb-1">
                      <b>Timings:</b>
                      <p>10.00 am - 4.00 pm (Monday - Saturday) <br/>
                        2nd and 4th Saturday- OFF</p>
                </Col> 
                <Col lg={12} xs={12} className="mt-1 mb-3">
                      <b>ATM</b>
                      <p>24*7 hrs ATM is situated in the premises of the Bank.</p>
                </Col>  
                  
              </Row>

              <Row className="justify-content-center text mb-5 ">
                <center>
                  <h4 className=" mb-5">
                    <b>Contact Information:</b>
                  </h4>
                </center>
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="6">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody className="py-5 pl-4">
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 text-primary">
                            <i className="fas fa-address-book" />
                          </div>

                          <h6 className="text-primary text-uppercase">
                            <b>Mrs. Savita Gothwal</b>
                            <br />
                          </h6>

                          <div className="px-2 pb-2">
                            <i>Branch Manager </i>
                            <br />
                            SBI Bank, MNIT Campus Branch <br/> JLN Marg, Jaipur- 302017
                          </div>

                          <h6>
                            <b>Phone no:</b>{" "}
                            <a href="tel:+91-9636961859">  +91-9636961859</a> <br/>
                            <b>Tel no:</b>{" "}
                            <a href="tel: +91-141-2529111">  +91-141-2529111</a>
                            <br /> <b>Email:</b>
                            <a href="mailto:sbi.15921@sbi.co.in"> sbi.15921@sbi.co.in</a>
                          </h6>
                          <b>Customer Care Toll Free : 1800 11 22 11</b>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Bank;
