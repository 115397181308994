import React from "react";
import WorkItem from "./WorkItem";
import { arrayOf, object } from "prop-types";

const Work = (props) => {
  const getWorkExperience = () => {
    const { workData } = props;
    const workItems = [];
    workData &&
      workData.forEach((val, index) => {
        workItems.push(<WorkItem key={index} workItemData={val} />);
      });
    return workItems;
  };

  return (
      <>
        {getWorkExperience().length > 0 && (
            <section
                className="section work"
                style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}
            >
              <h2 className="text-uppercase h2">
                <i className="fa fa-lg fa-building"></i> Work experience
              </h2>
              {getWorkExperience()}
            </section>
        )}
      </>
  );
};

Work.propTypes = {
  workData: arrayOf(object),
};

export default Work;
