import React from "react";
import "assets/css/style.css";
import { Button, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
class Director extends React.Component {
  state = {};
  constructor(props) {
    super(props);
    this.state = {
      isEnglish: true,
      readMore: false,
    };
  }

  handleLanguageChange = () => {
    this.setState((prevState) => ({
      isEnglish: !prevState.isEnglish,
    }));
  };
  handleReadmore = () => {
    this.setState((prevState) => ({
      readMore: !prevState.readMore,
    }));
  };

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Director - Indian Institute of Information Technology, Kota
          </title>
          <meta
            name="description"
            content="Director - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="Director iiit kota, Prof. A. P. S. Rathore, director mnit jaipur, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
          <meta name="author" content="Prof. A. P. S. Rathore" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Director</b>
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col lg={4} xs={12} className="mb-5">
                  <img
                    alt="/"
                    className="director-img"
                    src={require("assets/img/people/director.png")}
                  ></img>

                  <div className="mt-3">
                    <h6>Phone : +91 141 2529087</h6>
                  </div>
                  <div className="mt-3">
                    <h6>
                      Director, IIIT Kota
                      <br />
                      Prabha Bhawan, MNIT Campus, JLN Marg, Jaipur-302017
                    </h6>
                  </div>
                  <div className="d-flex flex-grow-1  d-lg-none">
                    <div className="d-flex flex-column row-gap-3">
                      <div>
                        <span className="btn-inner--icon mr-1">
                          <i class="fa fa-eye"></i>
                        </span>
                        <a href="https://iitr.ac.in/Departments/Electrical%20Engineering%20Department/People/Faculty/100083.html">
                          View Detailed Profile @ IITR
                        </a>
                      </div>
                      <div>
                        <span className="btn-inner--icon mr-1">
                          <i class="fa fa-eye"></i>
                        </span>
                        <a href="https://mnit.ac.in/admin/director">
                          View Detailed Profile @ MNIT
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="mt-3 ml-7">
                    <Button
                      className="btn-icon btn-3 theme-color-kota-bg"
                      type="button"
                      href="mailto:director@iiitkota.ac.in"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fa fa-envelope" />
                      </span>
                      <span className="btn-inner--text">E-mail</span>
                    </Button>
                    {/* <Button
                      className="btn-icon btn-3 theme-color-kota-bg"
                      type="button"
                      href="https://www.linkedin.com/in/udaykumar-r-yaragatti-559a1514/"
                      target="_blank"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fab fa-linkedin" />
                      </span>
                      <span className="btn-inner--text">LinkedIn</span>
                    </Button> */}
                  </div>
                </Col>
                <Col lg={8} xs={12}>
                  <div className="pb-4 d-flex jusitfy-content-between">
                    <div>
                      <h2 className="display-3 mb-0">
                        <b> Prof. N. P. Padhy</b>
                      </h2>
                      <h5>Director, IIIT Kota</h5>
                    </div>
                    <div className="d-lg-flex flex-grow-1 justify-content-end d-none ">
                      <div className="d-flex flex-column row-gap-3">
                        <div>
                          <span className="btn-inner--icon mr-1">
                            <i class="fa fa-eye"></i>
                          </span>
                          <a href="https://iitr.ac.in/Departments/Electrical%20Engineering%20Department/People/Faculty/100083.html">
                            View Detailed Profile @ IITR
                          </a>
                        </div>
                        <div>
                          <span className="btn-inner--icon mr-1">
                            <i class="fa fa-eye"></i>
                          </span>
                          <a href="https://mnit.ac.in/admin/director">
                            View Detailed Profile @ MNIT
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-justify director-content">
                    <h5>
                      Our focus is to continuously improve our performance
                      inspiring excellence and to grow into one of the best
                      institutions for engineering education in the country.
                    </h5>
                  </div>
                  <hr />
                  <div className="text-justify mb-6">
                    <h5 className="mb-4">
                      <b>The Brief Profile is as : </b>
                    </h5>
                    
                    <h6>
                    Dr. Narayana Prasad Padhy, Chairperson (I/C), Board of Governors MNIT Jaipur,
                  Director, MNIT Jaipur and Director, IIIT Kota, Chair Professor IIT Palakkad
                  Technology IHub Foundation (IPTIF), is a Professor (HAG) of Electrical Engineering
                  and has served as Dean of Academic Affairs, IIT Roorkee, Founder Head Mehta
                  Family School of Data Science and Artificial Intelligence, Professor In-Charge
                  Training and Placement, NEEPCO and Institute Chair Professor at IIT Roorkee.
                  Dr. Padhy is a prolific researcher in the area of Power Systems Engineering and
                  Smart Grid, and has authored over 220 papers in international journals, with an
                  overall citation of 9500 plus, h-index of 50, and i-index of 143. He has contributed
                  significantly to the domain by way of securing 13 international/national research
                  grants with a funding of over INR 200 million, successfully supervised 23 research
                  scholars and currently supervising 21 research scholars, publishing research
                  articles, writing six books, securing patents, laboratory development and developing
                  online NPTEL courses.
                  Dr. Padhy has held visiting positions at eminent international universities, and
                  established research engagements and institutional tie-ups with universities from the
                  US, UK, Denmark, Italy and Canada. He has collaborated with top industries and
                  government agencies for research, laboratory development, and as a knowledge
                  partner. He is well recognised in international and national professional bodies being
                  Fellow of Indian National Academy of Engineering, The Institution of Engineering
                  and Technology, UK, Institution of Engineers, India, Institution of Electronics and
                  Telecommunication Engineers, India, and has actively contributed in various
                  capacities towards their growth. He also represents the country at various
                  international research platforms.
                  Dr. Padhy is a well awarded and honoured scholarly professional at the national and
                  international levels, including Alexander von Humboldt Experienced Research
                  Fellowship, Samanta Chandra Sekhar Award, IEEE Smart City Jury Award, IEEE
                  PES Chapter Outstanding Engineer Award from IEEE Power & Energy Society, USA,
                  and BOYSCAST Research Fellow Award for Young Scientist.
                    </h6>
                      {/* <li>
                        <h6>
                          Chairman, North Western Regional Committee{" "}
                          <b>(NWRC)</b> Chandigarh, AICTE{" "}
                        </h6>
                      </li>
                      <li>
                        <h6>
                          Chairman, Central Seat Allotment Board{" "}
                          <b>(CSAB-17 & 2018)</b>
                        </h6>
                      </li>
                      <li>
                        <h6>
                          Member - Joint Seat Allocation Authority{" "}
                          <b>(JoSAA)</b> 2019{" "}
                        </h6>
                      </li>
                      <li>
                        <h6>
                          Chairperson - External committee to examine and
                          finalize the revised DPR of NITs
                        </h6>
                      </li>
                      <li>
                        <h6>Member - Finance committee IIT Jammu</h6>
                      </li>
                      <li>
                        <h6>
                          Member- General Body of National Testing Agency{" "}
                          <b>(NTA)</b>{" "}
                        </h6>
                      </li>
                      <li>
                        <h6>
                          Member - Committee constituted for implementation and
                          support of ERP system in NITs
                        </h6>
                      </li>
                      <li>
                        <h6>
                          Member- Central Advisory Board of Education{" "}
                          <b>(CABE)</b>, New Delhi
                        </h6>
                      </li>
                      <li>
                        <h6>
                          Member - Translational Health Science and Technology
                          Institute <b>(THSTI)</b>
                        </h6>
                      </li>
                      <li>
                        <h6>
                          Member of Governing Council of PGP College of
                          Engineering and Technology, Namakkal, Tamilnadu
                        </h6>
                      </li>
                      <li>
                        <h6>
                          Board member - JN Govt. Engineering College
                          Sundarnagar
                        </h6>
                      </li>
                      <li>
                        <h6>Member – Institute of Eminence (IoE)</h6>
                      </li> */}
                    
                  </div>
                  <hr />
                  <div className="text-justify mb-6">
                    <div className="d-flex  mb-4">
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <h5>
                          <b>Biography</b>
                        </h5>
                        <div className="d-flex" style={{ columnGap: 5 }}>
                          <div
                            onClick={this.handleLanguageChange}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              borderRadius: 4,
                              border: "solid 1px black",
                              padding: "3px 5px",
                              cursor: "pointer",
                              backgroundColor: this.state.isEnglish
                                ? "#0E407C"
                                : "inherit",
                              color: this.state.isEnglish
                                ? "#ffffff"
                                : "inherit",
                            }}
                          >
                            <span className=" mt-1 mr-1">
                              <i
                                class="fa fa-language"
                                style={{ fontSize: 20 }}
                              ></i>
                            </span>
                            English
                          </div>
                          <div
                            onClick={this.handleLanguageChange}
                            style={{
                              display: "flex",
                              alignItems: "center",
                              borderRadius: 4,
                              border: "solid 1px black",
                              padding: "3px 5px",
                              cursor: "pointer",
                              backgroundColor: !this.state.isEnglish
                                ? "#0E407C"
                                : "inherit",
                              color: !this.state.isEnglish
                                ? "#ffffff"
                                : "inherit",
                            }}
                          >
                            <span className=" mt-1 mr-1">
                              <i
                                class="fa fa-language"
                                style={{ fontSize: 20 }}
                              ></i>
                            </span>
                            Hindi
                          </div>
                        </div>
                      </div>
                    </div>
                    <h6>
                      {this.state.isEnglish ? (
                        this.state.readMore ? (
                          <div>
                            Dr. Narayana Prasad Padhy, Chairperson (I/C), Board of Governors MNIT Jaipur,
                            Director, MNIT Jaipur and Director, IIIT Kota, Chair Professor IIT Palakkad
                            Technology IHub Foundation (IPTIF), is a Professor (HAG) of Electrical Engineering
                            and has served as Dean of Academic Affairs, IIT Roorkee, Founder Head Mehta
                            Family School of Data Science and Artificial Intelligence, Professor In-Charge
                            Training and Placement, NEEPCO, and Institute Chair Professor at IIT Roorkee.
                            <br/>

                            He received his graduation degree in Electrical Engineering in 1990, Masters (Power
                            Systems Engineering) with Distinction in 1993, and Ph.D. (College of Engineering,
                            Guindy, Anna University, Chennai) in 1997. He worked as an Assistant Professor at
                            BITS Pilani, India, for a year, after which he joined the Department of Electrical
                            Engineering, IIT Roorkee, as a Lecturer in 1998. He was promoted to the position of
                            full Professor in 2008.
                            <br/>

                            Dr. Padhy researches in the area of Power Systems Engineering and Smart Grid. He
                            has successfully supervised 23 research scholars and is currently guiding 21
                            research scholars. He has authored over 220 papers and articles in international
                            journals, with an overall citation of 9500 plus (h-index=50, i-index 143). He has also
                            authored six textbooks and three successfully granted patents. His popular NPTEL
                            online course on &quot;Introduction to Smart Grid&quot; has over 50,000 views. His new
                            NPTEL course on &quot;Smart Grid: Basics to Advanced Technologies&quot;, focuses on the
                            practical research challenges of smart grid and provides laboratory demonstration.
                            Two of his new NPTEL courses on “Power System Operation and Control” and
                            “NPTEL course on Electrical Distribution System Analysis” start from July 2024.
                            <br/>

                            He is a Fellow of Indian National Academy of Engineers (FNAE), The Institution of
                            Engineering and Technology, UK (FIET), Institution of Engineers, India (FIE),
                            Institution of Electronics and Telecommunication Engineers, India (FIETE), Chair
                            Sectional Committee – V (Electrical Engineering) – INAE -India. As Senior Member
                            of IEEE and Power and Energy Society, IEEE USA, he has actively contributed to
                            various IEEE professional activities as Chair, PES Network Charging Working Group
                            and Chair, IEEE Roorkee Subsection, Chair, IEEE UP Section, Member, IEEE PES
                            Intelligent Systems Subcommittee. He also served as Vice-Chair, CIGRE National
                            Study Committee on C4: Power System Technical Performance. He is actively
                            involved in the Mission Innovation (MI) 2.0 - Green Powered Future Mission,
                            engaging with about two dozen countries on the international platform.
                            <br/>

                            Dr. Padhy has served as a Visiting Professor/Fellow at Canadian and UK
                            universities. His research engagements supported institutional tie-ups with eminent
                            US universities (MIT, Texas A&M, and WSU), UK universities (Bath, Cardiff,
                            Edinburgh, and Durham), DTU Denmark, and industry partners like TERI, BSES
                            Delhi, Tata Power and ABB. He successfully established the AC-DC Hybrid Microgrid
                            (ADMIRE) Lab at IIT Roorkee and dreams of deploying India’s first Smart Academic
                            Campus (SAC). He has successfully executed 13 international/national research
                            projects and is currently leading 6 international research projects, with a funding of
                            over INR 200 million. He serves as a knowledge partner/consultant to various Smart
                            City projects of the central government and state governments.
                            <br/>

                            Dr. Padhy has been conferred various national and international awards and
                            honours. These include Alexander von Humboldt Experienced Research Fellowship
                            from Alexander von Humboldt Foundation, Germany (2009), Samanta Chandra
                            Sekhar Award (2021), IEEE IAS GlobConHT 2023 Global Lifetime Achievement
                            Award, IEEE Smart City Jury Award 2022, IEEE PES Chapter Outstanding Engineer
                            Award from IEEE Power & Energy Society, USA (2018), Indian Institute of
                            Technology Roorkee felicitation for bringing laurels to the Institute in 2017, and
                            BOYSCAST Research Fellow Award for Young Scientist elected from Department of
                            Science and Technology, Govt. of India (2005). His services to the Smart City and
                            Smart Grid Technology development have been recognised by several honorary
                            awards. He has successfully supervised six PhD thesis for being awarded the Grid
                            India Power System Awards (GIPSA), formerly known as POSOCO award.
                            <br/>
                          </div>
                        ) : (
                          <div>
                            <strong>
                              Dr. Narayana Prasad Padhy, Director, MNIT Jaipur and Director, IIIT Kota
                            </strong>
                            , is a Professor (HAG) of Electrical Engineering and
                            has served as Dean of Academic Affairs, IIT Roorkee,
                            Founder Head Mehta Family School of Data Science and
                            Artificial Intelligence, Professor In-Charge
                            Training and Placement, NEEPCO, and Institute Chair
                            Professor at IIT Roorkee.
                            <br /> Dr. Padhy is a prolific researcher in the
                            area of Power Systems Engineering and Smart Grid. He
                            has contributed significantly to the domain by way
                            of securing research grants, supervising scholars,
                            publishing research articles, writing books,
                            securing patents, laboratory development and
                            developing online NPTEL courses. <br />
                            Dr. Padhy has held visiting positions at eminent
                            international universities and established research
                            engagements and institutional tie-ups with these
                            universities. He has collaborated with top
                            industries and government agencies for research,
                            laboratory development, and as a knowledge partner.
                            He is well recognised in international and national
                            professional bodies and has actively contributed in
                            various capacities towards their growth. He also
                            represents the country at various international
                            research platforms.
                            <br /> Dr. Padhy is a well awarded and honoured
                            scholarly professional at the national and
                            international levels.
                            <br />
                          </div>
                        )
                      ) : this.state.readMore ? (
                        <div>
                          <strong>
                          डॉ. नारायण प्रसाद पाढ़ी, निदेशक, एम.एन.आई.टी. जयपुर एवं आईआईआईटी कोटा
                          </strong>
                          , निवर्तमान प्रोफेसर (एच.ए.जी.), डीन अकादमिक मामले,
                          संस्थापक हेड- मेहता फैमिली स्कूल ऑफ डेटा साइंस एंड
                          आर्टिफिशियल इंटेलिजेंस, प्रोफेसर इंचार्ज- ट्रेनिंग एंड
                          प्लेसमेंट, एन.ई.ई.पी.सी.ओ. तथा आई.आई.टी. रुड़की में
                          संस्थान के चेयर प्रोफेसर के रूप में कार्य कर चुके हैं।
                          <br />
                          उन्होंने स्नातक इलेक्ट्रिकल इंजीनियरिंग से, परास्नातक
                          पावर सिस्टम इंजीनियरिंग में डिस्टिंक्शन तथा पी.एच.डी.
                          (कॉलेज ऑफ इंजीनियरिंग, गिंडी, अन्ना विश्वविद्यालय,
                          चेन्नई) की उपाधि क्रमशः 1990, 1993 और 1997 में प्राप्त
                          की। उन्होंने सहायक प्रोफेसर के पद पर बिट्स पिलानी,
                          भारत में एक वर्ष के लिए कार्य किया जिसके बाद उन्होंने
                          1998 में व्याख्याता के रूप में आई.आई.टी. रुड़की के
                          इलेक्ट्रिकल इंजीनियरिंग विभाग में कार्यग्रहण किया।
                          उन्हें 2008 में पूर्ण प्रोफेसर के पद पर पदोन्नत किया
                          गया था।
                          <br />
                          डॉ. पाढ़ी पावर सिस्टम्स इंजीनियरिंग और स्मार्ट ग्रिड
                          के क्षेत्र में शोध करते हैं। उन्होंने 16 शोधार्थियों
                          का सफलतापूर्वक पर्यवेक्षण किया है और वर्तमान में 27
                          शोधार्थियों का मार्गदर्शन कर रहे हैं। उन्होंने पावर
                          सिस्टम्स में छः पाठ्यपुस्तकों का प्रकाशन और तीन पेटेंट
                          दायर किये हैं । जिनमें से एक को स्वीकृत किया गया है।
                          उन्होंने 8700 से अधिक (एच.इंडेक्स- 47, आई.इंडेक्स-130)
                          के समग्र उद्धरण के साथ अंतरराष्ट्रीय पत्रिकाओं में 220
                          से अधिक शोध पत्र और लेख लिखे हैं। "इंट्रोडक्शन टू
                          स्मार्ट ग्रिड" पर उनके लोकप्रिय एन.पी.टी.ई.एल. ऑनलाइन
                          पाठ्यक्रम को 50000 से से अधिक बार देखा गया है।
                          "स्मार्ट ग्रिड: बेसिक्स टू एडवांस्ड टेक्नोलॉजीज" पर
                          उनका नया एनपीटीईएल पाठ्यक्रम, स्मार्ट ग्रिड की
                          प्रायौगिक अनुसंधान की चुनौतियों पर केंद्रित है तथा इस
                          पाठ्यक्रम में प्रयोगशाला के माध्यम से प्रदर्शन भी किया
                          गया है।
                          <br />
                          डॉ. पाढ़ी इंडियन नेशनल एकेडमी ऑफ इंजीनियरिंग (FNAE), द
                          इंस्टीट्यूशन ऑफ इंजीनियरिंग एंड टेक्नोलॉजी, यू.के.
                          (FIET), इंस्टीट्यूशन ऑफ इंजीनियर्स, इंडिया (FIE), फेलो
                          इंस्टीट्यूशन ऑफ इलेक्ट्रॉनिक्स एंड टेलीकम्युनिकेशन
                          इंजीनियर्स, इंडिया (FIETE) के फेलो हैं। आई.ई.ई.ई. और
                          पावर एंड एनर्जी सोसाइटी, आई.ई.ई.ई. यू.एस.ए. के वरिष्ठ
                          सदस्य होने के नाते उन्होंने चेयर पी.ई.एस. नेटवर्क
                          चार्जिंग वर्किंग ग्रुप तथा चेयर आई.ई.ई.ई. रुड़की उपखंड
                          के रूप में विभिन्न आई.ई.ई.ई. वृत्तिक गतिविधियों में
                          सक्रिय रूप से योगदान दिया है। उन्होंने C4: पावर सिस्टम
                          टेक्निकल परफॉरमेंस पर CIGRE राष्ट्रीय अध्ययन समिति के
                          उपाध्यक्ष के रूप में भी कार्य किया है। वह मिशन इनोवेशन
                          एम.आई. 2.0- ग्रीन पावर्ड फ्यूचर मिशन में सक्रिय रूप से
                          शामिल हैं जिसमे लगभग दो दर्जन देश अंतरराष्ट्रीय मंच पर
                          एक साथ कार्य कर रहे हैं।
                          <br />
                          डॉ. पाढ़ी ने कनाडा और ब्रिटेन के विश्वविद्यालयों में
                          विजिटिंग फैकल्टी/ फेलो के रूप में कार्य किया है। उनके
                          अनुसंधान कार्यों ने प्रख्यात अमेरिकी विश्वविद्यालयों
                          (एमआईटी, टेक्सास ए एंड एम, और डब्ल्यूएसयू), यूके के
                          विश्वविद्यालयों (बाथ, कार्डिफ, एडिनबर्ग, और डरहम),
                          डीटीयू डेनमार्क, और टेरी, बीएसईएस दिल्ली, टाटा पावर और
                          एबीबी जैसे उद्योग भागीदारों और संस्थाओं से साझेदारी
                          स्थापित की है। । उन्होंने आई.आई.टी. रुड़की में AC-DC
                          हाइब्रिड माइक्रोग्रिड (ADMIRE) लैब को सफलतापूर्वक
                          स्थापित किया तथा भारत के पहले स्मार्ट एकेडमिक कैंपस
                          (SAC) को स्थापित करने की कल्पना की है। उन्होंने 6
                          अंतरराष्ट्रीय/राष्ट्रीय अनुसंधान परियोजनाओं का
                          सफलतापूर्वक निष्पादन किया है और वर्तमान में 200 मिलियन
                          रुपये से अधिक के वित्त पोषण के साथ 6 अंतरराष्ट्रीय
                          अनुसंधान परियोजनाओं का नेतृत्व कर रहे हैं। वह केंद्र
                          और राज्य सरकारों की विभिन्न स्मार्ट सिटी परियोजनाओं के
                          लिए एक ज्ञान भागीदार/सलाहकार के रूप में कार्य कर रहे
                          हैं।
                          <br />
                          डॉ. पाढ़ी को राष्ट्रीय और अंतरराष्ट्रीय स्तर पर
                          विभिन्न सम्मानों और पुरस्कारों से सम्मानित किया गया
                          है। इनमें अलेक्जेंडर वॉन हम्बोल्ट फाउंडेशन, जर्मनी
                          (2009) से अलेक्जेंडर वॉन हम्बोल्ट एक्सपेरिएंस्ड रिसर्च
                          फैलोशिप, आई.ई.ई.ई. आई ए एस ग्लोब कॉन एच् टी 2023
                          ग्लोबल लाइफटाइम अचीवमेंट अवार्ड, आई.ई.ई.ई. स्मार्ट
                          सिटी जूरी अवार्ड (2022), आई.ई.ई.ई. पावर एंड एनर्जी
                          सोसाइटी यू.एस.ए. (2018) से आई.ई.ई.ई. पी.ई.एस. चैप्टर
                          आउटस्टैंडिंग इंजीनियर अवार्ड, इंडियन इंस्टीट्यूट ऑफ
                          टेक्नोलॉजी रुड़की 2017 में संस्थान के लिए सम्मान और
                          विज्ञान और प्रौद्योगिकी विभाग, भारत सरकार (2005) से
                          चुने गए युवा वैज्ञानिक के लिए बॉयकास्ट (BOYSCAST)
                          रिसर्च फेलो अवार्ड शामिल हैं। स्मार्ट सिटी और स्मार्ट
                          ग्रिड प्रौद्योगिकी विकास के लिए भी उनकी सेवाओं को कई
                          पुरस्कारों से सम्मानित किया गया है।
                          <br />
                        </div>
                      ) : (
                        <div>
                          <strong>
                          डॉ. नारायण प्रसाद पाढ़ी, निदेशक, एम.एन.आई.टी. जयपुर एवं आईआईआईटी कोटा
                          </strong>
                          , निवर्तमान प्रोफेसर (एच.ए.जी.), डीन अकादमिक मामले,
                          संस्थापक हेड- मेहता फैमिली स्कूल ऑफ डेटा साइंस एंड
                          आर्टिफिशियल इंटेलिजेंस, प्रोफेसर इंचार्ज- ट्रेनिंग एंड
                          प्लेसमेंट, एन.ई.ई.पी.सी.ओ. तथा आई.आई.टी. रुड़की में
                          संस्थान के चेयर प्रोफेसर के रूप में कार्य कर चुके हैं।
                          <br />
                          डॉ पाढ़ी स्मार्ट ग्रिड और पावर सिस्टम्स इंजीनियरिंग के
                          क्षेत्र में एक उत्कृष्ट शोधकर्ता हैं। उन्होंने
                          अनुसंधान अनुदान, शोध छात्रों का मार्गदर्शन, शोध लेख
                          प्रकाशन, किताबों के प्रकाशन, पेटेंट दायर और हासिल,
                          प्रयोगशाला विकास और ऑनलाइन एनपीटीईएल पाठ्यक्रम विकसित
                          करने के माध्यम से कार्यक्षेत्र में महत्वपूर्ण योगदान
                          दिये हैं।
                          <br />
                          डॉ. पाढ़ी ने प्रतिष्ठित अंतरराष्ट्रीय विश्वविद्यालयों
                          में अतिथि पदों पर कार्य किया है और इन विश्वविद्यालयों
                          के साथ अनुसंधान कार्य और और संस्थाओं से साझेदारी
                          स्थापित की है। उन्होंने अनुसंधान, प्रयोगशाला विकास और
                          एक ज्ञान भागीदार के रूप में शीर्ष उद्योगों और सरकारी
                          एजेंसियों का सहयोग किया है। उन्हें राष्ट्रीय एवं
                          अंतरराष्ट्रीय स्तर के व्यवसायिक निकायों में वरिष्ठ
                          स्थान प्राप्त है। उन्होंने विभिन्न क्षमताओं में उनके
                          विकास हेतु सक्रिय रूप से योगदान दिया है। वह विभिन्न
                          अंतरराष्ट्रीय अनुसंधान मंचों पर भी देश का प्रतिनिधित्व
                          करते आ रहे हैं।
                          <br />
                          डॉ पाढ़ी राष्ट्रीय और अंतर्राष्ट्रीय स्तर पर प्रसिद्ध,
                          पुरस्कृत और सम्मानित विद्वान् पेशेवर हैं।
                        </div>
                      )}
                    </h6>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div
                        onClick={this.handleReadmore}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          borderRadius: 4,
                          border: "solid 1px black",
                          padding: "3px 7px",
                          cursor: "pointer",
                          backgroundColor: "#0E407C",
                          color: "#ffffff",
                        }}
                      >
                        {this.state.readMore
                          ? this.state.isEnglish
                            ? "View less"
                            : "कम देखें"
                          : this.state.isEnglish
                          ? "View Detailed Profile"
                          : "विस्तृत प्रोफाइल देखे"}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Director;
