import React from "react";
import { Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
class ComputerLabs extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Computer Labs - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Computer Labs Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Computer Labs, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,Computer Lab IIIT Kota, IIIT Kota Computer Lab,IIIT Kota Lab,Computer Lab in IIIT Kota,MNIT labs"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Computer Labs</b>
                  </h2>
                </Col>
              </Row>
              <img
                alt="/"
                src={require("assets/img/college/labs1.webp")}
                width="100%"
              />
              <Row>
                <Col lg={12} xs={12} className="mt-3 text-justify">
                  <h6>
                    The Computer Centre at IIIT Kota is a technological hub at
                    the heart of the campus. It offers a total of 90 computer
                    workstations, each designed to meet high-performance
                    computing needs. These computers come equipped with reliable
                    and robust specifications, including 4GB of RAM and a
                    spacious 500GB hard drive (HDD). They are powered by
                    advanced processors, either Core i5 or Core i7, which are
                    known for their processing speed and efficiency. These
                    machines are optimized for demanding computational tasks,
                    making them ideal for students and researchers working on a
                    wide range of projects. The Computer Centre reflects IIIT
                    Kota's commitment to providing top-tier resources to
                    students and faculty. This cutting-edge facility is a vital
                    component of the institution's technological infrastructure,
                    supporting various research, development, and academic
                    activities in the field of computer science and technology.
                  </h6>
                </Col>
                <Col lg={12} xs={12} className="text-justify">
                  <h6>
                    <b>Computer centre remains open in office hours.</b>
                  </h6>
                </Col>
              </Row>
              <Row>
                <Col lg={12} xs={12} className="mt-3">
                  <h4>
                    <b>
                      List of Software Installed in the Workstations of Computer
                      Labs
                    </b>
                  </h4>
                </Col>
                <Col lg={4} xs={12}>
                  <h6>
                    <ul>
                      <li>MATLAB</li>
                      <li>Eclipse</li>
                      <li>Visual Studio 10</li>
                      <li>AutoCAD 2014</li>
                      <li>Xilinx</li>
                      <li>Adobe Illustrator</li>
                    </ul>
                  </h6>
                </Col>
                <Col lg={4} xs={12}>
                  <h6>
                    <ul>
                      <li>C++</li>
                      <li>JAVA</li>
                      <li>Netbeans</li>
                      <li>Python</li>
                      <li>Adobe Reader</li>
                      <li>Winzip</li>
                    </ul>
                  </h6>
                </Col>
                <Col lg={12} xs={12} className="mt-3">
                  <h4>
                    <b>Undergraduate Courses</b>
                  </h4>
                </Col>
                <Col lg={4} xs={12}>
                  <h6>
                    <ul>
                      <li>Data Structures and Algorithms</li>
                      <li>Computer Organization</li>
                      <li>Algorithm Design and Analysis</li>
                      <li>Animation and Graphics</li>
                      <li>System Management</li>
                    </ul>
                  </h6>
                </Col>
                <Col lg={12} xs={12} className="mt-3 text-justify">
                  <h6>
                    These labs are also used to provide facilities for the
                    institute events like online exams, technical events and
                    other important happenings.
                  </h6>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default ComputerLabs;
