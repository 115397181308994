import React from "react";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import "assets/css/style.css";
import Helmet from "react-helmet";

class OfficeStaff extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Office Staff - Indian Institute of Information Technology, Kota
          </title>
          <meta
            name="description"
            content="Office Staff - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content=" Office Staff iiitkota, Office Staff mnit jaipur, cse Office Staff,ece Office Staff, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  <h2>
                    <b>Office Staff</b>
                  </h2>
                </Col>
              </Row>

              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid ">
                    {/* <Col lg="4 mt-5">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <div className="mb-4  text-center">
                            <img
                              alt="Sh. Mahesh Kumar Sharma"
                              className="OfficeStaff-img"
                              height="300px"
                              src="https://files.iiitkota.ac.in/images/staff/1.webp"
                            />
                          </div>

                          <h6 className="text-primary ">
                            <b>Sh. Mahesh Kumar Sharma</b>
                          </h6>

                          <div className="px-2 pb-2">Consultant</div>

                          <h6>
                            <a href="mailto:mksharma@iiitkota.ac.in">
                              <i className="fa fa-envelope px-2" />
                              mksharma@iiitkota.ac.in
                            </a>
                          </h6>

                          <h6>
                            <a href="tel:+91-9549650517">
                              <i className="fas fa-phone-square-alt px-2" />
                              +91-9549650517
                            </a>
                          </h6>
                          <br />
                          <br />
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4 mt-5">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <div className="mb-4  text-center">
                            <img
                              alt="Sh. Rajendra Prasad Saxena"
                              className="OfficeStaff-img"
                              height="300px"
                              src="https://files.iiitkota.ac.in/images/staff/2.webp"
                            />
                          </div>

                          <h6 className="text-primary">
                            <b>Sh. Rajendra Prasad Saxena</b>
                          </h6>
                          <div className="px-2 pb-2">
                            Assistant Registrar
                            <br />
                            Academic and Store & Purchase
                          </div>

                          <h6>
                            <a href="mailto:mksharma@iiitkota.ac.in">
                              <i className="fa fa-envelope px-2" />
                              rpsaxena@iiitkota.ac.in
                            </a>
                          </h6>

                          <h6>
                            <a href="tel:+91-9549651177">
                              <i className="fas fa-phone-square-alt px-2" />
                              +91-9549651177
                            </a>
                          </h6>
                          <br />
                        </CardBody>
                      </Card>
                    </Col> */}

                    {/* <Col lg="4 mt-5">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <div className="mb-4  text-center"> */}
                    {/* <i className="ni ni-check-bold" /> */}
                    {/* <img
                              alt="Mr. Sagar.V.Vakkund"
                              className="OfficeStaff-img"
                              height="300px"
                              src="https://files.iiitkota.ac.in/images/staff/3.webp"
                            />
                          </div>

                          <h6 className="text-primary ">
                            <b>Sh. Sagar.V.Vakkund</b>
                          </h6>
                          <div className="px-2 pb-2">
                            Assistant Registrar
                            <br />
                            Establishment and Miscellaneous Works
                          </div>

                          <h6>
                            <a href="mailto:sagar@iiitkota.ac.in">
                              <i className="fa fa-envelope px-2" />
                              sagar@iiitkota.ac.in
                            </a>
                          </h6>

                          <h6>
                            <a href="tel:+91-9549650168">
                              <i className="fas fa-phone-square-alt px-2" />
                              +91-9549650168
                            </a>
                          </h6>
                        </CardBody>
                      </Card>
                    </Col> */}
                    {/* <Col lg="4 mt-5">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <div className="mb-4  text-center">
                            
                            <img
                              alt="Mr. Parag Somani"
                              className="OfficeStaff-img"
                              height="300px"
                              src="https://files.iiitkota.ac.in/images/staff/4.webp"
                            />
                          </div>

                          <h6 className="text-primary ">
                            <b>Mr. Parag Somani</b>
                          </h6>
                          <div className="px-2 pb-2">
                            Office Manager
                            <br />
                            Accounts
                          </div>

                          <h6>
                            <a href="mailto:parag@iiitkota.ac.in">
                              <i className="fa fa-envelope px-2" />
                              parag@iiitkota.ac.in
                            </a>
                          </h6>

                          <h6>
                            <a href="tel:+91-9549650547">
                              <i className="fas fa-phone-square-alt px-2" />
                              +91-9549650547
                            </a>
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4 mt-5">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <div className="mb-4  text-center">
                            
                            <img
                              alt="Mr. Pratap Singh Khangarot"
                              height="300px"
                              className="OfficeStaff-img"
                              src="https://files.iiitkota.ac.in/images/staff/5.webp"
                            />
                          </div>

                          <h6 className="text-primary ">
                            <b>Mr. Pratap Singh Khangarot</b>
                          </h6>
                          <div className="px-2 pb-2">
                            Office Assistant
                            <br />
                            Accounts & Finance
                          </div>

                          <h6>
                            <a href="mailto:pratap@iiitkota.ac.in">
                              <i className="fa fa-envelope px-2" />
                              pratap@iiitkota.ac.in
                            </a>
                          </h6>

                          <h6>
                            <a href="tel:+91-9549650189">
                              <i className="fas fa-phone-square-alt px-2" />
                              +91-9549650189
                            </a>
                          </h6>
                        </CardBody>
                      </Card>
                    </Col> */}
                    <Col lg="4 mt-5">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <div className="mb-4  text-center">
                            {/* <i className="ni ni-check-bold" /> */}
                            <img
                              alt="Mr. Satya Narayan Sharma"
                              height="300px"
                              className="OfficeStaff-img"
                              src="https://files.iiitkota.ac.in/images/staff/10.webp"
                            />
                          </div>

                          <h6 className="text-primary ">
                            <b>Mr. Satya Narayan Sharma</b>
                          </h6>
                          <div className="px-2 pb-2">
                            Library Information Officer
                            <br />
                            Central Library
                          </div>

                          <h6>
                            <a href="mailto:satyanarayan@iiitkota.ac.in">
                              <i className="fa fa-envelope px-2" />
                              satyanarayan@iiitkota.ac.in
                            </a>
                          </h6>

                          <h6>
                            <a href="tel:+91-9549651643">
                              <i className="fas fa-phone-square-alt px-2" />
                              +91-9549651643
                            </a>
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4 mt-5">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <div className="mb-4  text-center">
                            {/* <i className="ni ni-check-bold" /> */}
                            <img
                              alt="Mr. Banne Singh Bhati"
                              className="OfficeStaff-img"
                              height="300px"
                              src="https://files.iiitkota.ac.in/images/staff/7.webp"
                            />
                          </div>

                          <h6 className="text-primary ">
                            <b>Mr. Banne Singh Bhati</b>
                          </h6>
                          <div className="px-2 pb-2">
                            Junior Technical Manager
                            <br />
                            Department of ECE
                          </div>

                          <h6>
                            <a href="mailto:bannesingh@iiitkota.ac.in">
                              <i className="fa fa-envelope px-2" />
                              bannesingh@iiitkota.ac.in
                            </a>
                          </h6>

                          <h6>
                            <a href="tel:+91-9549650589">
                              <i className="fas fa-phone-square-alt px-2" />
                              +91-9549650589
                            </a>
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                    {/* <Col lg="4 mt-5">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <div className="mb-4  text-center">
                            <img
                              alt="Mrs. Varsha Soni"
                              height="300px"
                              className="OfficeStaff-img"
                              src="https://files.iiitkota.ac.in/images/staff/8.webp"
                            />
                          </div>

                          <h6 className="text-primary ">
                            <b>Mrs. Varsha Soni</b>
                          </h6>
                          <div className="px-2 pb-2">
                            Junior Technical Manager
                            <br />
                            Department of ECE
                          </div>

                          <h6>
                            <a href="mailto:varshasoni@iiitkota.ac.in">
                              <i className="fa fa-envelope px-2" />
                              varshasoni@iiitkota.ac.in
                            </a>
                          </h6>

                          <h6>
                            <a href="tel:+91-9549651642">
                              <i className="fas fa-phone-square-alt px-2" />
                              +91-9549651642
                            </a>
                          </h6>
                        </CardBody>
                      </Card>
                    </Col> */}
                    <Col lg="4 mt-5">
                      <Card className="card-lift--hover shadow border-0">
                        <CardBody>
                          <div className="mb-4  text-center">
                            {/* <i className="ni ni-check-bold" /> */}
                            <img
                              alt="Mr. Tej Singh Jatav"
                              height="300px"
                              className="OfficeStaff-img"
                              src="https://files.iiitkota.ac.in/images/staff/9.webp"
                            />
                          </div>

                          <h6 className="text-primary ">
                            <b>Mr. Tej Singh Jatav</b>
                          </h6>
                          <div className="px-2 pb-2">
                            Junior Technical Manager
                            <br />
                            Department of CSE
                          </div>

                          <h6>
                            <a href="mailto:tej@iiitkota.ac.in">
                              <i className="fa fa-envelope px-2" />
                              tej@iiitkota.ac.in
                            </a>
                          </h6>

                          <h6>
                            <a href="tel:+91-9549655861">
                              <i className="fas fa-phone-square-alt px-2" />
                              +91-9549655861
                            </a>
                          </h6>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default OfficeStaff;
