import React from "react";
import "assets/css/style.css";
import { Button, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
class Coordinator extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Coordinator - Indian Institute of Information Technology, Kota
          </title>
          <meta
            name="description"
            content="Coordinator - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="Coordinator iiit kota, Prof. A. K. Vyas, Coordinator mnit jaipur, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
          <meta name="author" content="Prof. Udaykumar R Yaragatti" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Coordinator</b>
                  </h2>
                </Col>
              </Row>
              <Row>
                <Col lg={4} xs={12} className="mb-5">
                  <img
                    alt="/"
                    className="director-img"
                    src={require("assets/img/people/administrating_body/coordinator.png")}
                  ></img>

                  <div className="mt-3">
                    <h6>
                      Phone : <a href="tel:+91-141-2713267">01412713461</a>
                    </h6>
                  </div>
                  <div className="mt-3">
                    <h6>
                      Coordinator, IIIT Kota
                      <br />
                      2nd Floor, Prabha Bhawan, MNIT Campus, JLN Marg,
                      Jaipur-302017
                    </h6>
                  </div>
                  <div className="mt-3">
                    <Button
                      className="btn-icon btn-3 theme-color-kota-bg"
                      type="button"
                      href="mailto:coordinator@iiitkota.ac.in"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fa fa-envelope" />
                      </span>
                      <span className="btn-inner--text">E-mail</span>
                    </Button>
                    {/* <Button
                      className="btn-icon btn-3 theme-color-kota-bg"
                      type="button"
                      href="https://www.linkedin.com/in/udaykumar-r-yaragatti-559a1514/"
                      target="_blank"
                    >
                      <span className="btn-inner--icon mr-1">
                        <i className="fab fa-linkedin" />
                      </span>
                      <span className="btn-inner--text">LinkedIn</span>
                    </Button> */}
                  </div>
                </Col>
                <Col lg={8} xs={12}>


                
                  <div className="">
                    <h2 className="display-3 mb-0">
                      <b>Prof. K. K. Sharma</b>
                    </h2>
                    <h5>Coordinator, IIIT Kota</h5>

                    <div className="d-lg-flex flex-grow-1 justify-content-end d-none ">
                      <div className="d-flex flex-column row-gap-3">
                        
                        <div>
                          <span className="btn-inner--icon mr-1">
                            <i class="fa fa-eye"></i>
                          </span>
                          <a href="https://mnit.ac.in/dept_ece/profile?fid=QKs=">
                            View Detailed Profile @ MNIT
                          </a>
                        </div>
                      </div>
                    </div>

                  </div>
                  
                  {/* <div className="text-justify director-content">
                    <h5>
                      Our focus is to continuously improve our performance
                      inspiring excellence and to grow into one of the best
                      institutions for engineering education in the country.
                    </h5>
                  </div> */}
                  <hr />
                  <div className="text-justify mb-6">
                    <h5 className="mb-2">
                      <b>Profile</b>
                    </h5>
                    <h6 className="mb-1">
                    Professor-HAG
                    </h6>
                    <h6 className="mb-1">Dept. of Electronics & Comm. Engineering, MNIT, Jaipur-302017</h6>
                    <h5 className="mt-4">
                      <b>Qualification</b>
                    </h5>
                    <ul>
                      <li>
                        <h6 className="mb-1">
                        Ph.D.(Signal Processing-IIT Delhi) from IIT Delhi
                        </h6>
                      </li>
                      <li>
                        <h6 className="mb-1">M.E.(Electronics & Comm. Engg.) from MREC Jaipur</h6>
                      </li>
                   
                      <li>
                      <h6>B.E.(Electronics & Comm. Engg.) from MREC Jaipur</h6>
                      </li>
                    </ul>
                    <h5 className="mt-4">
                      <b>Academic Achievements</b>
                    </h5>
                    <ul>
                      <li>
                        <h6>
                          Journal Publications - 77
                        </h6>{" "}
                      </li>
                      <li>
                        <h6>
                          Conference Publications - 49
                        </h6>
                      </li>
                      <li>
                        <h6>
                          Research Projects - 3
                        </h6>
                      </li>
                      <li>
                        <h6>
                        PhD Research Supervised - 17
                        </h6>
                      </li>
                    </ul>

                    <h5 className="mb-2">
                      <b>Administrative Responsibility</b>
                    </h5>
                    <ul>
                      <li>
                        <h6>
                        Coordinator in IIIT Kota from - 27-06-2024 to Till Date
                        </h6>{" "}
                      </li>

                      <li>
                        <h6>
                        Member BoS in Central University of Rajasthan Kishangarh Ajmer from - 14-02-2024 to Till Date
                        </h6>{" "}
                      </li>

                      <li>
                        <h6>
                        BoG Member in University Institute of Engineering & Technology (UIET) Kurukshetra from - 15-08-2018 to 14-08-2021
                        </h6>{" "}
                      </li>

                      <li>
                        <h6>
                        Head of the Department in MNIT from - 29-05-2015 to 04-06-2018
                        </h6>{" "}
                      </li>

                      <li>
                        <h6>
                        Officer-in-Charge PG in MNIT Jaipur from - 07-02-2011 to 27-06-2011
                        </h6>{" "}
                      </li>

                      <li>
                        <h6>
                        ITS officer in Department of Telecommunications, Government of India from - 10-07-1997 to 02-07-1998
                        </h6>{" "}
                      </li>
                     
                    </ul>

                    <h5 className="mb-2">
                      <b>Awards & Honours</b>
                    </h5>
                    <ul>

                      <li>
                        <h6>
                        Top Scholar by ScholarGPS for research contributions given by ScholarGPS, USA Year - 2024
                        </h6>{" "}
                      </li>

                      <li>
                        <h6>
                        The 2017 Albert Nelson Marquis Lifetime achievement Award for Research given by Marquis Whos Who USA Year - 2017
                        </h6>{" "}
                      </li>

                      <li>
                        <h6>
                        IETE-K S Krishan Memorial Award 2016 for Best system oriented co-authored Paper given by IETE New Delhi Year - 2016
                        </h6>{" "}
                      </li>

                      <li>
                        <h6>
                        Honors status awarded to our research paper for Paper published in Computers in Biology and Medicine in 2016 given by Journal Computers in Biology and Medicine, Elsevier Year - 2016
                        </h6>{" "}
                      </li>
                     
                    </ul>

                  </div>
                  <hr />
                  {/* <div className="text-justify mb-6">
                    <h5 className="mb-4">
                      <b>Biography</b>
                    </h5>
                    <h6>
                      He is a senior professor of Electrical and Electronics
                      Engineering. He completed his B.Tech. from KREC, now
                      NIT-K, Surathkal. Later, he completed his M.Tech. in 1990
                      in the same institution and completed his Ph.D. from IIT
                      Bombay in the year 2000. Prof. Yaragatti joined NIT-K as a
                      Lecturer and has served in various capacities spanning two
                      decade in NIT-K. He was also assigned additional charge of
                      Director, NIT Goa by MoE. In addition to his role as
                      Director of MNIT Jaipur, Prof. Udaykumar is also lead PI
                      for Joint consortium of Mission Innovation Research
                      Project (MI) Government of India “Cooperative Isolated
                      Renewable Energy Systems for Enhancing Reliability of
                      Power in Rural Areas”. He has guided 40 M.Tech theses and
                      17 research scholars have already been awarded Ph.D.
                      degree under his supervision. He has published more than
                      250 research papers in national and international
                      conferences and journals. He has travelled extensively in
                      countries such as Australia, Canada, Germany, Singapore,
                      Sri Lanka and the US for chairing the sessions at IEEE
                      conferences/ visiting professor/ research instructor. His
                      current work areas include Smart Grid, Energy System
                      Engineering, Power Electronics and Distributed Generation.
                      Apart from teaching and research, he is a senior member of
                      IEEE. He is also the Life-Member of different bodies like
                      ISTE, SESI, IE and ISLE.
                    </h6>
                  </div> */}
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Coordinator;
