import React from "react";
import Helmet from "react-helmet";
import {
  Card,
  CardBody,
  Container,
  Row,
  Col,
  UncontrolledCarousel,
} from "reactstrap";

import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";

const items = [
  {
    src: require("assets/img/hostel/hostels.webp"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/hostel/hostels3.webp"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/hostel/hostels4.webp"),
    altText: "",
    caption: "",
    header: "",
  },
];
class Hostels extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Hostels - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Hostels Indian Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Hostels, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota,IIIT Kota Hostels,Hostels of IIIT Kota,Ganga Hostel,Gargi Hostel,MNIT hostels,MNIT jaipur Hostels,Vinodini Hostel,Acharya Bhawan"
          />
          <meta name="author" content="Author name" />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Hostels</b>
                  </h2>
                </Col>
              </Row>
              <Row className="justify-content-center mb-3">
                <Col lg="12">
                  <center>
                    <h4>
                      <b>About Hostels</b>
                    </h4>
                  </center>
                </Col>
              </Row>

              <Row className="text-justify">
                <Col lg={12}>
                  <h6>
                    The Insitute has excellent hostel facilities for all the
                    students. The permanent campus of IIIT Kota is spread over an area
                    of 100.37 acres and is located in Ranpur, Kota. The permanent campus of IIIT
                    Kota has all the modern infrastructural facilities, including a 700 plus bedded Boys’ Hostel, 144 bedded Girls’
                    Hostel, mess, canteen, and sports grounds.
                  </h6>
                </Col>
                <Col lg={12} className="mt-3">
                  <h6>
                    Hostels are provided with 24x7 internet facility. It is
                    compulsory for all hostel residents to avail the mess
                    facilities. Day-scholars can eat in the mess by paying the
                    meal cost as notified. In addition to the mess, there are
                    canteen also, located in the campus which caters to the
                    needs of the students and faculty. All hostel students are
                    completely free to move around the campus at all times, and
                    the the Computer Center usually functional till 10 PM for the benefit of students.
                  </h6>
                </Col>
              </Row>
              <Row className="justify-content-center mb-3 mt-3">
                <Col lg={6} className="mt-3">
                  <h4 className=" mb-5">
                    <b>Hostel Allotment Policies</b>
                  </h4>

                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5 text-justify">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                        <a href="https://files.iiitkota.ac.in/pdf/hostel/Policy%20for%20Allotment.pdf">
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>

                      <h6>
                        <a href="https://files.iiitkota.ac.in/pdf/hostel/Policy%20for%20Allotment.pdf">
                          <b>
                            Click here to download detailed Hostel Allotment
                            Policies.
                          </b>
                        </a>
                      </h6>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={6} className="mb-5 mt-3">
                  <h4 className=" mb-5">
                    <b>Hostel Policies</b>
                  </h4>
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5 text-justify">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                        <a href="https://files.iiitkota.ac.in/pdf/hostel/RULES%20FOR%20GIRLS%20HOSTEL.pdf">
                          <i className="fas fa-external-link-alt"></i>
                        </a>
                      </div>

                      <h6>
                        <a href="https://files.iiitkota.ac.in/pdf/hostel/RULES%20FOR%20GIRLS%20HOSTEL.pdf">
                          <b>
                            Click here to download detailed Hostel Key Conduct
                            Rules For Girls.
                          </b>
                        </a>
                      </h6>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row className="justify-content-center ">
                <Col lg={12} xs={12} className="mt-3">
                  <center>
                    <h4>
                      <b>Hostel's Site </b>
                    </h4>
                  </center>
                </Col>
                <Col lg={12} xs={12} className="mt-5">
                  <center>
                    <UncontrolledCarousel items={items} />
                  </center>
                </Col>
              </Row>
              {/* <Row className="justify-content-center mb-3 mt-5">
                <Col lg="12 mb-3">
                  <center>
                    <h4>
                      <b>Warden and Care Taker Information</b>
                    </h4>
                  </center>
                </Col>
                
                <Col lg="4" className="mt-3">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 text-primary">
                        <i className="fas fa-address-card" />
                      </div>

                      <h6 className="text-primary text-uppercase">
                        <b>Dr. Priyanka Harjule</b>
                        <br />
                      </h6>

                      <div className="px-2 pb-2">Girls Hostel Warden</div>
                      <h6>
                        <a href="mailto:nmittal.cse@mnit.ac.in">
                          <i className="fa fa-envelope px-2" />
                          priyanka.maths@iiitkota.ac.in
                        </a>
                      </h6>

                      <h6>
                        <a href="tel:9549650321">
                          <i className="fas fa-phone-square-alt px-2" />
                          +91-9549650321
                        </a>
                      </h6>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="4" className="mt-3">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 text-primary">
                        <i className="fas fa-address-card" />
                      </div>

                      <h6 className="text-primary text-uppercase">
                        <b> Ms. Suman Lata</b>
                        <br />
                      </h6>

                      <div className="px-2 pb-2">Caretaker</div>
                      <br />
                      

                      <h6>
                        <a href="tel:8764231118">
                          <i className="fas fa-phone-square-alt px-2" />
                          +91-8764231118
                        </a>
                      </h6>
                    </CardBody>
                  </Card>
                </Col>
              </Row> */}
              {/* <Row className="justify-content-center mb-3 mt-5">
                <Col lg="12 mb-5">
                  <center>
                    <h4>
                      <b>Chief Warden</b>
                    </h4>
                  </center>
                </Col>
                <Col lg="4">
                  <Card className="card-lift--hover shadow border-0">
                    <CardBody className="py-5">
                      <div className="icon icon-shape icon-shape-primary rounded-circle mb-4 text-primary">
                        <i className="fas fa-address-card" />
                      </div>

                      <h6 className="text-primary text-uppercase">
                        <b>Prof. Upender Pandel</b>
                        <br />
                      </h6>

                      <div className="px-2 pb-2">Chief Warden</div>
                      <h6>
                        <a href="mailto:upandel.meta@mnit.ac.in">
                          <i className="fa fa-envelope px-2" />
                          upandel.meta@mnit.ac.in
                        </a>
                      </h6>

                      <h6>
                        <a href="tel:9549654366">
                          <i className="fas fa-phone-square-alt px-2" />
                          +91-9549654366
                        </a>
                      </h6>
                    </CardBody>
                  </Card>
                </Col>
              </Row> */}
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Hostels;
