import React from "react";
import { Container, Row, Col, UncontrolledCarousel } from "reactstrap";

import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";

const items = [
  {
    src: require("assets/img/college/1.png"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/college/2.png"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/college/3.png"),
    altText: "",
    caption: "",
    header: "",
  },
  {
    src: require("assets/img/college/4.png"),
    altText: "",
    caption: "",
    header: "", 
  },  
];
class Facilities extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            Campus - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Campus Indin Institute of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Campus IIITK, Campus IIIT Kota, IIIT Kota Campus, MNIT Campus, Indian Institute of Information Technology Kota Campus, NIT Jaipur, Top Engineering Colleges in India, Top Engineering Colleges in Jaipur, Largest Campus In Jaipur"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-5">
                <Col lg="8">
                  <h2>
                    <b>Institute Campus</b>
                  </h2>
                </Col>
              </Row>

              <Row className="text-justify">
                <Col lg={12}>
                  <h6>
                    IIIT, Kota does not have its own building, as of now it is
                    being operated from the premises of mentor institute i.e.
                    Malaviya National Institute of Technology. However in
                    permanent campus at Kota boundary wall has been constructed
                    further Administrative Block containing Director Office,
                    Conference Hall, Accounts and Establishment Branch, Academic
                    Block with ten Faculty Cabins, adequate no. of lecture
                    theatres of 66 students each, Seminar Hall with capacity of
                    more than 200 persons, Computer Center for 20 Computers,
                    Library, Workshop and Store, Boys & Girls Hostel for
                    accommodating 200 boys & 200 girls respectively, Guest
                    House, Directors & Staff Residencies, transit Accommodation
                    for 40 persons and other development work such as Rain Water
                    Harvesting Structure, Sewerage Treatment Plant, Overhead
                    Tank are to be taken up in phased manner on receipt of
                    required funds from Central and State Govt. for which the
                    matter is under vigorous pursuance with the authorities
                    concerned.
                  </h6>
                </Col>
              </Row>
              <Row className="mb-3 mt-3 justify-content-center">
                <Col lg={9}>
                  <UncontrolledCarousel items={items} />
                </Col>
              </Row>
              <Row className="mt-5 text-justify">
                <Col>
                  <h5>
                    <b>However the campus of MNIT Provides:</b>
                  </h5>
                </Col>
              </Row>
              <Row className="mt-3 text-justify">
                <Col lg={12} xs={12} className="mt-3">
                  <h4>
                    <b>About Campus</b>
                  </h4>
                  <h6>
                    Extending into an area of over 312 acres of lush greenery,
                    the campus of the Institute is imaginatively laid-out with a
                    picturesque landscape. It presents a spectacle of harmony in
                    modern architecture, and natural beauty which enthralls and
                    inspires. Indeed, all the states and union territories of
                    the country are represented in the undergraduate intake of
                    the Institute(50% from Rajasthan & the other 50% from all
                    States & Union Territories of India), thus making it a
                    perfect example of the celebrated axiom, ‘unity in
                    diversity’. The institute is actively engaged in research,
                    consultancy and development activities and collaborates with
                    leading industrial houses and IT companies in various
                    projects. The campus provides all essential amenities for
                    community living like staff clubs, hospital, bank, post
                    office, community centre, school, staff residences,
                    gymnasium, playing fields, guest houses, and canteen etc.
                  </h6>
                </Col>
              </Row>
              <Row className="mt-3 text-justify">
                <Col lg={12} xs={12} className="mt-3">
                  <h4>
                    <b>Library</b>
                  </h4>
                  <h6>
                    The Institute has a spacious and well equipped library which
                    is being run according to an open access system. It has rich
                    collection of about 1,33,600 volumes of books, periodicals,
                    reports and reference material. In addition to the books
                    from main library, some more books are issued to students
                    from book bank also. There is a video viewing facility.
                    Large number of books, periodicals, video cassettes and
                    CD-ROMs are available. The library is being fully
                    computerized.
                  </h6>
                </Col>
              </Row>

              <Row className="mt-3 text-justify">
                <Col lg={12} xs={12} className="mt-3">
                  <h4>
                    <b>Canteen</b>
                  </h4>
                  <h6>
                    One canteen near the instructional zone, another near the
                    hostels and one Nescafe coffee shop provides refreshments to
                    the students and the staff during and beyond the working
                    hours.
                  </h6>
                </Col>
              </Row>

              <Row className="mt-3 text-justify">
                <Col lg={12} xs={12} className="mt-3">
                  <h4>
                    <b>Guest Houses</b>
                  </h4>
                  <h6>
                    There are two guest houses in the campus to cater the needs
                    of guests and students.
                  </h6>
                </Col>
              </Row>

              <Row className="mt-3 text-justify">
                <Col lg={12} xs={12} className="mt-3">
                  <h4>
                    <b>Sports</b>
                  </h4>
                  <h6>
                    Adequate facilities are available for sports and games and
                    for co- curricular activities. The campus is well laid out
                    with roads, electrical installations, water supply and
                    underground drainage system etc.
                  </h6>
                </Col>
              </Row>

              <Row className="mt-3 text-justify">
                <Col lg={12} xs={12} className="mt-3">
                  <h4>
                    <b>Bank</b>
                  </h4>
                  <h6>
                    There is a branch of the ICICI Bank Ltd. in the campus.
                    Other reputed banks such as SBI, OBC, and PNB etc. are
                    within walking distance from the institute campus. For more
                    details{" "}
                    <a href="http://mnit.ac.in/facilities/banking.php">
                      Click Here.
                    </a>
                  </h6>
                </Col>
              </Row>

              <Row className="mt-3 text-justify">
                <Col lg={12} xs={12} className="mt-3">
                  <h4>
                    <b>Post-Office</b>
                  </h4>
                  <h6>
                    There is a Post Office in the campus of MNIT. For more
                    details{" "}
                    <a href="https://mnit.ac.in/facilities/postoffice">
                      Click Here.
                    </a>
                  </h6>
                </Col>
              </Row>

              <Row className="mt-3 text-justify">
                <Col lg={12} xs={12} className="mt-3">
                  <h4>
                    <b>Medical Facilities</b>
                  </h4>
                  <h6>
                    A full-fledged Dispensary is available in the campus. It is
                    supervised by a residential Medical Officer. The services of
                    a part-time Homeopathy Doctor and a Lady Doctor are also
                    available to students and to the family members of the
                    staff. For more details{" "}
                    <a href="https://mnit.ac.in/facilities/dispensary">
                      Click Here.
                    </a>
                  </h6>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Facilities;
