import React from "react";
import { arrayOf, object } from "prop-types";

const Education = (props) => {
  const { educationData } = props;
  var getEducation = "";
  if (educationData) {
    getEducation =
      educationData &&
      educationData.map(function (item, index) {
        return (
          <div key={index}>
            <h3 className="h3">
              {item.studyType} {item.area}
            </h3>
            <h4 className="h4">{item.institution}</h4>
            <p>
              Studying: {item.startDate} - {item.endDate}
            </p>
          </div>
        );
      });
  }
  return (
    <>
      {getEducation && (
        <section
          className="education section"
          style={{ paddingTop: "1.5rem", paddingBottom: "1.5rem" }}
        >
          <h2 className="text-uppercase h2">
            <i className="fa fa-lg fa-mortar-board"></i> Education
          </h2>
          {getEducation}
        </section>
      )}
    </>
  );
};

Education.propTypes = {
  educationData: arrayOf(object).isRequired,
};

export default Education;
