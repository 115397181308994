import React from "react";
import "assets/css/style.css";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
class Students extends React.Component {
  state = {};
  currBatch = 2021;
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>
            {" "}
            Students - Indian Institute of Information Technology Kota
          </title>
          <meta
            name="description"
            content="Students Indian Instutite of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Students IIIT Kota, IIIT Kota Students, Students IIIT, Students IIIT Kota,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg-5">
                <Col lg="8">
                  <h2>
                    <b>Students</b>
                  </h2>
                </Col>
              </Row>
            </Container>
            <section className="section section-lg pt-lg-0 ">
              <Container>
                <Row className="justify-content-center mb-5">
                  <Col lg="12">
                    <Row className="row-grid justify-content-center">
                      <Col lg="4">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5 text-center">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <Link to={"/students/" + this.currBatch}>
                                <i className="fas fa-external-link-alt" />
                              </Link>
                            </div>
                            <Link to={"/students/" + this.currBatch}>
                              <h6 className="text-primary text-uppercase">
                                <b>{this.currBatch} Batch</b>
                              </h6>
                            </Link>
                            <h6>
                              Click here for the detailed list of students of
                              Batch {this.currBatch}.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="4">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5 text-center">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <Link
                                to={"/students/" + Number(this.currBatch - 1)}
                              >
                                <i className="fas fa-external-link-alt" />
                              </Link>
                            </div>
                            <Link
                              to={"/students/" + Number(this.currBatch - 1)}
                            >
                              <h6 className="text-primary text-uppercase">
                                <b>{this.currBatch - 1} Batch</b>
                              </h6>
                            </Link>
                            <h6>
                              Click here for the detailed list of students of
                              Batch {this.currBatch - 1}.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="justify-content-center mb-5">
                  <Col lg="12">
                    <Row className="row-grid justify-content-center">
                      <Col lg="4">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5 text-center">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <Link
                                to={"/students/" + Number(this.currBatch - 2)}
                              >
                                <i className="fas fa-external-link-alt" />
                              </Link>
                            </div>
                            <Link
                              to={"/students/" + Number(this.currBatch - 2)}
                            >
                              <h6 className="text-primary text-uppercase">
                                <b>{this.currBatch - 2} Batch</b>
                              </h6>
                            </Link>
                            <h6>
                              Click here for the detailed list of students of
                              Batch {this.currBatch - 2}.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col lg="4">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5 text-center">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a
                                href="http://alumni.iiitkota.ac.in.s3-website.ap-south-1.amazonaws.com/"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fas fa-external-link-alt" />
                              </a>
                            </div>
                            <a
                              href="http://alumni.iiitkota.ac.in.s3-website.ap-south-1.amazonaws.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="text-primary text-uppercase">
                                <b>Alumni</b>
                              </h6>
                            </a>
                            <h6>
                              Click here for the detailed list of all the alumni.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>

                      {/* <Col lg="4">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5 text-center">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <Link
                                to={"/students/" + Number(this.currBatch - 3)}
                              >
                                <i className="fas fa-external-link-alt" />
                              </Link>
                            </div>
                            <Link
                              to={"/students/" + Number(this.currBatch - 3)}
                            >
                              <h6 className="text-primary text-uppercase">
                                <b>{this.currBatch - 3} Batch</b>
                              </h6>
                            </Link>
                            <h6>
                              Click here for the detailed list of students of
                              Batch {this.currBatch - 3}.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col> */}
                    </Row>
                  </Col>
                </Row>
              </Container>
            </section>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Students;
