/* eslint-disable */
import React from "react";
import { Button, Card, CardBody, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import "assets/css/style.css";
import axios from "axios";
import Helmet from "react-helmet";
import {Link} from "react-router-dom";
class Faculty extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      faculty: [],
      visiting: [],
      guest: [],
      adjunct: [],
      status: "all",
    };
    this.all = this.all.bind(this);
    this.adjunct = this.adjunct.bind(this);
    // this.guest = this.guest.bind(this);
    // this.visiting = this.visiting.bind(this);
  }

  async componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
    const Faculty = await axios.get(
      "https://admin-panel-new.onrender.com/faculties"
    );
    let data2 = Faculty.data.sort((a, b) =>
      a.name.toUpperCase().localeCompare(b.name.toUpperCase())
    );
    
    // remove ashish sir, and priyanka saha mam temporarily
    Faculty.data = Faculty.data.filter((item) => item._id !== "6083e7759605620015356295");
    Faculty.data = Faculty.data.filter((item) => item._id !== "615afa5d1bfbfc00156fdc33");
    Faculty.data = Faculty.data.filter((item) => item._id !== "608528827fb8f4001564e18a"); // Anupam sir
    Faculty.data = Faculty.data.filter((item) => item._id !== "60819870017b3300157a6ca9"); // Basant sir
    Faculty.data = Faculty.data.filter((item) => item._id !== "616cfa3889fbf90015a6bfdf"); // Dileep sir


    this.setState({ faculty: Faculty.data });
    this.state.faculty.map((fac) => {
      // if (fac.role == "Visiting") {
      //   let arr = this.state.visiting;
      //   arr.push(fac);
      //   this.setState({
      //     visiting: arr,
      //   });
      // }
      if (fac.role == "Adjunct") {
        let arr = this.state.adjunct;
        arr.push(fac);
        this.setState({
          adjunct: arr,
        });
      }
      // if (fac.role == "Guest") {
      //   let arr = this.state.guest;
      //   arr.push(fac);
      //   this.setState({
      //     guest: arr,
      //   });
      // }
    });
  }

  all() {
    this.setState({
      status: "all",
    });
  }

  // visiting() {
  //   this.setState({
  //     status: "visiting",
  //   });
  // }
  adjunct() {
    this.setState({
      status: "adjunct",
    });
  }

  // guest() {
  //   this.setState({
  //     status: "guest",
  //   });
  // }

  render() {
    return (
      <>
        <Helmet>
          <title>
            Faculties - Indian Institute of Information Technology, Kota
          </title>
          <meta
            name="description"
            content="Faculties - Indian Institute of Information Technology, Kota"
          />
          <meta
            name="keywords"
            content="faculties iiit kota, cse faculty iiit kota, ece faculty iiit kota, IIIT Kota, IIIT, Top IIITs,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="0 0 0 0 0 0"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center">
                <Col lg="8">
                  <h2>
                    <b>Faculty</b>
                  </h2>
                </Col>
              </Row>
              {/* <Row className="justify-content-center text-center">
                <Col lg="8">
                  <Button color="default" onClick={this.all} size="small">
                    All
                  </Button>
                  <Button color="default" onClick={this.adjunct} size="small">
                    On Contract
                  </Button>
                  {
                    // <Button color="default" onClick={this.guest} size="small">
                    // Guest
                    // </Button>
                    // <Button color="default" onClick={this.visiting} size="small">
                    // Visiting
                    // </Button>
                  }
                </Col>
              </Row> */}
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid ">
                    {this.state.status === "all" && this.state.faculty
                      ? this.state.faculty.map((ele) => (
                        <Col lg="4 mt-5">
                          <Card className="card-lift--hover shadow border-0">
                          <Link to={"/faculty/" + ele._id}>
                            <CardBody>
                              <div className="mb-4 text-center">
                                {/* <i className="ni ni-check-bold" /> */}
                                <img
                                  className="faculty-img"
                                  height="300px"
                                  alt={ele?.name}
                                  src={ele?.photourl}
                                />
                              </div>
                              <div className="text-center">
                                <h6 className="text-default ">
                                  <b>
                                      {ele?.name}
                                  </b>
                                </h6>

                                {ele?.description}

                                <br />
                                {ele?.department}
                              </div>
                            </CardBody>
                            </Link>
                          </Card>
                        </Col>
                      ))
                      : null}
                    {this.state.status == "adjunct" && this.state.adjunct
                      ? this.state.adjunct.map((ele) => (
                        <Col lg="4 mt-5">
                          <Card className="card-lift--hover shadow border-0">
                            <CardBody>
                              <div className="mb-4 text-center">
                                {/* <i className="ni ni-check-bold" /> */}
                                <img
                                  className="faculty-img"
                                  height="300px"
                                  alt={ele?.name}
                                  src={ele?.photourl}
                                />
                              </div>
                              <div className="text-center">
                                <h6 className="text-default ">
                                  <b>
                                    <Link to={"/faculty/" + ele._id}>
                                      {ele?.name}
                                    </Link>
                                  </b>
                                </h6>

                                {ele?.description}

                                <br />
                                {ele?.department}
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))
                      : null}
                    {this.state.status == "visiting" && this.state.visiting
                      ? this.state.visiting.map((ele) => (
                        <Col lg="4 mt-5">
                          <Card className="card-lift--hover shadow border-0">
                            <CardBody>
                              <div className="mb-4 text-center">
                                {/* <i className="ni ni-check-bold" /> */}
                                <img
                                  className="faculty-img"
                                  height="300px"
                                  alt={ele?.name}
                                  src={ele?.photourl}
                                />
                              </div>
                              <div className="text-center">
                                <h6 className="text-default ">
                                  <b>
                                    <Link to={"/faculty/" + ele._id}>
                                      {ele?.name}
                                    </Link>
                                  </b>
                                </h6>

                                {ele?.description}

                                <br />
                                {ele?.department}
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      ))
                      : null}
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default Faculty;
