/* eslint-disable */
import React from "react";
import ImageViewer from "views/HeaderSection/Life/ImageViewer";
import { useState } from "react";
import "./events.css";
import { HashLink as Link } from "react-router-hash-link";

const EventRowItem = ({ ele }) => {
  
  // console.log(props.data);
  const [isOpen, setIsOpen] = useState(false);

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -90;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };

  const handleImageClick = (event) => {
    setIsOpen(true);
  };

  const handleImageClose = (event) => {
    setIsOpen(false);
  };

  return (

    <div className="col-lg-3 col-md-6 mb-3">
      <div className="card">
        <div className="card-image-event" onClick={() => handleImageClick()}>
          <img
            className="img-fluid hero-event-img"
            alt="events"
            // style={{ height: "250px" }}
            src={ele?.imageurl}
          />
        </div>
        <ImageViewer
          imgs={ele?.imageurl}
          isOpen={isOpen}
          onClose={() => handleImageClose()}
        />
        <div className="card-body">
          <h5 className="card-title  event-title-hero">
            <Link
              smooth
              to={"/events#" + ele?.title}
              style={{ color: "#32325d" }}
              scroll={(el) => scrollWithOffset(el)}
            >
              {ele?.title}
            </Link>
          </h5>
          <h6 className="card-text text-truncate">{ele?.description}</h6>
          {ele.date != "" && <p>{ele?.date}</p>}
        </div>
      </div>
    </div>
    
  );
};

export default EventRowItem;
