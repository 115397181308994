import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";

const Profile = ({ profileData }) => {
    useEffect(() => {
        document.title = profileData?.name || "Profile";
    }, [profileData]);

    const getSocialLink = () => {
        return profileData.profiles?.map((ele) => (
            <li key={ele.network} title={ele.username}>
                <a
                    className={`fab fa-${ele.network.toLowerCase()} fa-2x`}
                    href={ele.url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <span className="sr-only">{ele.network}</span>
                </a>
                {" "}
            </li>
        ));
    };

    const fullName = profileData?.name || "";
    const metaDescription = `${fullName} - Indian Institute of Information Technology, Kota`;
    const metaKeywords = `${fullName} IIIT Kota, IIIT, Top IIITs, Indian Institute of Information Technology Kota, ${profileData?.metadata || ""}`;

    return (
        <div className="my-profile">
            <Helmet>
                <title>{metaDescription}</title>
                <meta name="description" content={metaDescription} />
                <meta name="keywords" content={metaKeywords} />
            </Helmet>
            <div className="profileImg">
                {profileData.picture && (
                    <img
                        className="img-circle center-block"
                        src={profileData.picture}
                        width="200"
                        alt={`${fullName}'s profile`}
                    />
                )}
            </div>
            <h1 className="text-center h1">{fullName}</h1>
            <h2 className="text-center h2">{profileData.label}</h2>
            <div className="divider" />
            <ul className="ul contact-links text-center">
                {profileData.location && (
                    <li>
                        <i className="fa fa-map-marker" />
                        {`${profileData.location.city}, ${profileData.location.region}, ${profileData.location.countryCode}`}
                    </li>
                )}
                {profileData.phone && (
                    <li>
                        <i className="fa fa-phone" />
                        {profileData.phone}
                    </li>
                )}
                {profileData.email && (
                    <li>
                        <i className="fa fa-envelope" />
                        <a href={`mailto:${profileData.email}`}>{profileData.email}</a>
                    </li>
                )}
                {profileData.website && (
                    <li>
                        <i className="fa fa-link" />
                        <a href={profileData.website} target="_blank" rel="noopener noreferrer">
                            {profileData.website}
                        </a>
                    </li>
                )}
            </ul>
            <div className="divider" />
            <ul className="ul profileLinks text-center">{getSocialLink()}</ul>
            <div className="divider" />
        </div>
    );
};

Profile.propTypes = {
    profileData: PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
        picture: PropTypes.string,
        email: PropTypes.string,
        phone: PropTypes.string,
        website: PropTypes.string,
        location: PropTypes.shape({
            city: PropTypes.string,
            region: PropTypes.string,
            countryCode: PropTypes.string,
        }),
        profiles: PropTypes.arrayOf(
            PropTypes.shape({
                network: PropTypes.string,
                username: PropTypes.string,
                url: PropTypes.string,
            })
        ),
        metadata: PropTypes.string,
    }).isRequired,
};

export default Profile;