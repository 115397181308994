import React from "react";
import "assets/css/style.css";
import { Card, CardBody, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import NewFooter from "components/Footers/NewFooter.js";
import Helmet from "react-helmet";
class AnnualReports extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Annual Reports - IIIT Kota</title>
          <meta
            name="description"
            content="Annual Reports Indian Instutite of Information Technology Kota"
          />
          <meta
            name="keywords"
            content="Annual Reports IIIT Kota, IIIT Kota Annual Report, Annual Report IIIT, Report IIIT Kota,Indian Institute of Information Technology Kota"
          />
        </Helmet>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section  section-shaped pb-200">
              <div className="shape shape-style-1 shape-default bg-gradient-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>

              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon className="fill-white" points="0 0 0 0 0" />
                </svg>
              </div>
            </section>
          </div>
          <section className="section">
            <Container>
              <Row className="justify-content-center text-center mb-lg-5">
                <Col lg="8">
                  <h2>
                    <b>Annual Reports</b>
                  </h2>
                </Col>
              </Row>
            </Container>
            <section className="section pt-lg-0 ">
              <Container>
                {/* <Row className="justify-content-center mb-5">
                  <Col lg="12">
                    <Row className="row-grid">
                      <Col lg="4">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a href="g.co/hashcode">
                                <i className="fa fa-file" />
                              </a>
                            </div>
                            <a href="g.co/hashcode">
                              <h6 className="text-primary text-uppercase">
                                2020-21
                              </h6>
                            </a>
                            <h6>
                              Click here for the detailed Annual Report of the
                              year 2020-21.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="4">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a href="!#">
                                <i className="fa fa-file" />
                              </a>
                            </div>
                            <a href="!#">
                              <h6 className="text-primary text-uppercase">
                                2019-20
                              </h6>
                            </a>
                            <h6>
                              Click here for the detailed Annual Report of the
                              year 2019-20.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="4">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a href="!#">
                                <i className="fa fa-file" />
                              </a>
                            </div>
                            <a href="!#">
                              <h6 className="text-primary text-uppercase">
                                2018-19
                              </h6>
                            </a>
                            <h6>
                              Click here for the detailed Annual Report of the
                              year 2018-19.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row> */}
                {/* <Row className="justify-content-center mb-5">
                  <Col lg="12">
                    <Row className="row-grid">
                      <Col lg="4">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a href="!#">
                                <i className="fa fa-file" />
                              </a>
                            </div>
                            <a href="!#">
                              <h6 className="text-primary text-uppercase">
                                2017-18
                              </h6>
                            </a>
                            <h6>
                              Click here for the detailed Annual Report of the
                              year 2017-18.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="4">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a href="!#">
                                <i className="fa fa-file" />
                              </a>
                            </div>
                            <a href="!#">
                              <h6 className="text-primary text-uppercase">
                                2016-17
                              </h6>
                            </a>
                            <h6>
                              Click here for the detailed Annual Report of the
                              year 2016-17.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="4">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a href="!#">
                                <i className="fa fa-file" />
                              </a>
                            </div>
                            <a href="!#">
                              <h6 className="text-primary text-uppercase">
                                2015-16
                              </h6>
                            </a>
                            <h6>
                              Click here for the detailed Annual Report of the
                              year 2015-16.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row> */}
                {/* <Row className="justify-content-center mb-5">
                  <Col lg="12">
                    <Row className="row-grid">
                      <Col lg="4">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a href="!#">
                                <i className="fa fa-file" />
                              </a>
                            </div>
                            <a href="!#">
                              <h6 className="text-primary text-uppercase">
                                2014-15
                              </h6>
                            </a>
                            <h6>
                              Click here for the detailed Annual Report of the
                              year 2014-15.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="4">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a href="!#">
                                <i className="fa fa-file" />
                              </a>
                            </div>
                            <a href="!#">
                              <h6 className="text-primary text-uppercase">
                                2013-14
                              </h6>
                            </a>
                            <h6>
                              Click here for the detailed Annual Report of the
                              year 2013-14.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              */}
                <Row className="justify-content-center mb-5">
                  <Col lg="12">
                    <Row className="row-grid justify-content-center">
                      <Col lg="8">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a
                                href="https://drive.google.com/file/d/1cX6vX1DsjEKb4Uzfd63naSYOyef43-dm/view"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fa fa-file" />
                              </a>
                            </div>
                            <a
                              href="https://drive.google.com/file/d/1cX6vX1DsjEKb4Uzfd63naSYOyef43-dm/view"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="text-primary text-uppercase">
                                2021-2022
                              </h6>
                            </a>
                            <h6>
                              Click here for the detailed Annual Report of the
                              year 2021-2022.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="justify-content-center mb-5">
                  <Col lg="12">
                    <Row className="row-grid justify-content-center">
                      <Col lg="8">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a
                                href="https://drive.google.com/file/d/1NJSLlxvpG_ZKz0uAvU3qR7VlelSweVS4/view"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fa fa-file" />
                              </a>
                            </div>
                            <a
                              href="https://drive.google.com/file/d/1NJSLlxvpG_ZKz0uAvU3qR7VlelSweVS4/view"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="text-primary text-uppercase">
                                2020-2021
                              </h6>
                            </a>
                            <h6>
                              Click here for the detailed Annual Report of the
                              year 2020-2021.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="justify-content-center mb-5">
                  <Col lg="12">
                    <Row className="row-grid justify-content-center">
                      <Col lg="8">
                        <Card className="card-lift--hover shadow border-0 mb-lg-5">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a
                                href="https://files.iiitkota.ac.in/pdf/IIITKota_AnnualReport_AnnualAccounts_2019-20.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fa fa-file" />
                              </a>
                            </div>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/IIITKota_AnnualReport_AnnualAccounts_2019-20.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="text-primary text-uppercase">
                                2019-20
                              </h6>
                            </a>
                            <h6>
                              Click here for the detailed Annual Report of the
                              year 2019-20.
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="8">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a
                                href="https://files.iiitkota.ac.in/pdf/IIITKota_AnnualReport_AnnualAccounts_2018-19.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fa fa-file" />
                              </a>
                            </div>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/IIITKota_AnnualReport_AnnualAccounts_2018-19.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="text-primary text-uppercase">
                                2018-19
                              </h6>
                            </a>
                            <h6>
                              Click here for the detailed Annual Report of the
                              year 2018-19.
                              <br />
                              &nbsp;
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="justify-content-center mb-5">
                  <Col lg="12">
                    <Row className="row-grid justify-content-center">
                      <Col lg="8">
                        <Card className="card-lift--hover shadow border-0 mb-lg-5">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a
                                href="https://files.iiitkota.ac.in/pdf/IIITKota_AnnualReport_AnnualAccounts_2017-18.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fa fa-file" />
                              </a>
                            </div>
                            <a
                              href="https://files.iiitkota.ac.in/pdf/IIITKota_AnnualReport_AnnualAccounts_2017-18.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="text-primary text-uppercase">
                                2017-18
                              </h6>
                            </a>
                            <h6>
                              Click here for the detailed Annual Report of the
                              year 2017-18.
                              <br />
                              &nbsp;
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="8">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a
                                href="http://210.212.97.132/PDF/Annual%20Report/2016-17/English%20Book%20-%20IIIT%20Kota.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fa fa-file" />
                              </a>
                            </div>
                            <a
                              href="http://210.212.97.132/PDF/Annual%20Report/2016-17/English%20Book%20-%20IIIT%20Kota.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="text-primary text-uppercase">
                                2016-17
                              </h6>
                            </a>
                            <h6>
                              Click here for the detailed Annual Report of the
                              year 2016-17 (English).
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="justify-content-center mb-5">
                  <Col lg="12">
                    <Row className="row-grid justify-content-center">
                      <Col lg="8">
                        <Card className="card-lift--hover shadow border-0 mb-lg-5">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a
                                href="http://210.212.97.132/PDF/Annual%20Report/2016-17/HIndi%20Book%20-%20IIIT%20Kota.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fa fa-file" />
                              </a>
                            </div>
                            <a
                              href="http://210.212.97.132/PDF/Annual%20Report/2016-17/HIndi%20Book%20-%20IIIT%20Kota.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="text-primary text-uppercase">
                                2016-17
                              </h6>
                            </a>
                            <h6>
                              Click here for the detailed Annual Report of the
                              year 2016-17 (Hindi).
                              <br />
                              &nbsp;
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col lg="8">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a
                                href="http://210.212.97.132/PDF/Annual%20Report/2015-16/English%20Book%20-%20IIIT%20Kota.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fa fa-file" />
                              </a>
                            </div>
                            <a
                              href="http://210.212.97.132/PDF/Annual%20Report/2015-16/English%20Book%20-%20IIIT%20Kota.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="text-primary text-uppercase">
                                2015-16
                              </h6>
                            </a>
                            <h6>
                              Click here for the detailed Annual Report of the
                              year 2015-16 (English).
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="justify-content-center mb-5">
                  <Col lg="12">
                    <Row className="row-grid justify-content-center">
                      <Col lg="8">
                        <Card className="card-lift--hover shadow border-0">
                          <CardBody className="py-5">
                            <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                              <a
                                href="http://210.212.97.132/PDF/Annual%20Report/2015-16/HIndi%20Book%20-%20IIIT%20Kota.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <i className="fa fa-file" />
                              </a>
                            </div>
                            <a
                              href="http://210.212.97.132/PDF/Annual%20Report/2015-16/HIndi%20Book%20-%20IIIT%20Kota.pdf"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <h6 className="text-primary text-uppercase">
                                2015-16
                              </h6>
                            </a>
                            <h6>
                              Click here for the detailed Annual Report of the
                              year 2015-16 (Hindi).
                              <br />
                              &nbsp;
                            </h6>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </section>
          </section>
        </main>
        <NewFooter />
      </>
    );
  }
}

export default AnnualReports;
